import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import socket from '../../utils/socket';

const Inbox = ({ setInboxCount }) => {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState(null);

  const token = localStorage.getItem('token');
  const userId = parseInt(localStorage.getItem('userId'), 10) || null;
  const role = localStorage.getItem('role'); // 'promotor'

  /* ---------------------------------------------
     1. Cargar las conversaciones (role=promotor)
  --------------------------------------------- */
  useEffect(() => {
    if (!userId || !role) return;

    const fetchConversations = async () => {
      try {
        const response = await axios.get(
          `https://api.finanbid.com/api/conversations?userId=${userId}&role=${role}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const updatedConversations = response.data.map((conv) => ({
          ...conv,
          isUnread: conv.is_unread,
          messages: [],
        }));

        setConversations(updatedConversations);

        // Registrar usuario en WebSocket
        socket.emit('registerUser', userId);
      } catch (error) {
        console.error('Error al cargar las conversaciones:', error);
      }
    };

    fetchConversations();
  }, [userId, role, token]);

  /* ---------------------------------------------
     2. Escuchar mensajes en tiempo real
  --------------------------------------------- */
  useEffect(() => {
    const handleNewMessage = (newMsg) => {
      if (newMsg.senderId !== userId) { // Solo actualiza si el mensaje es de otro usuario
        // Actualizar el listado de conversaciones y marcar como no leído
        setConversations((prev) =>
          prev.map((conv) =>
            conv.conversation_id === newMsg.conversation_id
              ? { ...conv, isUnread: true, last_message: newMsg.message }
              : conv
          )
        );

        // Si la conversación está abierta, añadir el mensaje directamente
        if (newMsg.conversation_id === selectedConversation?.conversation_id) {
          setSelectedConversation((prev) => ({
            ...prev,
            messages: [...(prev.messages || []), newMsg],
          }));
        }

        // Actualizar contador global de mensajes no leídos en el Dashboard
        if (setInboxCount) {
          setInboxCount(prev => prev + 1);
        }
      }
    };

    socket.on('newMessageGlobal', handleNewMessage);

    return () => {
      socket.off('newMessageGlobal', handleNewMessage);
    };
  }, [selectedConversation, userId, setInboxCount]);

  /* ---------------------------------------------
     3. Seleccionar conversación y cargar mensajes
  --------------------------------------------- */
  const handleSelectConversation = async (conversation) => {
    setSelectedConversation(conversation);

    try {
      const response = await axios.get(
        `https://api.finanbid.com/api/messages?conversationId=${conversation.conversation_id}&userId=${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const processedMessages = response.data.map((msg) => ({
        ...msg,
        fileUrl: msg.file_url || null,
        fileName: msg.file_name || null,
      }));

      setSelectedConversation((prev) => ({
        ...prev,
        messages: processedMessages,
      }));

      // Marcar como leído en el backend
      await axios.post('https://api.finanbid.com/api/messages/mark-as-read', {
        conversationId: conversation.conversation_id,
        userId,
        role
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Reducir el contador de mensajes no leídos en el Dashboard
      if (setInboxCount) {
        setInboxCount(prev => Math.max(0, prev - 1));
      }

      // Marcar la conversación como leída localmente
      setConversations((prev) =>
        prev.map((conv) =>
          conv.conversation_id === conversation.conversation_id
            ? { ...conv, isUnread: false }
            : conv
        )
      );
    } catch (error) {
      console.error('Error al cargar los mensajes:', error);
    }
  };

  /* ---------------------------------------------
     4. Enviar un nuevo mensaje
  --------------------------------------------- */
  const handleSendMessage = async () => {
    if (!newMessage.trim() && !attachment) return;
    if (!selectedConversation) return;

    const { conversation_id, recipient_id, recipient_role, estado_conversacion } = selectedConversation;

    // Determinar destinatario según el estado de la conversación
    let finalRecipientId = recipient_id;
    let finalRecipientRole = recipient_role;

    if (estado_conversacion === 'finanbid_intermediario' || estado_conversacion === 'con_promotor') {
      finalRecipientRole = 'admin'; // Enviar a Finanbid
    } else if (estado_conversacion === 'directa_fondo_promotor') {
      finalRecipientRole = 'fund'; // Enviar directamente al fondo
    }

    if (!finalRecipientId || !finalRecipientRole) {
      console.error('No se pudo determinar el destinatario del mensaje.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('conversationId', conversation_id);
      formData.append('senderId', userId);
      formData.append('senderRole', role);
      formData.append('recipientId', finalRecipientId);
      formData.append('recipientRole', finalRecipientRole);
      if (newMessage.trim()) formData.append('message', newMessage);
      if (attachment) formData.append('attachment', attachment);

      const response = await axios.post('https://api.finanbid.com/api/messages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      // Añadir el mensaje al estado local
      setSelectedConversation((prev) => ({
        ...prev,
        messages: [...(prev.messages || []), response.data],
      }));

      // Emitir mensaje por WebSocket
      socket.emit('sendMessage', {
        conversation_id,
        senderId: userId,
        recipientId: finalRecipientId,
        recipientRole: finalRecipientRole,
        message: newMessage,
      });

      setNewMessage('');
      setAttachment(null);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error.response?.data || error.message);
    }
  };

  /* --------------------------------------------
     Helper: Etiqueta de remitente
  -------------------------------------------- */
  const getSenderLabel = (msg) => {
    if (msg.sender_id === userId) return 'Yo';
    if (msg.sender_role === 'admin') return 'Finanbid';
    if (msg.sender_role === 'fund') return 'Fondo';
    return 'Desconocido';
  };

  /* --------------------------------------------
     Helper: Color de fondo del mensaje
  -------------------------------------------- */
  const getMessageBgColor = (msg) => {
    if (msg.sender_id === userId) return 'bg-green-200 text-right';
    if (msg.sender_role === 'admin') return 'bg-gray-200 text-left';
    if (msg.sender_role === 'fund') return 'bg-blue-100 text-left';
    return 'bg-purple-100 text-left';
  };

  return (
    <div className="flex h-full">
      {/* LISTA DE CONVERSACIONES */}
      <div className="w-1/3 bg-gray-100 p-4 border-r overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">{t('inbox.conversations')}</h2>
        {conversations.length > 0 ? (
          conversations.map((conversation) => (
            <div
              key={conversation.conversation_id}
              onClick={() => handleSelectConversation(conversation)}
              className={`p-3 mb-2 cursor-pointer rounded-lg ${
                selectedConversation?.conversation_id === conversation.conversation_id
                  ? 'bg-blue-200'
                  : conversation.isUnread
                  ? 'bg-yellow-100'
                  : 'bg-white hover:bg-gray-200'
              }`}
            >
              <p className="font-semibold">{`Project #${conversation.project_id || '???'}`}</p>
              <p className="text-sm text-gray-600">{conversation.last_message || t('inbox.noMessages')}</p>
              <p className="text-xs text-gray-500">{conversation.user_email || t('inbox.unknownUser')}</p>
            </div>
          ))
        ) : (
          <p className="text-gray-600">{t('inbox.noConversations')}</p>
        )}
      </div>

      {/* DETALLE DE LA CONVERSACIÓN */}
      <div className="w-2/3 p-4 flex flex-col justify-between">
        {selectedConversation ? (
          <>
            <div className="flex-1 overflow-y-auto mb-4">
              <h2 className="text-xl font-bold mb-4">
                {t('inbox.conversation')} #{selectedConversation.conversation_id}
              </h2>
              {selectedConversation.messages.map((msg, index) => (
                <div key={index} className={`mb-2 flex ${msg.sender_id === userId ? 'justify-end' : 'justify-start'}`}>
                  <div className={`inline-block p-3 rounded-lg ${getMessageBgColor(msg)}`}>
                    <p className="font-bold text-sm mb-1">{getSenderLabel(msg)}</p>
                    <p className="text-sm">{msg.message}</p>
                    {msg.file_url && (
                      <a href={msg.file_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        {t('inbox.viewAttachment')}
                      </a>
                    )}
                    <p className="text-xs text-gray-500 mt-1">{new Date(msg.fecha_creacion).toLocaleString()}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Subir archivo */}
            <div className="flex items-center mb-2">
              <input type="file" onChange={(e) => setAttachment(e.target.files[0])} className="p-2 border border-gray-300 rounded" />
            </div>

            {/* Escribir mensaje */}
            <div className="flex items-center">
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={t('inbox.typeMessage')}
                rows="4"
                className="flex-1 p-2 border border-gray-300 rounded-l-lg"
              />
              <button onClick={handleSendMessage} className="p-2 bg-blue-500 ml-3 text-white rounded-r-lg hover:bg-blue-600">
                {t('inbox.send')}
              </button>
            </div>
          </>
        ) : (
          <p className="text-gray-600">{t('inbox.selectConversation')}</p>
        )}
      </div>
    </div>
  );
};

export default Inbox;
