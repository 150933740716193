import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const AcercaDe = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language; // "es", "en", "pt", "sv", "de", "ar", etc.

  // 1) Variables condicionales para metaetiquetas
  let metaTitle = '';
  let metaDescription = '';
  let metaKeywords = '';

  if (currentLang === 'es') {
    metaTitle = 'Acerca de Finanbid - Innovación y Competitividad';
    metaDescription = 'Descubre cómo Finanbid revoluciona la financiación inmobiliaria conectando promotores con fondos de inversión.';
    metaKeywords = 'financiación inmobiliaria, bidfunding, fondos de inversión, competitividad, promotor';
  } else if (currentLang === 'en') {
    metaTitle = 'About Finanbid - Innovation and Competitiveness';
    metaDescription = 'Learn how Finanbid revolutionizes real estate financing by connecting promoters with investment funds.';
    metaKeywords = 'real estate financing, bidfunding, investment funds, competitiveness, promoter';
  } else if (currentLang === 'pt') {
    metaTitle = 'Sobre a Finanbid - Inovação e Competitividade';
    metaDescription = 'Descubra como a Finanbid revoluciona o financiamento imobiliário conectando promotores a fundos de investimento.';
    metaKeywords = 'financiamento imobiliário, bidfunding, fundos de investimento, competitividade, promotor';
  } else if (currentLang === 'sv') {
    metaTitle = 'Om Finanbid - Innovation och konkurrenskraft';
    metaDescription = 'Lär dig hur Finanbid revolutionerar fastighetsfinansiering genom att koppla ihop projektutvecklare med investeringsfonder.';
    metaKeywords = 'fastighetsfinansiering, bidfunding, investeringsfonder, konkurrenskraft, promotor';
  } else if (currentLang === 'de') {
    metaTitle = 'Über Finanbid - Innovation und Wettbewerbsfähigkeit';
    metaDescription = 'Erfahren Sie, wie Finanbid die Immobilienfinanzierung revolutioniert, indem es Projektentwickler mit Investmentfonds verbindet.';
    metaKeywords = 'Immobilienfinanzierung, bidfunding, Investmentfonds, Wettbewerbsfähigkeit, Projektentwickler';
  } else if (currentLang === 'ar') {
    metaTitle = 'حول Finanbid - الابتكار والقدرة التنافسية';
    metaDescription = 'اكتشف كيف تحدث Finanbid ثورة في التمويل العقاري من خلال ربط المروجين بصناديق الاستثمار.';
    metaKeywords = 'تمويل عقاري، bidfunding، صناديق استثمار، القدرة التنافسية، المروج';
  } else {
    // Fallback (e.g., Spanish)
    metaTitle = 'Acerca de Finanbid - Innovación y Competitividad';
    metaDescription = 'Descubre cómo Finanbid revoluciona la financiación inmobiliaria conectando promotores con fondos de inversión.';
    metaKeywords = 'financiación inmobiliaria, bidfunding, fondos de inversión, competitividad, promotor, devtest';
  }

  // 2) Retorno del componente con Helmet y contenido
  return (
    <section className="bg-gray-900 py-16 text-white mt-20">
      {/* SEO con <Helmet> */}
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        {/* Ejemplo: link canonical + hreflang para multiidioma */}
        <link rel="canonical" href="https://www.finanbid.com/about" />
        <link rel="alternate" href="https://www.finanbid.com/about" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/about" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/about" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/about" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/about" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/about" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/about" hreflang="ar" />
      </Helmet>

      {/* Hero Section */}
      <div className="text-center max-w-2xl mx-auto mb-16">
        <h1 className="text-4xl font-bold mb-4">{t('acercaDe.heroTitle')}</h1>
        <p className="text-lg text-gray-300">{t('acercaDe.heroSubtitle')}</p>
      </div>

      {/* Mission Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.missionTitle')}
        </h2>
        <p className="text-lg text-gray-300 leading-relaxed">
          {t('acercaDe.missionDescription')}
        </p>
      </div>

      {/* How It Works Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.howItWorksTitle')}
        </h2>
        <div className="flex flex-wrap gap-8">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.promotersTitle')}</h3>
            <p className="text-gray-300">{t('acercaDe.promotersDescription')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.investorsTitle')}</h3>
            <p className="text-gray-300">{t('acercaDe.investorsDescription')}</p>
          </div>
        </div>
      </div>

      {/* Values Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.valuesTitle')}
        </h2>
        <div className="flex flex-wrap gap-8">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value1Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value1Description')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value2Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value2Description')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value3Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value3Description')}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg flex-1">
            <h3 className="text-xl font-semibold mb-4">{t('acercaDe.value4Title')}</h3>
            <p className="text-gray-300">{t('acercaDe.value4Description')}</p>
          </div>
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="max-w-5xl mx-auto px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-semibold mb-6 text-secondary">
          {t('acercaDe.whyChooseUsTitle')}
        </h2>
        <p className="text-lg text-gray-300 leading-relaxed">
          {t('acercaDe.whyChooseUsDescription')}
        </p>
      </div>
    </section>
  );
};

export default AcercaDe;