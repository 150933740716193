import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const PrestamoPuente = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.toLowerCase();
  const navigate = useNavigate();

  let metaTitle = '';
  let metaDescription = '';
  let metaKeywords = '';
  let ogTitle = '';
  let ogDescription = '';
  let faqJsonLd = '';

  if (currentLang === 'es') {
    metaTitle = 'Préstamo Puente - Liquidez Inmediata | Finanbid';
    metaDescription =
      'Obtén liquidez inmediata para cubrir brechas financieras temporales. Con Finanbid, fondos especializados compiten para ofrecerte un préstamo puente rápido y flexible.';
    metaKeywords =
      'préstamo puente, bridging loan, liquidez inmediata, financiación rápida, capital privado, financiar compra de vivienda, refinanciación, préstamo corto plazo';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "¿Para qué sirve un Préstamo Puente?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "El Préstamo Puente te permite cubrir necesidades temporales de liquidez mientras gestionas un crédito definitivo, vendes un activo o recibes ingresos futuros."
            }
          },
          {
            "@type": "Question",
            "name": "¿Qué plazos manejan los fondos para un préstamo puente?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Normalmente, entre 3 y 24 meses, dependiendo de la operación. La idea es resolver un vacío financiero de corto plazo."
            }
          },
          {
            "@type": "Question",
            "name": "¿Cuánto tardan los fondos en hacer una oferta?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Tras subir la información de tu proyecto, los fondos interesados pueden presentar ofertas en cuestión de días, gracias a la competitividad en Finanbid."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'en') {
    metaTitle = 'Bridge Loan - Immediate Liquidity | Finanbid';
    metaDescription =
      'Secure immediate liquidity to cover short-term financial gaps. With Finanbid, specialized funds compete to offer you a fast and flexible bridge loan.';
    metaKeywords =
      'bridge loan, bridging finance, immediate liquidity, fast financing, private capital, short-term loan, real estate, bridging gap';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a Bridge Loan used for?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A Bridge Loan covers temporary liquidity needs while you finalize long-term financing, sell an asset, or wait for incoming funds."
            }
          },
          {
            "@type": "Question",
            "name": "What terms do funds offer for a bridge loan?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Typically between 3 to 24 months, depending on the deal. The goal is to fill a short-term financial gap."
            }
          },
          {
            "@type": "Question",
            "name": "How quickly do funds present offers?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Once you upload your project data, interested funds can present offers within days, thanks to the competitiveness on Finanbid."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'pt') {
    metaTitle = 'Empréstimo Ponte - Liquidez Imediata | Finanbid';
    metaDescription =
      'Obtenha liquidez imediata para cobrir lacunas financeiras temporárias. Com a Finanbid, fundos especializados competem para oferecer um empréstimo ponte rápido e flexível.';
    metaKeywords =
      'empréstimo ponte, bridging loan, liquidez imediata, financiamento rápido, capital privado, curto prazo';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'sv') {
    metaTitle = 'Brygglån - Omedelbar Likviditet | Finanbid';
    metaDescription =
      'Säkra omedelbar likviditet för att täcka kortfristiga finansiella behov. Med Finanbid tävlar specialiserade fonder om att erbjuda dig ett snabbt, flexibelt brygglån.';
    metaKeywords =
      'brygglån, bridging loan, omedelbar likviditet, snabb finansiering, privat kapital, kortfristigt lån';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'de') {
    metaTitle = 'Zwischenkredit - Sofortige Liquidität | Finanbid';
    metaDescription =
      'Erhalten Sie sofortige Liquidität, um kurzfristige finanzielle Engpässe zu überbrücken. Bei Finanbid konkurrieren spezialisierte Fonds, um Ihnen einen schnellen, flexiblen Zwischenkredit anzubieten.';
    metaKeywords =
      'zwischenkredit, bridging loan, sofortige liquidität, schnelle finanzierung, privates kapital, kurzfristige finanzierung';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'ar') {
    metaTitle = 'القرض الجسر - سيولة فورية | Finanbid';
    metaDescription =
      'احصل على السيولة الفورية لتغطية الاحتياجات المالية قصيرة الأجل. في Finanbid، تتنافس الصناديق المتخصصة لتقديم قرض جسر سريع ومرن.';
    metaKeywords =
      'قرض جسر، bridging loan، سيولة فورية، تمويل سريع، رأس مال خاص، تمويل قصير الأجل';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else {
    // Fallback: es
    metaTitle = 'Préstamo Puente - Liquidez Inmediata | Finanbid';
    metaDescription =
      'Obtén liquidez inmediata para cubrir brechas financieras temporales. Con Finanbid, fondos especializados compiten para ofrecerte un préstamo puente rápido y flexible.';
    metaKeywords =
      'préstamo puente, bridging loan, liquidez inmediata, financiación rápida, capital privado, refinanciación';
    ogTitle = metaTitle;
    ogDescription = metaDescription;
    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  }

  // Urls
  const canonicalUrl = "https://www.finanbid.com/prestamopuente";

  return (
    <div className="bg-back text-white lg:mt-10 mt-24">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        <link rel="canonical" href={canonicalUrl} />

        {/* Hreflang alternates */}
        <link rel="alternate" href="https://www.finanbid.com/prestamopuente" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/prestamopuente" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/prestamopuente" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/prestamopuente" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/prestamopuente" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/prestamopuente" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/prestamopuente" hreflang="ar" />

        {/* Open Graph */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@finanbid" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* JSON-LD FAQ */}
        <script type="application/ld+json">
          {faqJsonLd}
        </script>
      </Helmet>
      {/* Hero Section */}
      <section className="bg-back dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:pt-28">
          <div className="place-self-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              {t('prestamoPuente.hero.title')}
            </h1>
            <p className="max-w-2xl mb-6 font-light text-secondary lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('prestamoPuente.hero.subtitle')}
            </p>
            <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('prestamoPuente.hero.description')}
            </p>
            <button
              onClick={() => navigate('/subir-proyecto')}
              className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
            >
              {t('prestamoPuente.hero.button')}
            </button>
          </div>
        </div>
      </section>
  {/* Principales Usos de los Préstamos Puente */}
  <section className="px-4 py-16 mx-auto sm:max-w-xl bg-gray-200 mb-12 md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 rounded-xl shadow-lg transform transition-transform hover:translate-y-[-10px]">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-sky-500 text-white">
              {t('prestamoPuente.uses.tag')}
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold  leading-none tracking-tight text-back sm:text-4xl md:mx-auto">
            {t('prestamoPuente.uses.title')}
          </h2>
          <p className="text-base text-secondary md:text-lg">
            {t('prestamoPuente.uses.subtitle')}
          </p>
        </div>

        <div className="grid max-w-md gap-8 row-gap-10 sm:mx-auto lg:max-w-full lg:grid-cols-3">
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoPuente.uses.inmobiliario.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPuente.uses.inmobiliario.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPuente.uses.inmobiliario.description2')}
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoPuente.uses.empresarial.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPuente.uses.empresarial.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPuente.uses.empresarial.description2')}
              </p>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoPuente.uses.personal.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPuente.uses.personal.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPuente.uses.personal.description2')}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Características del Préstamo Puente */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('prestamoPuente.features.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {['feature1', 'feature2', 'feature3'].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800 rounded-lg text-center">
                <h3 className="text-xl font-semibold">
                  {t(`prestamoPuente.features.${feature}.title`)}
                </h3>
                <p className="mt-4 text-gray-300">
                  {t(`prestamoPuente.features.${feature}.description`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

       {/* Call-to-Action */}
       <section className="py-16 bg-back text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">
            {t('inmobiliaria.cta.title')}
          </h2>
          <p className="text-lg mb-8">
            {t('inmobiliaria.cta.description')}
          </p>
          <button
            onClick={() => navigate('/subir-proyecto')}
            className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
          >
            {t('inmobiliaria.cta.button')}
          </button>
        </div>
      </section>
    </div>
  );
};


export default PrestamoPuente;
