import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import socket from '../../utils/socket'; // Asumiendo que ya tienes tu socket inicializado

const AdminInbox = ({ setInboxCount, setCurrentConversationId }) => {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [notifications, setNotifications] = useState([]);

  // Modal para crear conversaciones directas (Admin->Fondo/Promotor)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // Modal de confirmar "Añadir promotor"
  const [showAddPromotorModal, setShowAddPromotorModal] = useState(false);

  const messagesEndRef = useRef(null);
  const token = localStorage.getItem('token');
  const userId = parseInt(localStorage.getItem('userId'), 10) || null;
  const roleFromStorage = localStorage.getItem('role'); // 'admin'

  // ------------------------------------------
  // Desplazar el scroll hasta el final del chat
  // ------------------------------------------
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // ------------------------------------------
  // Cargar todas las conversaciones (role=admin)
  // ------------------------------------------
  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await axios.get(
          `https://api.finanbid.com/api/conversations?userId=${userId}&role=${roleFromStorage}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const updatedConversations = response.data.map((conv) => ({
          ...conv,
          isUnread: conv.is_unread,
          messages: [],
        }));

        setConversations(updatedConversations);

        // Escuchar mensajes nuevos en WebSocket
        
      } catch (error) {
        console.error('Error al cargar las conversaciones:', error);
      }
    };

    fetchConversations();

    // Limpieza al desmontar
    return () => {
      socket.off('newMessageGlobal');
    };
  }, [userId, roleFromStorage, selectedConversation, setInboxCount]);
  
  // ------------------------------------------
  // Marcar los mensajes como leídos cuando se abre una conversación
  // ------------------------------------------
  const handleSelectConversation = async (conversation) => {
    setSelectedConversation(conversation);
    setCurrentConversationId(conversation.conversation_id);  // Establece la conversación activa
  
    try {
      const response = await axios.get(
        `https://api.finanbid.com/api/messages?conversationId=${conversation.conversation_id}&userId=${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const messages = response.data.map((msg) => ({
        ...msg,
        fileUrl: msg.file_url || null,
        fileName: msg.file_name || null,
      }));
  
      setSelectedConversation((prev) => ({
        ...prev,
        messages,
      }));
  
      // Marcar los mensajes como leídos en el backend
      await axios.post(
        'https://api.finanbid.com/api/messages/mark-as-read',
        {
          conversationId: conversation.conversation_id,
          userId,
          role: roleFromStorage,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Actualizar el contador de mensajes no leídos
      setInboxCount((prev) => Math.max(0, prev - 1));
  
      // Marcar como leída en la lista de conversaciones
      setConversations((prev) =>
        prev.map((conv) =>
          conv.conversation_id === conversation.conversation_id
            ? { ...conv, isUnread: false }
            : conv
        )
      );
  
      scrollToBottom();
    } catch (error) {
      console.error('Error al cargar o marcar los mensajes como leídos:', error);
    }
  };
  useEffect(() => {
    return () => {
      setCurrentConversationId(null);  // Limpiar cuando salimos del Inbox
    };
  }, []);
  useEffect(() => {
    const handleNewMessage = (newMsg) => {
      if (newMsg.senderId !== userId) {
        // Actualizar lista de conversaciones
        setConversations((prevConvs) =>
          prevConvs.map((conv) =>
            conv.conversation_id === newMsg.conversation_id
              ? { ...conv, isUnread: true, last_message: newMsg.message }
              : conv
          )
        );
  
        // Actualizar el contador de mensajes no leídos solo si NO estamos en la conversación activa
        if (newMsg.conversation_id !== selectedConversation?.conversation_id) {
          setInboxCount((prev) => prev + 1);
        }
      }
  
      // Si la conversación está abierta, añadir el nuevo mensaje y hacer scroll
      if (newMsg.conversation_id === selectedConversation?.conversation_id) {
        setSelectedConversation((prev) => ({
          ...prev,
          messages: [...(prev.messages || []), newMsg],
        }));
        scrollToBottom();  // Hacer scroll automáticamente al recibir un nuevo mensaje
      }
    };
  
    socket.on('newMessageGlobal', handleNewMessage);
  
    return () => {
      socket.off('newMessageGlobal', handleNewMessage);
    };
  }, [selectedConversation, userId, setInboxCount]);
  // ------------------------------------------
  // Enviar un mensaje
  // ------------------------------------------
  const handleSendMessage = async () => {
    if (!newMessage.trim() && !attachment) return;
    if (!selectedConversation) return;

    const { conversation_id, estado_conversacion, recipient_id, cliente_final_id } = selectedConversation;

    let finalRecipientId = null;
    let finalRecipientRole = null;

    // Lógica para definir el destinatario según el estado de la conversación
    if (estado_conversacion === 'finanbid_intermediario') {
      finalRecipientId = recipient_id;  // Fondo
      finalRecipientRole = 'fondo';
    } else if (estado_conversacion === 'con_promotor') {
      finalRecipientId = cliente_final_id;  // Promotor también está involucrado
      finalRecipientRole = 'promotor';
    } else if (estado_conversacion === 'directa_fondo_promotor') {
      finalRecipientId = cliente_final_id;  // Comunicación directa con el promotor
      finalRecipientRole = 'promotor';
    }

    if (!finalRecipientId) {
      console.error('No se ha podido determinar el destinatario del mensaje.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('conversationId', conversation_id);
      formData.append('senderId', userId);
      formData.append('senderRole', roleFromStorage);
      formData.append('recipientId', finalRecipientId);
      formData.append('recipientRole', finalRecipientRole);
      if (newMessage.trim()) formData.append('message', newMessage);
      if (attachment) formData.append('attachment', attachment);

      const response = await axios.post('https://api.finanbid.com/api/messages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setSelectedConversation((prev) => ({
        ...prev,
        messages: [...(prev.messages || []), response.data],
      }));

      // Emitir el mensaje al WebSocket para tiempo real
      socket.emit('sendMessage', {
        conversation_id,
        senderId: userId,
        recipientId: finalRecipientId,
        message: newMessage,
      });

      setNewMessage('');
      setAttachment(null);
      scrollToBottom();
    } catch (error) {
      console.error('Error al enviar el mensaje:', error.response?.data || error.message);
    }
  };

  

  // ------------------------------------------
  // Manejo de archivo adjunto
  // ------------------------------------------
  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  // ------------------------------------------
  // Modal para crear nuevas conversaciones directas
  // ------------------------------------------
  const openNewConversationModal = () => {
    setIsModalOpen(true);
    setUserRole('');
    setUsersList([]);
    setSelectedUser(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Cargar usuarios según rol
  const fetchUsersByRole = async (role) => {
    try {
      const response = await axios.get(`https://api.finanbid.com/api/users?role=${role}`);
      setUsersList(response.data);
    } catch (error) {
      console.error('Error al cargar usuarios:', error);
    }
  };

  const handleCreateConversation = async () => {
    if (!selectedUser || !userRole) return;
    try {
      const response = await axios.post(
        'https://api.finanbid.com/api/conversations',
        {
          userId,
          role: 'admin', // Admin crea conversación con 'fondo' o 'promotor'
          recipientId: selectedUser.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const newConv = response.data;
      setConversations((prev) => [...prev, newConv]);
      setSelectedConversation(newConv);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error al crear conversación:', error);
    }
  };

  // ------------------------------------------
  // Añadir promotor a la conversación
  // ------------------------------------------
  const handleOpenAddPromotorModal = () => {
    // En lugar de mostrar un select de promotores, mostrarnos solo un popup de confirmación
    setShowAddPromotorModal(true);
  };

  const handleAddPromotor = async () => {
    if (!selectedConversation) return;

    try {
      // 1. Obtenemos el userId del promotor asociado a este proyecto (por ejemplo, usando project_id)
      const projectId = selectedConversation.project_id;
      if (!projectId) {
        console.error('No se encontró project_id en la conversación seleccionada');
        setShowAddPromotorModal(false);
        return;
      }

      // Asumimos que el endpoint /api/projects/:id devuelve la info del promotor:
      //    { user_id: <idPromotor>, ... }
      const projectRes = await axios.get(`https://api.finanbid.com/api/projects/${projectId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const promotorId = projectRes.data.user_id; // Ajusta según tu atributo real (puede ser 'promotor_id')

      // 2. Llamamos al endpoint que añade el promotor
      await axios.post(
        'https://api.finanbid.com/api/conversations/add-promotor',
        {
          conversationId: selectedConversation.conversation_id,
          promotorId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // 3. Actualizar estado local
      setSelectedConversation((prev) => ({
        ...prev,
        estado_conversacion: 'con_promotor',
        cliente_final_id: promotorId,
      }));

      setShowAddPromotorModal(false);
    } catch (error) {
      console.error('Error al añadir promotor:', error);
    }
  };

  // ------------------------------------------
  // Transferir conversación al promotor
  // ------------------------------------------
  const handleTransferToPromotor = async () => {
    if (!selectedConversation) return;

    try {
      await axios.post(
        'https://api.finanbid.com/api/conversations/transfer-to-promotor',
        {
          conversationId: selectedConversation.conversation_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setSelectedConversation((prev) => ({
        ...prev,
        estado_conversacion: 'directa_fondo_promotor',
      }));
    } catch (error) {
      console.error('Error al transferir conversación:', error);
    }
  };

  return (
    <div className="flex h-full">
      {/* LISTA DE CONVERSACIONES */}
      <div className="w-1/3 bg-gray-100 p-4 border-r overflow-y-auto relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{t('inbox.conversations')}</h2>
          <button
            onClick={openNewConversationModal}
            className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600"
          >
            +
          </button>
        </div>
        <div>
          {conversations.length > 0 ? (
            conversations.map((conversation) => (
              <div
                key={conversation.conversation_id}
                onClick={() => handleSelectConversation(conversation)}
                className={`p-3 mb-2 cursor-pointer rounded-lg ${
                  selectedConversation?.conversation_id === conversation.conversation_id
                    ? 'bg-blue-200'
                    : conversation.isUnread
                    ? 'bg-yellow-100'
                    : 'bg-white hover:bg-gray-200'
                }`}
              >
                {/* 
                  1) Project #ID
                  2) Último mensaje
                  3) Email del usuario asociado
                */}
                <p className="font-semibold">
                  {`Project #${conversation.project_id || '???'}`}
                </p>
                <p className="text-sm text-gray-600">
                  {conversation.last_message || t('inbox.noMessages')}
                </p>
                <p className="text-xs text-gray-500">
                  {conversation.user_email || t('inbox.unknownUser')}
                </p>
              </div>
            ))
          ) : (
            <p className="text-gray-600">{t('inbox.noConversations')}</p>
          )}
        </div>
      </div>

      {/* MODAL PARA CREAR NUEVA CONVERSACIÓN */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-10">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h3 className="text-lg font-bold mb-4">{t('inbox.newConversation')}</h3>
            <label className="block mb-2">
              {t('inbox.selectRole')}
              <select
                value={userRole}
                onChange={(e) => {
                  setUserRole(e.target.value);
                  fetchUsersByRole(e.target.value);
                }}
                className="w-full p-2 border rounded"
              >
                <option value="">{t('inbox.select')}</option>
                <option value="promotor">{t('inbox.promotor')}</option>
                <option value="fondo">{t('inbox.fund')}</option>
              </select>
            </label>
            {userRole && (
              <label className="block mb-2">
                {t('inbox.selectUser')}
                <select
                  value={selectedUser?.id || ''}
                  onChange={(e) =>
                    setSelectedUser(
                      usersList.find((user) => user.id === parseInt(e.target.value, 10))
                    )
                  }
                  className="w-full p-2 border rounded"
                >
                  <option value="">{t('inbox.selectUser')}</option>
                  {usersList.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.email})
                    </option>
                  ))}
                </select>
              </label>
            )}
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-500 text-white rounded mr-2 hover:bg-gray-600"
              >
                {t('inbox.cancel')}
              </button>
              <button
                onClick={handleCreateConversation}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {t('inbox.open')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* MODAL PARA CONFIRMAR AÑADIR PROMOTOR */}
      {showAddPromotorModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-20">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h3 className="text-lg font-bold mb-4">{t('inbox.addPromotorToConversation')}</h3>
            <p className="mb-4 text-gray-700">
              {/* Mensaje de advertencia */}
              {t('inbox.addPromotorWarning')} 
              {/* (ej: 'Al añadir al promotor, éste podrá ver toda la conversación con el fondo...') */}
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowAddPromotorModal(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded mr-2 hover:bg-gray-600"
              >
                {t('inbox.cancel')}
              </button>
              <button
                onClick={handleAddPromotor}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                {t('inbox.confirm')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* DETALLE DE LA CONVERSACIÓN */}
      <div className="w-2/3 p-4 flex flex-col justify-between">
        {selectedConversation ? (
          <>
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-xl font-bold">
                {t('inbox.conversationDetail')} #{selectedConversation.conversation_id}
              </h2>
              {/* Botones según estado */}
              <div className="flex gap-2">
                {['finanbid_intermediario', 'admin_cliente'].includes(
                  selectedConversation.estado_conversacion
                ) && (
                  <button
                    onClick={handleOpenAddPromotorModal}
                    className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    {t('inbox.addPromotor')}
                  </button>
                )}
                {selectedConversation.estado_conversacion === 'con_promotor' && (
                  <button
                    onClick={handleTransferToPromotor}
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    {t('inbox.transferToPromotor')}
                  </button>
                )}
              </div>
            </div>

            {/* Lista de mensajes */}
            <div className="flex-1 overflow-y-auto mb-4" ref={messagesEndRef}>
  {selectedConversation?.messages?.map((msg, index) => (
    <div key={index} className={`mb-2 flex ${msg.sender_id === userId ? 'justify-end' : 'justify-start'}`}>
      <div className={`inline-block p-3 rounded-lg ${msg.sender_id === userId ? 'bg-green-200 text-right' : 'bg-gray-200 text-left'}`}>
        <p className="text-sm">{msg.message}</p>
        {msg.file_url && (
          <a href={msg.file_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {t('inbox.viewAttachment')}
          </a>
        )}
        <p className="text-xs text-gray-500 mt-1">{new Date(msg.fecha_creacion).toLocaleString()}</p>
      </div>
    </div>
  ))}
</div>

            {/* Subir archivo */}
            <div className="flex items-center mb-2">
              <input
                type="file"
                onChange={handleFileChange}
                className="p-2 border border-gray-300 rounded"
              />
            </div>

            {/* Escribir mensaje */}
            <div className="flex items-center">
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={t('inbox.typeMessage')}
                rows="3"
                className="flex-1 p-2 border border-gray-300 rounded-l-lg"
              />
              <button
                onClick={handleSendMessage}
                className="p-2 bg-blue-500 ml-3 text-white rounded-r-lg hover:bg-blue-600"
              >
                {t('inbox.send')}
              </button>
            </div>
          </>
        ) : (
          <p className="text-gray-600">{t('inbox.selectConversation')}</p>
        )}
      </div>
    </div>
  );
};

export default AdminInbox;
