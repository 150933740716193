import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Step0 from './Step0'; // Paso inicial de introducción
import Step1 from './Step1Login'; // Paso de login/registro
import StepTermsAndConditions from './StepTermsAndConditions'; // Paso de términos y condiciones
import Step2 from './Step2ProjectDetails'; // Paso de detalles del proyecto
// import Step3 from './Step3Details'; // El siguiente paso después de Step2, dependiendo del tipo de proyecto
import Step4Congratulations from './Step4Congratulations'; // Importar el nuevo componente

const SubirProyecto = () => {
  const { t } = useTranslation(); // Hook para traducciones
  const [currentStep, setCurrentStep] = useState(0); // Controlamos los pasos
  const [userData, setUserData] = useState(null); // Almacena datos del usuario
  const [termsAccepted, setTermsAccepted] = useState(false); // Estado para saber si se han aceptado los términos
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para saber si el usuario ya está logueado
  const [projectId, setProjectId] = useState(null); // Almacena el ID del proyecto

  // Comprobamos si el usuario ya está autenticado (por ejemplo, si hay un token en el localStorage)
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  // Función para guardar la aceptación de términos y continuar
  const saveTermsAcceptance = () => {
    const timestamp = new Date().toISOString();
    const ip = '127.0.0.1'; // Aquí deberías usar la IP real del usuario en producción
    setTermsAccepted(true);
  };

  // Función para guardar el ID del proyecto después de Step2
  const saveProjectId = (id) => {
    setProjectId(id);
    console.log('Project ID guardado:', id);
  };

  // Definición de pasos, filtramos los pasos nulos si el usuario está autenticado
  const steps = [
    {
      title: t('subirProyecto.introTitle'),
      component: <Step0 nextStep={() => handleNextStep()} />
    },
    !isLoggedIn && {
      title: t('subirProyecto.loginTitle'),
      component: <Step1 nextStep={() => handleNextStep()} setUserData={setUserData} />
    },
    {
      title: t('subirProyecto.termsTitle'),
      component: <StepTermsAndConditions nextStep={() => handleNextStep()} saveTermsAcceptance={saveTermsAcceptance} />
    },
    {
      title: t('subirProyecto.step2'),
      component: (
        <Step2 
          onNextStep={() => handleNextStep()} 
          onPreviousStep={() => handlePreviousStep()} // Pasar función de retroceso
          userId={userData?.id} 
          saveProjectId={saveProjectId} 
        />
      )
    },
    // {
    //   title: t('subirProyecto.step3'),
    //   component: (
    //     <Step3 
    //       nextStep={() => handleNextStep()} 
    //       projectId={projectId} // Pasamos el projectId a Step3
    //     />
    //   )
    // },
    {
      title: t('subirProyecto.step4'),
      component: (
        <Step4Congratulations />
      )
    }
  ].filter(Boolean); // Filtra pasos vacíos/nulos

  // Función para avanzar al siguiente paso
  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  // Función para retroceder al paso anterior si es necesario
  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  // Validación para evitar que se intente acceder a un paso que no existe
  const currentStepComponent = steps[currentStep]?.component;

  return (
    <div className="min-h-screen bg-back text-white pt-24"> {/* Ajustamos el padding superior */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8">

        {/* Indicador de pasos en la parte superior */}
        <div className="mb-10">
          <nav aria-label="Progress">
            {/* En pantallas md o menores, solo se muestra el título del paso actual */}
            <ol className="md:flex md:space-x-8 hidden md:block">
              {steps.map((step, index) => (
                <li key={index} className="md:flex-1">
                  <div
                    onClick={() => setCurrentStep(index)} // Navegación entre pasos
                    className={`group cursor-pointer flex flex-col py-2 pl-4 md:pb-0 md:pl-0 md:pt-4 border-l-4 
                    ${currentStep === index 
                      ? 'border-secondary text-secondary' 
                      : 'border-gray-200 hover:border-gray-300'}
                    md:border-l-0 md:border-t-4`}
                  >
                    <span className={`${currentStep === index 
                      ? 'text-secondary' 
                      : 'text-gray-500 group-hover:text-gray-700'} text-sm font-medium`}>
                      {step.title}
                    </span>
                  </div>
                </li>
              ))}
            </ol>

            {/* En pantallas pequeñas (menores a md), solo mostramos el paso actual */}
            <div className="block md:hidden">
              <div className="flex justify-center items-center py-2 px-4 border-l-4 border-secondary">
                <span className="text-secondary text-sm font-medium">
                  {steps[currentStep].title}
                </span>
              </div>
            </div>
          </nav>
        </div>

        {/* Renderizamos el componente del paso actual */}
        <div>
          {currentStepComponent ? currentStepComponent : <p>Error: Paso no encontrado.</p>}
        </div>
      </div>
    </div>
  );
};

export default SubirProyecto;
