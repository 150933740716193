import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon, LightBulbIcon, BriefcaseIcon, CollectionIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Hero = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const currentLang = i18n.language.toLowerCase(); // "es", "en", "pt", etc.

  // Función para mostrar el modal
  const handleModalOpen = () => {
    setShowModal(true);
  };

  // Función para cerrar el modal
  const handleModalClose = () => {
    setShowModal(false);
  };
 // Metas condicionales
 let metaTitle = '';
 let metaDescription = '';
 let metaKeywords = '';
 let faqJsonLd = '';  // JSON-LD FAQ
 let ogTitle = '';
 let ogDescription = '';

 // Lógica multiidioma
 if (currentLang === 'es') {
   metaTitle = 'Finanbid | Financiación alternativa para promotores - Capital privado';
   metaDescription = 'Finanbid conecta promotores con fondos de inversión. Recibe varias ofertas y financia tu proyecto en las mejores condiciones.';
   metaKeywords = 'financiación inmobiliaria, bidfunding, fondos de inversión, préstamo promotor, flipping, capital privado, préstamo puente, prestamo puente, financiación alternativa';
   ogTitle = metaTitle;
   ogDescription = metaDescription;

   faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": [
         {
           "@type": "Question",
           "name": "¿Qué es Finanbid?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Finanbid es una plataforma de subasta de financiación (bidfunding) que conecta promotores con fondos de inversión, agilizando la obtención de capital privado."
           }
         },
         {
           "@type": "Question",
           "name": "¿Cómo funciona el bidfunding?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Los promotores suben sus proyectos y los fondos compiten (pujan) presentando ofertas de financiación, dando mayor competitividad y rapidez."
           }
         }
       ]
     }
   `;
 } else if (currentLang === 'en') {
   metaTitle = 'Finanbid: Bidfunding for Real Estate Projects - Private Capital';
   metaDescription = 'Finanbid connects real estate promoters with investment funds. Receive multiple offers and finance your project under the best conditions.';
   metaKeywords = 'real estate financing, bidfunding, investment funds, promoter loans, flipping, private capital';
   ogTitle = metaTitle;
   ogDescription = metaDescription;

   faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": [
         {
           "@type": "Question",
           "name": "What is Finanbid?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Finanbid is a funding auction (bidfunding) platform that connects real estate promoters with investment funds, speeding up access to private capital."
           }
         },
         {
           "@type": "Question",
           "name": "How does bidfunding work?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Promoters upload their projects and funds compete by submitting financing offers, ensuring competitiveness and agility in funding."
           }
         }
       ]
     }
   `;
 } else if (currentLang === 'pt') {
   metaTitle = 'Finanbid: Bidfunding para Projetos Imobiliários - Capital Privado';
   metaDescription = 'A Finanbid conecta promotores com fundos de investimento. Receba várias ofertas e financie seu projeto nas melhores condições.';
   metaKeywords = 'financiamento imobiliário, bidfunding, fundos de investimento, flipping, capital privado';
   ogTitle = metaTitle;
   ogDescription = metaDescription;

   // Podrías añadir JSON-LD en portugués
   faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
 } else if (currentLang === 'sv') {
   metaTitle = 'Finanbid: Budgivning för fastighetsprojekt - Privat kapital';
   metaDescription = 'Finanbid förbinder fastighetsutvecklare med investeringsfonder. Ta emot flera erbjudanden och finansiera ditt projekt under de bästa förhållandena.';
   metaKeywords = 'fastighetsfinansiering, bidfunding, investeringsfonder, flipping, privat kapital';
   ogTitle = metaTitle;
   ogDescription = metaDescription;

   faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
 } else if (currentLang === 'de') {
   metaTitle = 'Finanbid: Bidfunding für Immobilienprojekte - Privatkapital';
   metaDescription = 'Finanbid verbindet Projektentwickler mit Investmentfonds. Erhalten Sie mehrere Angebote und finanzieren Sie Ihr Projekt zu den besten Konditionen.';
   metaKeywords = 'Immobilienfinanzierung, bidfunding, Investmentfonds, flipping, Privatkapital';
   ogTitle = metaTitle;
   ogDescription = metaDescription;

   faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
 } else if (currentLang === 'ar') {
   metaTitle = 'Finanbid: التمويل بالمزايدة للمشاريع العقارية - رأس مال خاص';
   metaDescription = 'تربط Finanbid المروجين العقاريين بصناديق الاستثمار. احصل على عروض متعددة ومول مشروعك بأفضل الشروط.';
   metaKeywords = 'التمويل العقاري, bidfunding, صناديق الاستثمار, flipping, رأس المال الخاص';
   ogTitle = metaTitle;
   ogDescription = metaDescription;

   faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
 } else {
   // Fallback: 'es'
   metaTitle = 'Finanbid: Bidfunding para Promotores - Capital Privado';
   metaDescription = 'Finanbid conecta promotores con fondos de inversión. Recibe varias ofertas y financia tu proyecto en las mejores condiciones.';
   metaKeywords = 'financiación inmobiliaria, bidfunding, fondos de inversión, préstamos promotor, flipping, capital privado';
   ogTitle = metaTitle;
   ogDescription = metaDescription;
   faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
 }

 // Subdominio base => generamos la canonical + alternates
 // (Ajusta si tu "x-default" es "www" o "finanbid.com")
 const canonicalUrl = "https://www.finanbid.com/";
 // Contruye tu link rel="alternate" para todos los idiomas
 // Suponiendo que cada subdominio / base: 
 // es: es.finanbid.com, en: en.finanbid.com, etc.
  return (
    <section
      className="min-h-screen w-screen bg-back text-white lg:mt-10 flex items-center"
      style={{
        backgroundImage: 'url("/fondo.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
       {/* Helmet para SEO multiidioma */}
       <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        {/* Canonical */}
        <link rel="canonical" href={canonicalUrl} />

        {/* Hreflang alternates */}
        <link rel="alternate" href="https://www.finanbid.com/" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/" hreflang="ar" />

        {/* Open Graph (og) */}
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@finanbid" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* JSON-LD FAQ */}
        <script type="application/ld+json">
          {faqJsonLd}
        </script>
      </Helmet>
      <div className="mx-auto max-w-7xl w-full">
        <div className="lg:pt-20 lg:grid lg:grid-cols-12 lg:gap-8 mt-32 lg:mt-16 items-center"
          style={{
            paddingTop: '20px'
          }}>
          <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:text-left lg:mr-12 lg:pt-16 md:pt-20 sm:pt-24">
            <div className="space-y-8">
              <div className="space-y-4 pb-12">
                <div className="space-y-2">
                  <span className="rounded-full uppercase bg-secondary px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                    {t('hero.earlyAccess')}
                  </span>
                  <h1 className="text-4xl font-extrabold tracking-wide sm:text-5xl md:text-6xl">
                    <span className="block sm:inline text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 via-red-500 to-pink-600">
                      {t('hero.title')}
                    </span> 
                    <span className="block font-extrabold text-white">
                      {t('hero.subtitle')}
                    </span>
                  </h1>
                </div>
                <p className="text-lg text-gray-200 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {t('hero.description')}
                </p>
              </div>
              <div className="border-t border-gray-700"></div>
              <div className="mt-4 sm:mt-6 mb-2">
                <button
                  onClick={() => navigate('/subir-proyecto')}
                  className="inline-block px-8 py-3 mb-4 border border-transparent text-base font-medium rounded-md text-white bg-secondary hover:bg-opacity-90"
                >
                  {t('hero.boton1')}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-8 lg:mt-0 mb-4 lg:col-span-6 grid grid-cols-1 sm:grid-cols-2 gap-4 px-6 lg:px-0 lg:ml-12">
            <div
              onClick={() => navigate('/inmobiliario')}
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <HomeIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card1')}</h2>
            </div>

            <div
              onClick={() => navigate('/renovables')}
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <LightBulbIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card2')}</h2>
            </div>

            <div
              onClick={handleModalOpen} // Muestra el modal para Card 3
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <BriefcaseIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card3')}</h2>
            </div>

            <div
              onClick={handleModalOpen} // Muestra el modal para Card 4
              className="border-2 border-secondary rounded-lg p-4 text-center bg-transparent h-40 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 hover:bg-gray-800"
            >
              <CollectionIcon className="h-15 w-15 text-secondary mb-2" />
              <h2 className="text-xl font-semibold text-white">{t('hero.card4')}</h2>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">{t('hero.proximamente')}</h2>
            <p className="text-gray-600 mb-6">{t('hero.prox2')}</p>
            <button
              onClick={handleModalClose}
              className="px-4 py-2 bg-secondary text-white rounded-md hover:bg-opacity-90"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;
