import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const FundDetails = () => {
  const { t } = useTranslation();
  const { fundId } = useParams(); // Ahora captura fundId
  const navigate = useNavigate();
 

  const [fundDetails, setFundDetails] = useState({});
  const [activeTab, setActiveTab] = useState('users');
  const [users, setUsers] = useState([]);
  const [followups, setFollowups] = useState([]);
  const [offers, setOffers] = useState([]);
  const [showUserModal, setShowUserModal] = useState(false);
  const [newUser, setNewUser] = useState({ name: '', email: '', phone_number: '' });

  // Verificar que `id` está definido
  useEffect(() => {
    if (!fundId) {
      console.error('No se proporcionó un ID válido para el fondo.');
      return;
    }

    console.log(`Cargando detalles del fondo con ID: ${fundId}`);

    const fetchFundDetails = async () => {
      try {
        const response = await axios.get(`https://api.finanbid.com/api/funds/${fundId}`);
        console.log('Fund details fetched:', response.data);
        setFundDetails(response.data);
      } catch (error) {
        console.error('Error fetching fund details:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`https://api.finanbid.com/api/funds/${fundId}/users`);
        console.log('Fund users fetched:', response.data);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching fund users:', error);
      }
    };

    const fetchFollowups = async () => {
      try {
        const token = localStorage.getItem("token"); // Obtener el token de autorización
        const response = await fetch(`https://api.finanbid.com/api/admin/funds/${fundId}/followups`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Añadir el token de autorización
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        //console.log("Fund followups fetched:", data);
        setFollowups(data);
      } catch (error) {
        console.error("Error fetching followups:", error);
      }
    };
    

    const fetchOffers = async () => {
      try {
        const token = localStorage.getItem("token"); // Obtener el token de autorización
        const response = await fetch(`https://api.finanbid.com/api/admin/funds/${fundId}/offers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Añadir el token de autorización
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        //console.log("Fund offers fetched:", data);
        setOffers(data);
      } catch (error) {
        console.error("Error fetching offers:", error);
      }
    };
    

    fetchFundDetails();
    fetchUsers();
    fetchFollowups();
    fetchOffers();
  }, [fundId]);

  const handleFieldChange = async (field, value) => {
    setFundDetails((prev) => ({ ...prev, [field]: value }));
    
    try {
      const token = localStorage.getItem('token'); // Obtén el token del localStorage
      if (!token) {
        throw new Error('No token available'); // Manejo en caso de que no exista el token
      }
  
      const response = await axios.put(
        `https://api.finanbid.com/api/funds/${fundId}`, 
        { [field]: value },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Agrega el token al encabezado de autorización
          },
        }
      );
  
      console.log('Field updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating fund field:', error);
    }
  };

  const handleCreateUser = async () => {
    try {
      console.log('handleCreateUser llamado');
  
      const password = Math.random().toString(36).slice(-8);
  
      if (!newUser.name || !newUser.email || !newUser.phone_number) {
        throw new Error('Todos los campos son obligatorios para crear un usuario.');
      }
  
      console.log('Datos validados:', newUser);
  
      const userResponse = await axios.post('https://api.finanbid.com/api/register', {
        name: newUser.name,
        email: newUser.email,
        phone_number: newUser.phone_number,
        role: 'fondo',
        password,
      });
  
      console.log('Respuesta de registro:', userResponse.data);
  
      if (!userResponse.data.user || !userResponse.data.user.id) {
        throw new Error('El usuario no se creó correctamente.');
      }
  
      const userId = userResponse.data.user.id;
  
      const fundUserResponse = await axios.post('https://api.finanbid.com/api/funds/users/create', {
        fund_id: fundDetails.id,
        user_id: userId,
        rol: 'fondo',
      });
  
      console.log('Respuesta de asociación a fondo:', fundUserResponse.data);
  
      if (!fundUserResponse.data.fundUserId) {
        throw new Error('La asociación del usuario al fondo falló.');
      }
  
      const emailData = {
        to: newUser.email,
        templateId: 4,
        variables: {
          fondo: fundDetails.nombre || 'Fondo',
          email: newUser.email,
          name: newUser.name,
          pass: password,
        },
      };
  
      console.log('Datos para enviar el email (JSON.stringify):', JSON.stringify(emailData));
      console.log('Enviando email con datos:', emailData);
  
      const emailResponse = await axios.post(
        'https://api.finanbid.com/api/projects/send-email-with-template',{
            to: newUser.email,
            templateId: 4,
            variables: {
              fondo: fundDetails.nombre || 'Fondo',
              email: newUser.email,
              name: newUser.name,
              pass: password,
            },
          }
       
      );
  
      console.log('Respuesta del email:', emailResponse.data);
  
      setUsers((prev) => [
        ...prev,
        { id: userId, name: newUser.name, email: newUser.email, phone_number: newUser.phone_number },
      ]);
  
      setShowUserModal(false);
      setNewUser({ name: '', email: '', phone_number: '' });
  
      console.log('Usuario creado exitosamente y asociado al fondo.');
    } catch (error) {
      console.error('Error al crear usuario o asociarlo al fondo:', error.message);
      alert('Hubo un problema al crear el usuario. Por favor, inténtalo nuevamente.');
    }
  };
  

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {/* Fund Details Card */}
<div className="bg-gray-200 p-4 rounded-lg shadow-md mb-6">
  <div className="grid grid-cols-2 gap-4">
    {Object.keys(fundDetails).map(
      (key) =>
        key !== 'fundId' && (
          <div key={key}>
            <label className="block text-sm font-medium text-gray-600 capitalize">{key}</label>
            <input
              type="text"
              value={fundDetails[key] || ''}
              onChange={(e) =>
                setFundDetails((prev) => ({
                  ...prev,
                  [key]: e.target.value, // Actualiza el estado mientras escribes
                }))
              }
              onBlur={(e) => handleFieldChange(key, e.target.value)} // Guarda el cambio al perder el foco
              className="w-full border rounded px-2 py-1"
            />
          </div>
        )
    )}
  </div>
</div>

      {/* Tabs */}
      <div className="border-b border-gray-300 mb-4">
        <nav className="flex space-x-4">
          <button
            className={`px-4 py-2 ${activeTab === 'users' ? 'border-b-2 border-blue-500 font-semibold' : ''}`}
            onClick={() => setActiveTab('users')}
          >
            {t('funds.users')}
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'followups' ? 'border-b-2 border-blue-500 font-semibold' : ''}`}
            onClick={() => setActiveTab('followups')}
          >
            {t('funds.followups')}
          </button>
          <button
            className={`px-4 py-2 ${activeTab === 'offers' ? 'border-b-2 border-blue-500 font-semibold' : ''}`}
            onClick={() => setActiveTab('offers')}
          >
            {t('funds.offers')}
          </button>
        </nav>
      </div>

      {/* Users */}
      {activeTab === 'users' && (
        <div>
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">{t('funds.userList')}</h3>
            <button
              onClick={() => setShowUserModal(true)}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              {t('funds.addUser')}
            </button>
          </div>
          <ul>
            {users.map((user) => (
              <li
                key={user.id}
                onClick={() => navigate(`/admin/users/${user.id}`)}
                className="cursor-pointer hover:bg-gray-50 p-2"
              >
                {user.name} - {user.email}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Followups */}
      {activeTab === 'followups' && (
        <ul>
          {followups.map((followup) => (
            <li
              key={followup.id}
              onClick={() => navigate(`/admin/projects/${followup.project_id}`)}
              className="cursor-pointer hover:bg-gray-50 p-2"
            >
              {t('funds.project')} #{followup.project_id} - {new Date(followup.created_at).toLocaleDateString()}
            </li>
          ))}
        </ul>
      )}

      {/* Offers */}
      {activeTab === 'offers' && (
        <ul>
          {offers.map((offer) => (
            <li
              key={offer.id}
              onClick={() => navigate(`/admin/offers/${offer.id}`)}
              className="cursor-pointer hover:bg-gray-50 p-2"
            >
              {t('funds.offer')} #{offer.id} - {t('funds.interestRate')}: {offer.interest_rate_monthly}% -{' '}
              {t('funds.openingFee')}: {offer.opening_fee}€ - {offer.accepted ? t('funds.accepted') : t('funds.pending')}
            </li>
          ))}
        </ul>
      )}

      {/* Modal */}
      {showUserModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">{t('funds.addUser')}</h3>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                placeholder={t('funds.fields.name')}
                value={newUser.name}
                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                className="w-full border rounded px-4 py-2"
              />
              <input
                type="email"
                name="email"
                placeholder={t('funds.fields.email')}
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                className="w-full border rounded px-4 py-2"
              />
              <input
                type="text"
                name="phone_number"
                placeholder={t('funds.fields.phone')}
                value={newUser.phone_number}
                onChange={(e) => setNewUser({ ...newUser, phone_number: e.target.value })}
                className="w-full border rounded px-4 py-2"
              />
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setShowUserModal(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              >
                {t('funds.cancel')}
              </button>
              <button
                onClick={handleCreateUser}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                {t('funds.save')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FundDetails;
