import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars, faTimes, faHome, faUsers, faFolder, faChartLine,
  faMoneyBill, faCog, faEnvelope, faHandshake
} from '@fortawesome/free-solid-svg-icons';

import AdminHome from './AdminHome';
import Users from './Users';
import UserDetails from './UserDetails';
import AdminProjects from './AdminProjects';
import AdminProject from './ProjectNew';
import Reports from './Reports';
import Funds from './Funds';
import FundDetails from './FundDetails';
import Settings from './Settings';
import AdminOffers from './AdminOffers';
import AdminInbox from './Inbox';
import DueDiligenceEdit from './DueDiligenceEdit';

import socket from '../../utils/socket';

const DashboardAdmin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [inboxCount, setInboxCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [funds, setFunds] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);  // Estado para la conversación activa
  const userId = parseInt(localStorage.getItem('userId'), 10) || null;
  const role = localStorage.getItem('role'); // 'admin'

  useEffect(() => {
    const fetchInboxCount = async () => {
      try {
        if (!userId || !role) return;

        const response = await fetch(`https://api.finanbid.com/api/messages/unread-count?userId=${userId}&role=${role}`);
        if (!response.ok) throw new Error(`Error al obtener la cantidad de mensajes: ${response.status}`);

        const data = await response.json();
        setInboxCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de mensajes:', error);
      }
    };

    fetchInboxCount();
    socket.emit('registerUser', userId);
  }, [userId, role]);

  useEffect(() => {
    const handleNewMessage = (newMsg) => {
      console.log('Nuevo mensaje recibido:', newMsg);
  
      // Verificar si el mensaje es para el Admin y no es del propio Admin
      if (newMsg.recipientId === userId && newMsg.senderId !== userId) {
        console.log('Mensaje para el Admin. Evaluando si mostrar notificación o actualizar contador.');
  
        // Si el mensaje NO es parte de la conversación activa, actualiza el contador y muestra notificación
        if (newMsg.conversation_id !== currentConversationId) {
          setInboxCount((prev) => prev + 1);  // Incrementar contador de mensajes no leídos
          setNotifications((prev) => [
            ...prev,
            { id: Date.now(), message: newMsg.message, conversationId: newMsg.conversation_id }
          ]);
  
          // Eliminar la notificación después de 5 segundos
          setTimeout(() => {
            setNotifications((prev) => prev.filter((n) => n.conversationId !== newMsg.conversation_id));
          }, 5000);
        } else {
          console.log('El mensaje pertenece a la conversación activa. No actualizar contador ni mostrar notificación.');
        }
      }
    };
  
    // Asegúrate de que no hay múltiples listeners
    socket.off('newMessageGlobal');
    socket.on('newMessageGlobal', handleNewMessage);
  
    return () => {
      socket.off('newMessageGlobal', handleNewMessage);
    };
  }, [userId, role, currentConversationId]);

  useEffect(() => {
    const fetchFunds = async () => {
      try {
        const response = await fetch('https://api.finanbid.com/api/funds/list');
        const data = await response.json();
        setFunds(data);
      } catch (error) {
        console.error('Error al obtener fondos:', error);
      }
    };
    fetchFunds();
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      const timer = setTimeout(() => {
        setNotifications((prev) => prev.slice(1));
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [notifications]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <header className="bg-gray-800 text-white flex justify-between items-center p-4 md:hidden">
        <img className="h-8 w-auto" src="/logofinanbid.png" alt="Admin Dashboard" />
        <button onClick={toggleSidebar} className="text-white focus:outline-none">
          <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="lg" />
        </button>
      </header>

      <nav className={`bg-gray-800 w-64 h-full flex flex-col justify-between fixed top-0 md:relative z-10 transform ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } md:translate-x-0 transition-transform duration-200 ease-in-out`}>
        <div>
          <div className="h-16 bg-gray-900 flex items-center justify-center">
            <img className="h-12 w-auto hidden md:block" src="/logofinanbid.png" alt="Admin Dashboard" />
          </div>
          <div className="flex-1 mt-8 space-y-2">
            <Link to="/admin" onClick={() => setIsSidebarOpen(false)} className="block text-gray-400 hover:text-white px-4 py-2 flex items-center">
              <FontAwesomeIcon icon={faHome} className="mr-2" /> {t('dashboard.home')}
            </Link>
            <Link to="/admin/users" onClick={() => setIsSidebarOpen(false)} className="block text-gray-400 hover:text-white px-4 py-2 flex items-center">
              <FontAwesomeIcon icon={faUsers} className="mr-2" /> {t('dashboardAdmin.users')}
            </Link>
            <Link to="/admin/projects" onClick={() => setIsSidebarOpen(false)} className="block text-gray-400 hover:text-white px-4 py-2 flex items-center">
              <FontAwesomeIcon icon={faFolder} className="mr-2" /> {t('dashboardAdmin.projects')}
            </Link>
            <Link to="/admin/offers" onClick={() => setIsSidebarOpen(false)} className="block text-gray-400 hover:text-white px-4 py-2 flex items-center">
              <FontAwesomeIcon icon={faHandshake} className="mr-2" /> {t('dashboardAdmin.offers')}
            </Link>
            <Link to="/admin/reports" onClick={() => setIsSidebarOpen(false)} className="block text-gray-400 hover:text-white px-4 py-2 flex items-center">
              <FontAwesomeIcon icon={faChartLine} className="mr-2" /> {t('dashboardAdmin.reports')}
            </Link>
            <Link to="/admin/funds" onClick={() => setIsSidebarOpen(false)} className="block text-gray-400 hover:text-white px-4 py-2 flex items-center">
              <FontAwesomeIcon icon={faMoneyBill} className="mr-2" /> {t('dashboardAdmin.funds')}
            </Link>
            <Link to="/admin/settings" onClick={() => setIsSidebarOpen(false)} className="block text-gray-400 hover:text-white px-4 py-2 flex items-center">
              <FontAwesomeIcon icon={faCog} className="mr-2" /> {t('dashboardAdmin.settings')}
            </Link>

            <Link to="/admin/inbox" onClick={() => setIsSidebarOpen(false)} className={`block px-4 py-2 flex items-center ${inboxCount > 0 ? 'text-secondary font-bold' : 'text-gray-400 hover:text-white'}`}>
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              {inboxCount > 0 ? `${t('dashboard.inbox')} (${inboxCount})` : t('dashboard.inbox')}
            </Link>
          </div>
        </div>
        <div className="mb-4 space-y-2">
          <button onClick={handleLogout} className="block text-gray-400 hover:text-white px-4 py-2 w-full text-left">
            {t('dashboard.logout')}
          </button>
        </div>
      </nav>

      <main className="flex-1 h-screen overflow-y-auto p-6 bg-gray-100">
        <div className="fixed bottom-4 right-4 space-y-2 z-50">
          {notifications.map((notification) => (
            <div key={notification.id} className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
              <p className="text-sm">{notification.message}</p>
              <button
                onClick={() => {
                  navigate(`/admin/inbox`);
                  setNotifications((prev) => prev.filter((n) => n.id !== notification.id));
                }}
                className="text-blue-500 text-xs mt-2 hover:underline"
              >
                Ver conversación
              </button>
            </div>
          ))}
        </div>

        <Routes>
          <Route path="/" element={<AdminHome />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<UserDetails />} />
          <Route path="/projects" element={<AdminProjects />} />
          <Route path="/offers" element={<AdminOffers />} />
          <Route path="/projects/:projectId" element={<AdminProject />} />
          <Route path="/funds" element={<Funds funds={funds} />} />
          <Route path="/funds/:fundId" element={<FundDetails />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/inbox" element={<AdminInbox setInboxCount={setInboxCount} setCurrentConversationId={setCurrentConversationId} />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/reports/:projectId/initial-dd" element={<DueDiligenceEdit />} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardAdmin;
