import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Colaboradores = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.toLowerCase();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: '', email: '', phone_number: '', message: '', password: '' });
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusColor, setStatusColor] = useState('');

  let metaTitle = '';
  let metaDescription = '';
  let metaKeywords = '';
  let ogTitle = '';
  let ogDescription = '';
  let faqJsonLd = '';

  if (currentLang === 'es') {
    metaTitle = 'Conviértete en Colaborador - Finanbid';
    metaDescription =
      'Ofrece a tus clientes acceso a capital privado y recibe comisiones por cada operación financiada. Conecta proyectos y fondos en Finanbid.';
    metaKeywords =
      'colaborador, broker, asesor financiero, monetizar transacciones, capital privado, financiación proyectos, comisiones';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "¿Qué es un Colaborador de Finanbid?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Es un profesional o asesor que presenta operaciones a la plataforma y obtiene comisiones por cada proyecto financiado a través de Finanbid."
            }
          },
          {
            "@type": "Question",
            "name": "¿Cómo gana dinero un colaborador?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Por cada operación financiada con éxito, el colaborador recibe una comisión previamente establecida en el contrato de colaboración."
            }
          },
          {
            "@type": "Question",
            "name": "¿Qué tipo de proyectos puedo subir?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Proyectos inmobiliarios (compra de suelo, promoción, flipping), empresariales u otras operaciones que requieran capital privado."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'en') {
    metaTitle = 'Become a Collaborator - Finanbid';
    metaDescription =
      'Provide your clients with private capital access and earn commissions on each funded deal. Connect projects and funds on Finanbid.';
    metaKeywords =
      'collaborator, broker, financial advisor, monetize deals, private capital, project financing, commissions';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is a Finanbid Collaborator?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A professional or advisor who brings deals to our platform and earns commissions for each successfully funded project."
            }
          },
          {
            "@type": "Question",
            "name": "How does a collaborator earn money?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "For every successfully funded operation, the collaborator receives a commission agreed upon in the collaboration contract."
            }
          },
          {
            "@type": "Question",
            "name": "What kinds of projects can I submit?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Real estate (land purchase, development, flipping), business operations, or any project that requires private capital."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'pt') {
    metaTitle = 'Torne-se um Colaborador - Finanbid';
    metaDescription =
      'Ofereça aos seus clientes acesso a capital privado e receba comissões em cada operação financiada. Conecte projetos e fundos na Finanbid.';
    metaKeywords =
      'colaborador, corretor, consultor financeiro, monetizar transações, capital privado, financiamento de projetos, comissões';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'sv') {
    metaTitle = 'Bli en Samarbetspartner - Finanbid';
    metaDescription =
      'Erbjud dina kunder tillgång till privat kapital och tjäna provision på varje finansierad affär. Koppla projekt och fonder via Finanbid.';
    metaKeywords =
      'samarbetspartner, mäklare, finansiell rådgivare, tjäna provision, privat kapital, projektfinansiering';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'de') {
    metaTitle = 'Werden Sie Partner - Finanbid';
    metaDescription =
      'Bieten Sie Ihren Kunden Zugang zu privatem Kapital und verdienen Sie Provisionen bei jeder finanzierten Transaktion. Verbinden Sie Projekte und Fonds über Finanbid.';
    metaKeywords =
      'partner, broker, finanzberater, privatkapital, projektfinanzierung, provisionen, monetarisieren';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'ar') {
    metaTitle = 'كن شريكًا - Finanbid';
    metaDescription =
      'قدّم لعملائك الوصول إلى رأس مال خاص واحصل على عمولات عن كل صفقة تمويل ناجحة. اربط المشاريع والصناديق من خلال Finanbid.';
    metaKeywords =
      'شريك، وسيط، مستشار مالي، رأس مال خاص، تمويل المشاريع، العمولات';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else {
    // fallback: es
    metaTitle = 'Conviértete en Colaborador - Finanbid';
    metaDescription =
      'Ofrece a tus clientes acceso a capital privado y recibe comisiones por cada operación financiada. Conecta proyectos y fondos en Finanbid.';
    metaKeywords =
      'colaborador, broker, asesor financiero, monetizar transacciones, capital privado, financiación proyectos, comisiones';
    ogTitle = metaTitle;
    ogDescription = metaDescription;
    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  }

  const canonicalUrl = 'https://www.finanbid.com/colaboradores';


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Registrar al colaborador
      const registerResponse = await fetch('https://api.finanbid.com/api/register-collaborator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!registerResponse.ok) {
        throw new Error(t('contact.errorMessage'));
      }

      // Después de registrar, obtener la respuesta del usuario
      const registerData = await registerResponse.json();
      const { user } = registerData;

      // Enviar email de bienvenida
      const emailData = {
        to: user.email,
        templateId: 1, // Template de bienvenida
        variables: {
          name: user.name,
          email: user.email,
          pass: formData.password,
        },
      };

      await fetch('https://api.finanbid.com/api/projects/send-email-with-template', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      // Después de enviar el correo, iniciar sesión automáticamente
      const loginResponse = await fetch('https://api.finanbid.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      if (!loginResponse.ok) {
        throw new Error('Error al iniciar sesión');
      }

      const loginData = await loginResponse.json();
      const { token } = loginData;

      // Guardar el token y redirigir al dashboard del colaborador
      localStorage.setItem('token', token);
      navigate('/dashboard');
    } catch (err) {
      console.error('Error al registrar:', err);
      setStatusMessage(err.message || t('register.error'));
      setStatusColor('text-red-500');
    }

    // Limpiar mensaje después de unos segundos
    setTimeout(() => {
      setStatusMessage(null);
    }, 5000);
  };

  return (
    <div className="bg-back text-white lg:mt-10 mt-24">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        <link rel="canonical" href={canonicalUrl} />

        {/* Hreflang alternates */}
        <link rel="alternate" href="https://www.finanbid.com/colaboradores" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/colaboradores" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/colaboradores" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/colaboradores" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/colaboradores" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/colaboradores" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/colaboradores" hreflang="ar" />

        {/* Open Graph */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@finanbid" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* JSON-LD FAQ */}
        <script type="application/ld+json">
          {faqJsonLd}
        </script>
      </Helmet>
      {/* Hero Section */}
      <section className="bg-back dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">

            <span className="rounded-full uppercase bg-primary px-3 py-0.5 text-sm font-semibold leading-5 text-white mb-4 inline-block">
              {t('colaboradores.hero.tag', 'COLABORADORES')}
            </span>

            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              {t('colaboradores.hero.title', 'Hazte colaborador de Finanbid')}
            </h1>

            <p className="max-w-2xl mb-6 font-light text-secondary lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('colaboradores.hero.description', 'Ofrece a tus clientes la mejor financiación privada conectando con fondos de inversión y accede a un sistema de comisiones exclusivos.')}
            </p>

            <a href="#formulario" className="inline-flex items-center justify-center px-5 py-3 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-opacity-90 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700">
              {t('colaboradores.hero.cta', 'Hazte Colaborador')}
            </a>
          </div>

          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src="/herocolaboradores.png"
              alt={t('colaboradores.hero.imgAlt', 'Colaboradores de Finanbid')}
              className="rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
            />
          </div>
        </div>
      </section>

      {/* Beneficios para Colaboradores */}
      <section className="py-16 bg-back text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('colaboradores.benefits.title', 'Beneficios de ser colaborador')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">

            {/* Beneficio 1 */}
            <div className="p-6 bg-secondary rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-3">
                {t('colaboradores.benefits.benefit1.title', 'Acceso a fondos de inversión')}
              </h3>
              <p className="text-back">
                {t('colaboradores.benefits.benefit1.description', 'Conecta a tus clientes con una amplia red de fondos especializados.')}
              </p>
            </div>

            {/* Beneficio 2 */}
            <div className="p-6 bg-secondary rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-3">
                {t('colaboradores.benefits.benefit2.title', 'Sistema de comisiones')}
              </h3>
              <p className="text-back">
                {t('colaboradores.benefits.benefit2.description', 'Gana atractivas comisiones por cada operación cerrada.')}
              </p>
            </div>

            {/* Beneficio 3 */}
            <div className="p-6 bg-secondary rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-3">
                {t('colaboradores.benefits.benefit3.title', 'Soporte personalizado')}
              </h3>
              <p className="text-back">
                {t('colaboradores.benefits.benefit3.description', 'Recibe apoyo en todo el proceso de financiación.')}
              </p>
            </div>

          </div>
        </div>
      </section>

      {/* Formulario */}
      <section id="formulario" className="py-16 bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Columna de texto */}
          <div className="flex flex-col justify-center">
            <h2 className="text-4xl font-bold mb-4">
              {t('colaboradores.form.title', 'Únete a nuestra red de colaboradores')}
            </h2>
            <p className="text-lg font-light text-gray-300 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: t('colaboradores.form.description') }} />
          </div>

          {/* Columna del formulario */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-300">
                {t('colaboradores.form.name', 'Nombre Completo')}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="block w-full p-2.5 bg-gray-800 border border-gray-600 rounded-lg text-white focus:ring-primary focus:border-primary"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-300">
                {t('colaboradores.form.email', 'Email')}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="block w-full p-2.5 bg-gray-800 border border-gray-600 rounded-lg text-white focus:ring-primary focus:border-primary"
                required
              />
            </div>

            <div>
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-300">
                {t('colaboradores.form.phone', 'Teléfono')}
              </label>
              <input
                type="tel"
                id="phone_number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                className="block w-full p-2.5 bg-gray-800 border border-gray-600 rounded-lg text-white focus:ring-primary focus:border-primary"
                required
              />
            </div>

            <div>
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-300">
                {t('colaboradores.form.password', 'Contraseña')}
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="block w-full p-2.5 bg-gray-800 border border-gray-600 rounded-lg text-white focus:ring-primary focus:border-primary"
                required
              />
            </div>

            <div>
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-300">
                {t('colaboradores.form.message', 'Mensaje')}
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                rows="4"
                className="block w-full p-2.5 bg-gray-800 border border-gray-600 rounded-lg text-white focus:ring-primary focus:border-primary"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full py-3 px-6 bg-primary text-white font-bold rounded-lg hover:bg-opacity-90 focus:ring-primary focus:ring-primary"
            >
              {t('colaboradores.form.submit', 'Enviar')}
            </button>
            {statusMessage && (
              <span className={`${statusColor} font-semibold`}>{statusMessage}</span>
            )}
          </form>
        </div>
      </section>

    </div>
  );
};

export default Colaboradores;

