import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  // === Nuevo state para reflejar idioma seleccionado en el dropdown ===
  const [selectedLang, setSelectedLang] = useState('es');

  // Detecta subdominio para setSelectedLang
  useEffect(() => {
    const host = window.location.hostname.toLowerCase(); // ej: "es.finanbid.com"
    if (host.startsWith('es.')) {
      setSelectedLang('es');
    } else if (host.startsWith('en.')) {
      setSelectedLang('en');
    } else if (host.startsWith('pt.')) {
      setSelectedLang('pt');
    } else if (host.startsWith('sv.')) {
      setSelectedLang('sv');
    } else if (host.startsWith('de.')) {
      setSelectedLang('de');
    } else if (host.startsWith('ar.')) {
      setSelectedLang('ar');
    } else {
      // fallback al "www" => asume es
      setSelectedLang('es');
    }
  }, []);

  // Redirección de subdominio según idioma
  const redirectSubdomain = (lng) => {
    // Path actual (para mantener /about, /contact, etc.)
    const path = window.location.pathname + window.location.search + window.location.hash;

    // Según el idioma elegido, construimos la URL con subdominio
    switch (lng) {
      case 'es':
        window.location.href = 'https://es.finanbid.com' + path;
        break;
      case 'en':
        window.location.href = 'https://en.finanbid.com' + path;
        break;
      case 'pt':
        window.location.href = 'https://pt.finanbid.com' + path;
        break;
      case 'sv':
        window.location.href = 'https://sv.finanbid.com' + path;
        break;
      case 'de':
        window.location.href = 'https://de.finanbid.com' + path;
        break;
      case 'ar':
        window.location.href = 'https://ar.finanbid.com' + path;
        break;
      default:
        // Fallback: si seleccionan algo desconocido, manda a la genérica (www)
        window.location.href = 'https://www.finanbid.com' + path;
        break;
    }
  };

  // Menú hamburguesa
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Menú desplegable de usuario
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  // Navegación y cerrar menú
  const handleNavigation = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  // Efecto para autenticar / obtener datos de user
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch('https://api.finanbid.com/api/user', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            localStorage.removeItem('token');
            setIsAuthenticated(false);
            setUserName('');
            setUserRole(null);
            throw new Error('Token no válido o ha caducado');
          }
          return response.json();
        })
        .then((data) => {
          setIsAuthenticated(true);
          setUserName(data.name);
          setUserRole(data.role);
        })
        .catch((error) => {
          console.error('Error al autenticar el token:', error);
        });
    } else {
      setIsAuthenticated(false);
      setUserName('');
      setUserRole(null);
    }
  }, []);

  // Detectar login/logout en localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const token = localStorage.getItem('token');
      if (token) {
        fetch('https://api.finanbid.com/api/user', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            if (!response.ok) {
              localStorage.removeItem('token');
              setIsAuthenticated(false);
              setUserName('');
              setUserRole(null);
              throw new Error('Token no válido o ha caducado');
            }
            return response.json();
          })
          .then((data) => {
            setIsAuthenticated(true);
            setUserName(data.name);
            setUserRole(data.role);
          })
          .catch((error) => {
            console.error('Error al autenticar el token:', error);
          });
      } else {
        setIsAuthenticated(false);
        setUserName('');
        setUserRole(null);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <header className="fixed top-0 left-0 w-full z-50 flex justify-between items-center p-5 bg-back text-white">
      {/* Logo */}
      <div className="flex items-center space-x-4">
        <a href="/">
          <img src="/solologo.png" alt="Logo Finanbid" className="h-14" />
        </a>
        <a href="/" className="font-bold flex items-center">
          <span className="text-2xl font-bold">{t('header.home')}</span>
          <span className="hidden lg:inline-block text-lg text-secondary font-light ml-2">
            {t('header.slogan')}
          </span>
        </a>
      </div>

      {/* Nav en escritorio */}
      <nav className="hidden md:flex space-x-6">
        <a href="/about" className="hover:text-gray-300">{t('header.about')}</a>
        <a href="/comofunciona" className="hover:text-gray-300">{t('header.services')}</a>
        <a href="/funds" className="hover:text-gray-300">{t('header.funds')}</a>
        <a href="/colaboradores" className="hover:text-gray-300">{t('header.colaboradores')}</a>
        <a href="/contact" className="hover:text-gray-300">{t('header.contact')}</a>
      </nav>

      {/* Dropdown idioma + Auth */}
      <div className="hidden md:flex space-x-4 items-center">
        {/* Selector idioma -> subdominio */}
        <div className="relative">
          <select
            className="bg-secondary text-white py-1 px-2 rounded-md"
            value={selectedLang}                // <-- AQUI asignamos
            onChange={(e) => redirectSubdomain(e.target.value)}
          >
            <option value="es">🇪🇸 Español</option>
            <option value="pt">🇵🇹 Português</option>
            <option value="en">🇬🇧 English</option>
            <option value="sv">🇸🇪 Svenska</option>
            <option value="de">🇩🇪 Deutsch</option>
            <option value="ar">🇦🇪 عربي</option>
          </select>
        </div>

        {/* Usuario logueado */}
        {isAuthenticated ? (
          <div className="relative">
            <button className="hover:text-gray-300" onClick={toggleUserMenu}>
              {t('header.hello')}, {userName}
            </button>
            {userMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white text-back rounded-md shadow-lg py-2">
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    if (userRole === 'admin') {
                      navigate('/admin/dashboard');
                    } else if (userRole === 'fondo') {
                      navigate('/fund/dashboard');
                    } else {
                      navigate('/dashboard');
                    }
                    setUserMenuOpen(false);
                  }}
                >
                  {t('header.dashboard')}
                </button>
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('role');
                    localStorage.removeItem('userId');
                    setIsAuthenticated(false);
                    navigate('/');
                  }}
                >
                  {t('header.logout')}
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <a href="/login" className="hover:text-gray-300">
              {t('header.login')}
            </a>
          </>
        )}
      </div>

      {/* Menú hamburguesa responsive */}
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                  d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>

      {/* Menú desplegable en móviles */}
      {isOpen && (
        <div className="absolute top-16 left-0 w-full bg-back text-white flex flex-col items-center space-y-4 p-4">
          <button onClick={() => handleNavigation('/about')} className="hover:text-gray-300 cursor-pointer">
            {t('header.about')}
          </button>
          <button onClick={() => handleNavigation('/comofunciona')} className="hover:text-gray-300 cursor-pointer">
            {t('header.services')}
          </button>
          <button onClick={() => handleNavigation('/funds')} className="hover:text-gray-300 cursor-pointer">
            {t('header.funds')}
          </button>
          <button onClick={() => handleNavigation('/colaboradores')} className="hover:text-gray-300 cursor-pointer">
            {t('header.colaboradores')}
          </button>
          <button onClick={() => handleNavigation('/contact')} className="hover:text-gray-300 cursor-pointer">
            {t('header.contact')}
          </button>

          {/* Selector idioma en menú móvil */}
          <select
            className="bg-secondary text-white py-1 px-2 rounded-md"
            value={selectedLang}
            onChange={(e) => redirectSubdomain(e.target.value)}
          >
            <option value="es">🇪🇸 Español</option>
            <option value="pt">🇵🇹 Português</option>
            <option value="en">🇬🇧 English</option>
            <option value="sv">🇸🇪 Svenska</option>
            <option value="de">🇩🇪 Deutsch</option>
            <option value="ar">🇦🇪 عربي</option>
          </select>

          {isAuthenticated ? null : (
            <button onClick={() => handleNavigation('/login')} className="hover:text-gray-300 cursor-pointer">
              {t('header.login')}
            </button>
          )}
          <button
            onClick={() => handleNavigation('/subir-proyecto')}
            className="bg-white text-primary py-1 px-4 rounded-lg hover:bg-gray-100 transition cursor-pointer"
          >
            {t('header.register')}
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;


