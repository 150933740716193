import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Step1Description from "./steps/Step1Description";
import Step2LoanDetails from "./steps/Step2LoanDetails";
import Step3AssetDetails from "./steps/Step3AssetDetails";
import Step4CollateralDetails from "./steps/Step4CollateralDetails";
import Step5ProjectDocuments from "./steps/Step5ProjectDocuments";
import Step6ProjectImages from "./steps/Step6ProjectImages";
import Step7LegalEntity from "./steps/Step7LegalEntity";
import Congratulations from "./steps/Congratulations";
import { calculateLTV } from "../utils/ltvCalculator";

Modal.setAppElement("#root");

const ProjectAssistantModal = ({ isOpen, onClose, projectId, initialStep = 1 }) => {
  const [currentStep, setCurrentStep] = useState(initialStep); // Paso actual
  const [projectData, setProjectData] = useState(null); // Datos iniciales
  const [errors, setErrors] = useState({}); // Errores del paso actual
  const [ltv, setLtv] = useState({ current: 0, final: 0 }); // Valores del LTV
  const token = localStorage.getItem("token");

  // Función para calcular LTV y actualizar el estado
  const updateLTV = async (data) => {
    const amountRequested = parseFloat(data.project?.amount_requested || 0);
    const capitalInvertido = parseFloat(data.project?.capital_invertido || 0);
    const assets = data.assets || [];
    const collaterals = data.collaterals || [];
  
    // Calcular el nuevo LTV
    const newLTV = calculateLTV(amountRequested, capitalInvertido, assets, collaterals);
    //console.log(amountRequested, capitalInvertido, assets, collaterals);
    //console.log(newLTV);
    setLtv(newLTV); // Actualizar el estado de LTV localmente
  
    // Preparar los datos para la API
    const updatedLoanDetails = {
      ltv: newLTV.final, // Solo actualizamos el LTV final
    };
  
    // Llamar a la API para actualizar el LTV en el backend
    try {
      const response = await fetch(
        `https://api.finanbid.com/api/projects/${projectId}/loan-request-details`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedLoanDetails),
        }
      );
  
      if (!response.ok) {
        throw new Error("Error al actualizar el LTV en el backend");
      }
  
      //console.log("LTV actualizado correctamente en el backend");
    } catch (error) {
      //console.error("Error al actualizar el LTV:", error);
    }
  };
  useEffect(() => {
    if (isOpen && projectId) {
        fetchProjectData(); // Cargar datos al abrir el modal
    }
}, [isOpen, projectId]);

  // Validar datos del paso actual
  const validateCurrentStep = () => {
    const currentStepData = projectData?.[`step${currentStep}`] || {};
    const currentStepErrors = {};
    if (currentStep === 1) {
      if (!currentStepData.resumen) currentStepErrors.resumen = "La descripción es obligatoria.";
      if (!currentStepData.direccion) currentStepErrors.direccion = "La dirección es obligatoria.";
      if (!currentStepData.localidad) currentStepErrors.localidad = "La localidad es obligatoria.";
      if (!currentStepData.provincia) currentStepErrors.provincia = "La provincia es obligatoria.";
      if (!currentStepData.pais) currentStepErrors.pais = "El país es obligatorio.";
    } else if (currentStep === 2) {
      if (!currentStepData.amount_requested)
        currentStepErrors.amount_requested = "La cantidad requerida es obligatoria.";
      if (!currentStepData.capital_invertido)
        currentStepErrors.capital_invertido = "El capital propio es obligatorio.";
      if (!currentStepData.duracion_prestamo)
        currentStepErrors.duracion_prestamo = "La duración del préstamo es obligatoria.";
      if (!currentStepData.devolucion_intereses)
        currentStepErrors.devolucion_intereses = "La flexibilidad de pago es obligatoria.";
      if (!currentStepData.repago) currentStepErrors.repago = "El método de repago es obligatorio.";
    }
    return currentStepErrors;
  };

  // Cargar datos iniciales del proyecto desde la API
  const fetchProjectData = async () => {
    try {
      const response = await fetch(
        `https://api.finanbid.com/api/admin/projects/${projectId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`Error al obtener los datos: ${response.statusText}`);
      }
      const data = await response.json();
      //console.log("Datos del proyecto cargados:", data);

      const project = data.project || {};
      const loanDetails = data.loanDetails || {};
      const assets = data.assets || [];
      const collaterals = data.collaterals || [];
      const documents = data.documents || [];
      const images = data.images || [];
      const legalEntity = data.legalEntity || [];

      setProjectData({
        step1: {
          resumen: project.resumen || "",
          direccion: project.direccion || "",
          localidad: project.localidad || "",
          provincia: project.provincia || "",
          pais: project.pais || "España",
        },
        step2: {
          amount_requested: loanDetails.amount_requested || "",
          capital_invertido: loanDetails.capital_invertido || "",
          duracion_prestamo: loanDetails.duracion_prestamo || "",
          devolucion_intereses: loanDetails.devolucion_intereses || "",
          repago: loanDetails.repago || "",
        },
        step3: {
          assets: assets || [],
          projectId: project.id,
        },
        step4: {
          collaterals: collaterals || [],
          projectId: project.id,
        },
        step5: {
          documents: documents || [], // Aquí agregamos los documentos
          projectId: project.id,
        },
        step6: {
          images: images || [], // Aquí agregamos los documentos
          projectId: project.id,
        },
        step7: {
          legalEntity: legalEntity || null, // Aquí cargamos los datos de la entidad legal
          projectId: project.id,
        },
        project, // Mantener el proyecto completo para referencia
      });
      

      // Recalcular LTV
      updateLTV(data);
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  useEffect(() => {
    if (projectId) {
      fetchProjectData();
    }
  }, [projectId]);

  useEffect(() => {
    if (isOpen) {
      setCurrentStep(initialStep);
    }
  }, [isOpen, initialStep]);

  const handleSendForReview = async () => {
    try {
      const token = localStorage.getItem("token");

      // Cambia el estado del proyecto a "En revisión"
      const response = await fetch(
        `https://api.finanbid.com/api/admin/projects/${projectId}/field`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            field: "status",
            value: "En Revision",
          }),
        }
      );

      if (!response.ok) throw new Error("Error al actualizar el estado del proyecto");

      // Envía el correo de notificación
      await fetch("https://api.finanbid.com/api/projects/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          to: "andresalen@gmail.com",
          subject: "Solicitud de revisión de proyecto",
          html: `<p>Un usuario ha enviado un proyecto para revisión. ID del proyecto: ${projectId}</p>`,
        }),
      });

      // Redirige al usuario a la página de proyectos
      window.location.href = "https://www.finanbid.com/dashboard/projects";
    } catch (error) {
      console.error("Error al enviar el proyecto a revisión:", error);
      alert("Hubo un problema al enviar el proyecto a revisión. Por favor, intenta de nuevo.");
    }
  };

  // Actualizar datos de un paso específico
  const updateStepData = (step, updatedData) => {
    setProjectData((prev) => {
      const newData = {
        ...prev,
        [`step${step}`]: updatedData,
      };
  
      // Actualizar LTV solo si afecta a los pasos 3 o 4
      if (step === 3 || step === 4) {
        updateLTV({
          project: newData.step2 || prev.step2,
          assets: newData.step3?.assets || prev.step3?.assets || [],
          collaterals: newData.step4?.collaterals || prev.step4?.collaterals || [],
        });
      }
  
      return newData;
    });
    setErrors({}); // Limpiar errores al actualizar
  };
  const updateProjectAPI = async (endpoint, updatedData) => {
    try {
      const response = await fetch(
        `https://api.finanbid.com/api/projects/${projectId}/${endpoint}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedData),
        }
      );
  
      if (!response.ok) {
        throw new Error(`Error al actualizar el proyecto: ${response.statusText}`);
      }
  
      const result = await response.json();
      //console.log(`Datos actualizados en la API (${endpoint}):`, result);
  
      // Después de actualizar, volver a cargar los datos más recientes del proyecto
      await fetchProjectData();
    } catch (error) {
      console.error("Error al actualizar el proyecto:", error);
    }
  };
  const handleNext = async () => {
    const currentErrors = validateCurrentStep();
    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors);
      return;
    }

    if (currentStep === 1) {
      const updatedData = projectData?.step1 || {};
      await updateProjectAPI("update", updatedData);
    } else if (currentStep === 2) {
      const updatedData = projectData?.step2 || {};
      await updateProjectAPI("loan-request-details", updatedData);
    }
    setCurrentStep((prev) => Math.min(prev + 1, steps.length));
  };

  const steps = [
    {
      id: 1,
      title: "Descripción del Proyecto",
      component: projectData ? (
        <Step1Description
          data={projectData.step1}
          onUpdate={(updatedData) => updateStepData(1, updatedData)}
          errors={errors}
        />
      ) : (
        <p>Cargando...</p>
      ),
    },
    {
      id: 2,
      title: "Detalles del Préstamo",
      component: projectData ? (
        <Step2LoanDetails
          data={projectData.step2}
          onUpdate={(updatedData) => updateStepData(2, updatedData)}
          errors={errors}
        />
      ) : (
        <p>Cargando...</p>
      ),
  },
  {
    id: 3,
    title: "Activos principales del proyecto",
    component: projectData ? (
      <Step3AssetDetails
        assets={projectData.step3.assets || []}
        projectId={projectData.step3.projectId || null}
        ltv={ltv} // Pasar el LTV para mostrarlo en LTVCards
        onUpdate={(updatedData) => updateStepData(3, updatedData)} // Actualizar datos y recalcular LTV
        errors={errors}
      />
    ) : (
      <p>Cargando...</p>
    ),
  },
  {
    id: 4,
    title: "Garantías colaterales del proyecto",
    component: projectData ? (
      <Step4CollateralDetails
        collaterals={projectData.step4.collaterals || []}
        projectId={projectData.step4.projectId || null}
        ltv={ltv} // Pasar el LTV para mostrarlo en LTVCards
        onUpdate={(updatedData) => updateStepData(4, updatedData)} // Actualizar datos y recalcular LTV
        errors={errors}
      />
    ) : (
      <p>Cargando...</p>
    ),
  },
  {
    id: 5,
    title: "Documentación del Proyecto",
    component: projectData ? (
      <Step5ProjectDocuments
        documents={projectData.step5.documents || []} // Pasar los documentos
        projectId={projectData.step5.projectId || null}
        onUpdate={(updatedData) => updateStepData(5, updatedData)} // Actualizar datos del paso 5
        errors={errors}
      />
    ) : (
      <p>Cargando...</p>
    ),
  },
  {
    id: 6,
    title: "Imágenes del Proyecto",
    component: projectData ? (
      <Step6ProjectImages
        images={projectData.step6.images || []}
        projectId={projectData.step6.projectId || null}
        project={projectData.project} // Pasar el proyecto completo
        onUpdate={(updatedData) => updateStepData(6, updatedData)}
        errors={errors}
      />
    ) : (
      <p>Cargando...</p>
    ),
  },
  {
    id: 7,
    title: "Entidad Legal Asociada",
    component: projectData ? (
      <Step7LegalEntity
        project={projectData.project} // Pasamos el proyecto completo
        legalEntity={projectData.step7.legalEntity || null} // Pasamos la entidad legal si existe
        projectId={projectData.step7.projectId || null}
        onUpdate={(updatedData) => updateStepData(7, updatedData)} // Actualizamos los datos en este paso
        errors={errors}
      />
    ) : (
      <p>Cargando...</p>
    ),
  },
  {
    id: 8,
    title: "¡Proyecto listo para revisión!",
    component: (
      <Congratulations
        onSendForReview={handleSendForReview}
        onClose={onClose}
      />
    ),
  },
  
];

return (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Asistente de Proyecto"
    className="fixed inset-0 flex items-center justify-center z-50"
    overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
  >
    <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl lg:max-w-6xl mx-auto">
      <header className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-800">
          {steps[currentStep - 1]?.title || "Asistente de Proyecto"}
        </h2>
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-gray-600 transition"
        >
          <span className="sr-only">Cerrar</span>
          ×
        </button>
      </header>

      <div className="px-6 py-4">
        {steps[currentStep - 1]?.component || <p className="text-gray-500">Cargando...</p>}
      </div>

      <footer className="flex justify-between items-center px-6 py-4 border-t border-gray-200">
        <button
          onClick={() => {
            fetchProjectData(); // Llama a la función para actualizar los datos
            setCurrentStep((prev) => Math.max(prev - 1, 1)); // Cambia el paso actual
        }}
          disabled={currentStep === 1}
          className={`px-4 py-2 rounded-md ${
            currentStep === 1
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          Anterior
        </button>
        <button
          onClick={() => {
            handleNext();
            fetchProjectData();
        }}
          className={`px-4 py-2 rounded-md ${
            Object.keys(errors).length > 0
              ? "bg-gray-300 text-gray-500 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
        >
          Siguiente
        </button>
      </footer>
    </div>
  </Modal>
);
};

export default ProjectAssistantModal;

