import React, { useState, useEffect } from 'react';
import StepCreateEntity from '../StepCreateEntity';
import StepTermsAndConditions from '../StepTermsAndConditions';
import Step2ProjectDetails from '../Step2ProjectDetails';
// import Step3Details from '../Step3Details'; // Eliminado pero dejado como referencia
import Step4Congratulations from '../Step4Congratulations';
import axios from 'axios';

const CreateProjectWithEntityModal = ({ onClose }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [legalEntityId, setLegalEntityId] = useState(null);
    const [userId, setUserId] = useState(null);
    const [comId, setComId] = useState(null);
    const [projectId, setProjectId] = useState(null);
    const [collaboratorId, setCollaboratorId] = useState(null);

    const nextStep = () => setCurrentStep((prevStep) => prevStep + 1);
    const previousStep = () => setCurrentStep((prevStep) => prevStep - 1);

    useEffect(() => {
        const fetchCollaboratorId = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://api.finanbid.com/api/user/info', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setCollaboratorId(data.userId);
            } catch (error) {
                console.error('Error al obtener el Collaborator ID:', error);
            }
        };

        fetchCollaboratorId();
    }, []);

    const saveEntityAndUser = (entityId, userId, comId) => {
        setLegalEntityId(entityId);
        setUserId(userId);
        setComId(comId);
    };

    const saveProjectId = (id) => setProjectId(id);

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <StepCreateEntity
                        nextStep={nextStep}
                        saveEntityAndUser={saveEntityAndUser}
                        collaboratorId={collaboratorId}
                    />
                );
            case 2:
                return (
                    <StepTermsAndConditions
                        nextStep={nextStep}
                        saveTermsAcceptance={() => setFormData((prev) => ({ ...prev, termsAccepted: true }))}
                    />
                );
            case 3:
                return (
                    <Step2ProjectDetails
                        onNextStep={nextStep}
                        onPreviousStep={previousStep}
                        formData={formData}
                        saveProjectId={saveProjectId}
                        legalEntityId={legalEntityId}
                        userId={userId}
                        comId={comId}
                    />
                );
            // case 4:  // Eliminado Step3Details
            //     return (
            //         <Step3Details
            //             projectId={projectId}
            //             nextStep={nextStep}
            //         />
            //     );
            case 4: // Ahora Step4Congratulations es el nuevo Step3
                return (
                    <Step4Congratulations
                        projectId={projectId}
                        nextStep={nextStep}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center p-4 bg-back text-white">
            <div className="bg-back rounded-lg p-6 w-full max-w-5xl lg:w-4/5 relative">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-white bg-gray-600 hover:bg-gray-800 rounded-full w-8 h-8 flex justify-center items-center"
                >
                    <span className="text-lg font-bold">×</span>
                </button>
                {renderStep()}
            </div>
        </div>
    );
};

export default CreateProjectWithEntityModal;
