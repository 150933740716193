import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const HeaderComoFunciona = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.toLowerCase(); // "es", "en", etc.

  // 1) Variables condicionales para meta
  let metaTitle = '';
  let metaDescription = '';
  let metaKeywords = '';
  let faqJsonLd = '';
  let ogTitle = '';
  let ogDescription = '';

  // 2) Lógica según idioma
  if (currentLang === 'es') {
    metaTitle = '¿Cómo Funciona Finanbid? - Descubre el Bidfunding para Promotores';
    metaDescription = 'En Finanbid, los fondos de inversión compiten por tu proyecto. Sube tus datos y recibe ofertas de financiación más rápidas y competitivas.';
    metaKeywords = 'financiación alternativa, bidfunding, fondos de inversión, promotor, obra, capital privado, cómo funciona';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": [
         {
           "@type": "Question",
           "name": "¿Cómo funciona exactamente Finanbid?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Finanbid es una plataforma de bidfunding: subes tu proyecto, los fondos revisan la información y realizan ofertas de financiación para competir entre sí."
           }
         },
         {
           "@type": "Question",
           "name": "¿Qué beneficios tiene el bidfunding?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Mayor competitividad, acceso a múltiples ofertas, y rapidez en la obtención de capital privado para proyectos inmobiliarios u otros sectores."
           }
         },
         {
           "@type": "Question",
           "name": "¿Cuánto tarda el proceso?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Usualmente más rápido que la banca tradicional. Una vez subes la documentación, los fondos interesados pueden pujar en pocos días."
           }
         }
       ]
     }
   `;
  } else if (currentLang === 'en') {
    metaTitle = 'How Does Finanbid Work? - Discover Bidfunding for Promoters';
    metaDescription = 'On Finanbid, investment funds compete to finance your project. Upload your data and receive faster, more competitive offers.';
    metaKeywords = 'alternative financing, bidfunding, investment funds, promoter, construction, private capital, how it works';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": [
         {
           "@type": "Question",
           "name": "How exactly does Finanbid work?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Finanbid is a bidfunding platform: you upload your project, funds review the information and make competing financing offers."
           }
         },
         {
           "@type": "Question",
           "name": "What are the benefits of bidfunding?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Increased competitiveness, access to multiple offers, and faster private capital for real estate or other projects."
           }
         },
         {
           "@type": "Question",
           "name": "How long does the process take?",
           "acceptedAnswer": {
             "@type": "Answer",
             "text": "Usually quicker than traditional banking. Once you upload the documentation, interested funds can bid within days."
           }
         }
       ]
     }
   `;
  } else if (currentLang === 'pt') {
    metaTitle = 'Como Funciona a Finanbid? - Descubra o Bidfunding para Promotores';
    metaDescription = 'Na Finanbid, fundos de investimento competem para financiar seu projeto. Envie seus dados e receba ofertas mais rápidas e competitivas.';
    metaKeywords = 'financiamento alternativo, bidfunding, fundos de investimento, promotor, construção, capital privado';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
  } else if (currentLang === 'sv') {
    metaTitle = 'Hur fungerar Finanbid? - Upptäck budgivning för fastighetsprojekt';
    metaDescription = 'På Finanbid tävlar investeringsfonder om att finansiera ditt projekt. Ladda upp dina uppgifter och få snabbare, mer konkurrenskraftiga erbjudanden.';
    metaKeywords = 'alternativ finansiering, budgivning, investeringsfonder, promotor, privat kapital, hur funkar det';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
  } else if (currentLang === 'de') {
    metaTitle = 'Wie funktioniert Finanbid? - Entdecken Sie Bidfunding für Projekte';
    metaDescription = 'Bei Finanbid konkurrieren Investmentfonds um Ihr Projekt. Laden Sie Ihre Daten hoch und erhalten Sie schnellere, wettbewerbsfähigere Angebote.';
    metaKeywords = 'alternative Finanzierung, bidfunding, Investmentfonds, Promotor, privates Kapital, wie funktioniert es';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
  } else if (currentLang === 'ar') {
    metaTitle = 'كيف تعمل Finanbid؟ - اكتشف تمويل المزايدات للمروجين';
    metaDescription = 'في Finanbid ، تتنافس صناديق الاستثمار على تمويل مشروعك. قم برفع بياناتك واحصل على عروض أسرع وأكثر تنافسية.';
    metaKeywords = 'تمويل بديل، تمويل بالمزايدة، صناديق الاستثمار، المروج، رأسمال خاص, كيف تعمل';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
  } else {
    // fallback: es
    metaTitle = '¿Cómo Funciona Finanbid? - Descubre el Bidfunding para Promotores';
    metaDescription = 'En Finanbid, los fondos de inversión compiten por tu proyecto. Sube tus datos y recibe ofertas de financiación más rápidas y competitivas.';
    metaKeywords = 'financiación alternativa, bidfunding, fondos de inversión, promotor, obra, capital privado, cómo funciona';
    ogTitle = metaTitle;
    ogDescription = metaDescription;
    faqJsonLd = `
     {
       "@context": "https://schema.org",
       "@type": "FAQPage",
       "mainEntity": []
     }
   `;
  }

  // 3) URLs
  const canonicalUrl = "https://www.finanbid.com/comofunciona";

  return (
    <div className="relative isolate overflow-hidden bg-back py-20">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        {/* rel canonical */}
        <link rel="canonical" href={canonicalUrl} />

        {/* hreflang alternates */}
        <link rel="alternate" href="https://www.finanbid.com/comofunciona" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/comofunciona" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/comofunciona" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/comofunciona" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/comofunciona" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/comofunciona" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/comofunciona" hreflang="ar" />

        {/* Open Graph */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@finanbid" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* JSON-LD FAQ */}
        <script type="application/ld+json">
          {faqJsonLd}
        </script>
      </Helmet>
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="background-pattern"
            width="200"
            height="200"
            x="100%"
            y="-1"
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth="0"
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth="0"
          fill="url(#background-pattern)"
        />
      </svg>
      <div
        className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
        aria-hidden="true"
      >
        <div
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-secondary to-primary opacity-20"
          style={{
            clipPath:
              "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
          }}
        ></div>
      </div>
      <div className="flex items-center justify-center py-16">
        <div className="max-w-full flex-shrink-0 px-4 text-center lg:mx-0 lg:max-w-3xl">
          <h1 className="text-5xl font-bold tracking-tight text-white sm:text-6xl">
            {t("headerComoFunciona.title.part1")}
            <span className="text-secondary"> {t("headerComoFunciona.title.part2")} </span>
            {t("headerComoFunciona.title.part3")}
            <span className="text-secondary"> Finanbid</span>
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            {t("headerComoFunciona.subtitle")}
          </p>
          <div className="mt-5 flex items-center justify-center gap-x-6">
            <a
              href="/subir-proyecto"
              className="rounded-md bg-secondary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-400"
              rel="noreferrer"
            >
              {t("headerComoFunciona.button")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComoFunciona;
