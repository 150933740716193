import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfoCircle, faTimes, faFileLines, faMagicWandSparkles, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import ProjectAssistantModal from "./ProjectAssistantModal";
import { useTranslation } from 'react-i18next';
import LTVCards from "./steps/LTVCards";
import { calculateLTV } from "../utils/ltvCalculator";



const ProjectNew = ({ projectId }) => {
    const [projectData, setProjectData] = useState(null); // Datos completos del proyecto
    const [isModalOpen, setIsModalOpen] = useState(false); // Control del modal
    const [currentStep, setCurrentStep] = useState(1); // Step actual para el modal
    const [ltv, setLtv] = useState({ current: 0, final: 0 });
    const { t } = useTranslation();
    const token = localStorage.getItem("token");
    const [selectedImage, setSelectedImage] = useState(null);
    const [showStatusModal, setShowStatusModal] = useState(false); // Estado para el modal de estado
    const [creatingEntity, setCreatingEntity] = useState(false); // Estado para mostrar carga mientras se crea la entidad legal

    const createLegalEntity = async () => {
        try {
            setCreatingEntity(true);

            // Crear una nueva entidad legal
            const response = await fetch('https://api.finanbid.com/api/entity/create-legal-entity', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    business_name: "",
                    business_cif: "",
                    business_address: "",
                    contact_phone: "",
                    contact_email: "",
                    year_of_incorporation: new Date().getFullYear(),
                    user_id: projectData.project.user_id,
                }),
            });

            if (!response.ok) {
                throw new Error("Error al crear la entidad legal");
            }

            const newEntity = await response.json();

            // Asociar la entidad legal al proyecto
            await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}/field`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    field: "legal_entity_id",
                    value: newEntity.id,
                }),
            });

            // Refrescar los datos del proyecto
            await fetchProjectData();
        } catch (error) {
            console.error("Error al crear y asociar la entidad legal:", error);
        } finally {
            setCreatingEntity(false);
        }
    };

    const handleStep7Click = async () => {
        if (!projectData.legalEntity) {
            if (window.confirm("No hay una entidad legal asociada. ¿Deseas crear una nueva entidad?")) {
                await createLegalEntity();
            } else {
                return;
            }
        }
        openModal(7);
    };

    const calculateInitialLTV = (data) => {
        // Validación y valores predeterminados
        const amountRequested = parseFloat(data?.loanDetails?.amount_requested || 0);
        const capitalInvertido = parseFloat(data?.loanDetails?.capital_invertido || 0);
        const assets = Array.isArray(data?.assets) ? data.assets : []; // Si no hay, toma un arreglo vacío
        const collaterals = Array.isArray(data?.collaterals) ? data.collaterals : []; // Si no hay, toma un arreglo vacío

        // Si no hay activos ni colaterales, el LTV debe manejarse como 0
        if (assets.length === 0 && collaterals.length === 0) {
            //console.log("No assets or collaterals provided, defaulting LTV to 0.");
            setLtv({ current: 0, final: 0 }); // LTV predeterminado
            return;
        }

        // Calcular el LTV solo si hay datos válidos
        const newLTV = calculateLTV(amountRequested, capitalInvertido, assets, collaterals);

        // Actualizar el estado de LTV
        setLtv(newLTV);
    };

    useEffect(() => {
        if (projectData) {
            calculateInitialLTV(projectData);
        }

    }, [projectData]);
    // Función para obtener datos del proyecto
    const fetchProjectData = async () => {
        try {
            const response = await fetch(`https://api.finanbid.com/api/admin/projects/${projectId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Error al cargar los datos del proyecto");
            }

            const data = await response.json();
            setProjectData(data);
        } catch (error) {
            console.error("Error al obtener los datos del proyecto:", error);
        }
    };

    useEffect(() => {
        fetchProjectData();

    }, [projectId]);
    useEffect(() => {
        if (projectData) {
            const nonEditableStatuses = ["En Revision", "Visible", "Cancelado", "Finalizado"];
            if (nonEditableStatuses.includes(projectData.project.status)) {
                setShowStatusModal(true);
            }
        }
    }, [projectData]);

    // Abrir modal en un step específico
    const openModal = (step) => {
        setCurrentStep(step);
        setIsModalOpen(true);
    };

    // Cerrar modal y actualizar datos
    const closeModal = () => {
        setIsModalOpen(false);
        fetchProjectData();
        fetchProjectData().then((data) => calculateInitialLTV(data));
    };

    if (!projectData) {
        return <p>Cargando...</p>;
    }

    return (
        <div className="space-y-6">
            {showStatusModal && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg text-center">
                        <h3 className="text-xl font-semibold mb-4">El proyecto no se puede modificar</h3>
                        <p>Este proyecto está en estado: {projectData.project.status}</p>
                        <button
                            onClick={() => window.location.href = 'https://www.finanbid.com/dashboard/projects'}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg"
                        >
                            Volver a Mis Proyectos
                        </button>
                    </div>
                </div>
            )}
            {/* Header */}
            <div className="flex justify-between items-center p-6 bg-white rounded-lg shadow">
                <div>
                    <h1 className="text-xl font-bold text-gray-800">{t(`projectUpload.products.${projectData.project.product_type}`)}</h1>

                    <p className="text-sm text-gray-500">{t('projectNew.status')}: {projectData.project.status}</p>
                </div>
                <button
                    onClick={() => openModal(1)}
                    className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                >
                    <FontAwesomeIcon icon={faMagicWandSparkles} className="mr-2" />
                    Asistente Mágico
                </button>
            </div>

            {/* Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Step 1 Card */}
                <div className="p-6 bg-white rounded-lg shadow flex flex-col">
                    <h2 className="text-lg font-bold text-gray-800 flex items-center mb-4">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-secondary" />
                        {t('projectNew.projectDescription')}
                    </h2>
                    <p className="text-sm text-gray-600 flex-grow">{projectData.project.resumen || t('projectNew.noDescription')}</p>

                    {/* New Section for Address */}
                    <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-sm border border-gray-200">
                        <div className="mb-2">
                            <label className="block text-xs font-medium text-gray-500">{t('projectNew.address')}</label>
                            <p className="text-sm text-gray-700">{projectData.project.direccion || t('projectNew.noAddress')}</p>
                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-2">
                            <div>
                                <label className="block text-xs font-medium text-gray-500">{t('projectNew.locality')}</label>
                                <p className="text-sm text-gray-700">{projectData.project.localidad || t('projectNew.noLocality')}</p>
                            </div>
                            <div>
                                <label className="block text-xs font-medium text-gray-500">{t('projectNew.province')}</label>
                                <p className="text-sm text-gray-700">{projectData.project.provincia || t('projectNew.noProvince')}</p>
                            </div>
                        </div>
                        <div>
                            <label className="block text-xs font-medium text-gray-500">{t('projectNew.country')}</label>
                            <p className="text-sm text-gray-700">{projectData.project.pais || t('projectNew.noCountry')}</p>
                        </div>
                    </div>

                    <div className="mt-4">
                        <button
                            onClick={() => openModal(1)}
                            className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            {t('projectNew.edit')}
                        </button>
                    </div>
                </div>

                {/* Step 2 Card */}
                <div className="p-6 bg-white rounded-lg shadow space-y-4">
                    <h2 className="text-lg font-bold text-gray-800 flex items-center">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-secondary" />
                        {t('projectNew.loanDetails')}
                    </h2>

                    <div className="space-y-1">
                        <p className="text-sm text-gray-600">
                            <span className="font-bold">{t('projectNew.amountRequested')}:</span> {projectData.loanDetails.amount_requested || t('projectNew.notAvailable')}
                        </p>
                        <p className="text-sm text-gray-600">
                            <span className="font-bold">{t('projectNew.investedCapital')}:</span> {projectData.loanDetails.capital_invertido || t('projectNew.notAvailable')}
                        </p>
                        <p className="text-sm text-gray-600">
                            <span className="font-bold">{t('projectNew.loanDuration')}:</span> {projectData.loanDetails.duracion_prestamo || t('projectNew.notAvailable')}
                        </p>
                        <p className="text-sm text-gray-600">
                            <span className="font-bold">{t('projectNew.repayment')}:</span> {projectData.loanDetails.repago || t('projectNew.notAvailable')}
                        </p>
                    </div>

                    {/* LTVCards */}
                    <LTVCards ltv={ltv} />

                    <button
                        onClick={() => openModal(2)}
                        className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                    >
                        <FontAwesomeIcon icon={faEdit} className="mr-2" />
                        {t('projectNew.edit')}
                    </button>
                </div>



                {/* Step 3 Card */}
                <div className="p-6 bg-white rounded-lg shadow space-y-4">
                    <h2 className="text-lg font-bold text-gray-800 flex items-center">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-secondary" />
                        {t('projectNew.projectAssets')}
                    </h2>
                    <p className="text-sm text-gray-600">{projectData.assets.length} {t('projectNew.assetsRegistered')}</p>

                    {/* Listado de activos */}
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm text-left text-gray-600 border border-gray-200">
                            <thead className="bg-gray-100 text-gray-700">
                                <tr>
                                    <th className="px-4 py-2 border-b">{t('projectNew.assetType')}</th>
                                    <th className="px-4 py-2 border-b">{t('projectNew.propertyRegistry')}</th>
                                    <th className="px-4 py-2 border-b">{t('projectNew.valuation')}</th>
                                    <th className="px-4 py-2 border-b">{t('projectNew.documents')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projectData.assets.map((asset, index) => (
                                    <tr key={index} className="border-b">
                                        <td className="px-4 py-2">{asset.type || t('projectNew.notAvailable')}</td>
                                        <td className="px-4 py-2">{asset.registro_propiedad || t('projectNew.notAvailable')}</td>
                                        <td className="px-4 py-2">&euro;{Math.round(parseFloat(asset.valor_tasacion_final || 0)).toLocaleString()}</td>
                                        <td className="px-4 py-2">
                                            {asset.documents && asset.documents.length > 0 ? (
                                                <div className="flex gap-2">
                                                    {asset.documents.map((doc, docIndex) => (
                                                        <button
                                                            key={docIndex}
                                                            onClick={() => window.open(doc.document_url, '_blank')}
                                                            className="text-blue-500 hover:underline"
                                                            title={doc.document_type || t('projectNew.document')}
                                                        >
                                                            <FontAwesomeIcon icon={faFileLines} />
                                                        </button>
                                                    ))}
                                                </div>
                                            ) : (
                                                t('projectNew.noDocuments')
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Botón Editar */}
                    <div className="mt-4">
                        <button
                            onClick={() => openModal(3)}
                            className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            {t('projectNew.edit')}
                        </button>
                    </div>
                </div>


                {/* Step 4 Card */}
                <div className="p-6 bg-white rounded-lg shadow space-y-4">
                    <h2 className="text-lg font-bold text-gray-800 flex items-center">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-secondary" />
                        {t('projectNew.collateralGuarantees')}
                    </h2>
                    <p className="text-sm text-gray-600">{projectData.collaterals.length} {t('projectNew.collateralsRegistered')}</p>
                    {projectData.collaterals.length > 0 ? (
                        <div className="overflow-x-auto">
                            <table className="min-w-full table-auto border-collapse border border-gray-200">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-4 py-2 border border-gray-200 text-left text-sm font-bold text-gray-700">
                                            {t('projectNew.collateralType')}
                                        </th>
                                        <th className="px-4 py-2 border border-gray-200 text-left text-sm font-bold text-gray-700">
                                            {t('projectNew.totalGuaranteeValue')}
                                        </th>
                                        <th className="px-4 py-2 border border-gray-200 text-left text-sm font-bold text-gray-700">
                                            {t('projectNew.documents')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {projectData.collaterals.map((collateral) => (
                                        <tr key={collateral.id} className="hover:bg-gray-50">
                                            <td className="px-4 py-2 border border-gray-200 text-sm text-gray-600">
                                                {collateral.tipo_garantia || t('projectNew.notAvailable')}
                                            </td>
                                            <td className="px-4 py-2 border border-gray-200 text-sm text-gray-600">
                                                {new Intl.NumberFormat('es-ES', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                    maximumFractionDigits: 0,
                                                }).format(collateral.valor_total_garantia || 0)}
                                            </td>
                                            <td className="px-4 py-2 border border-gray-200 text-sm text-gray-600 flex items-center gap-2">
                                                {collateral.documents && collateral.documents.length > 0 ? (
                                                    collateral.documents.map((doc, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => window.open(doc.document_url, '_blank')}
                                                            className="text-blue-500 hover:underline"
                                                            title={doc.document_type}
                                                        >
                                                            <FontAwesomeIcon icon={faFileLines} />
                                                        </button>
                                                    ))
                                                ) : (
                                                    <span>{t('projectNew.noDocuments')}</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className="text-sm text-gray-600">{t('projectNew.noCollaterals')}</p>
                    )}
                    <div className="mt-4">
                        <button
                            onClick={() => openModal(4)}
                            className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            {t('projectNew.edit')}
                        </button>
                    </div>
                </div>

            </div>
            {/* Cards Layout */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Left Column */}
                <div className="space-y-6 md:col-span-1">
                    {/* Step 5 Card */}
                    <div className="p-6 bg-white rounded-lg shadow space-y-4">
                        <h2 className="text-lg font-bold text-gray-800 flex items-center">
                            <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-secondary" />
                            {t('projectNew.projectDocuments')}
                        </h2>
                        {projectData.documents.length > 0 ? (
                            <div className="divide-y divide-gray-300">
                                <div className="flex justify-between py-2 text-sm font-bold text-gray-800">
                                    <span>{t('projectNew.documentType')}</span>
                                    <span>{t('projectNew.year')}</span>
                                    <span>{t('projectNew.actions')}</span>
                                </div>
                                {projectData.documents.map((doc, index) => (
                                    <div
                                        key={index}
                                        className="flex justify-between items-center py-2 text-sm text-gray-600"
                                    >
                                        <span className="w-1/3 truncate">{doc.document_type || t('projectNew.unknownType')}</span>
                                        <span className="w-1/3 text-center">{doc.year || t('projectNew.unknownYear')}</span>
                                        <a
                                            href={doc.document_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline w-1/3 text-right"
                                        >
                                            <FontAwesomeIcon icon={faFileAlt} className="mr-1" />
                                            {t('projectNew.view')}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-sm text-gray-600">{t('projectNew.noDocumentsUploaded')}</p>
                        )}
                        <button
                            onClick={() => openModal(5)}
                            className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                        >
                            <FontAwesomeIcon icon={faEdit} className="mr-2" />
                            {t('projectNew.edit')}
                        </button>
                    </div>

                {/* Step 7 Card */}
<div className="p-6 bg-white rounded-lg shadow space-y-6">
    <h2 className="text-lg font-bold text-gray-800 flex items-center">
        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-secondary" />
        {t('projectNew.legalEntity')}
    </h2>

    {/* Verificar si se está creando la entidad */}
    {creatingEntity ? (
        <p>Cargando...</p>
    ) : projectData.legalEntity ? (
        // Mostrar detalles de la entidad legal si existe
        <div>
            <div className="space-y-2">
                <p className="text-sm text-gray-600">
                    <strong>{t('projectNew.businessName')}:</strong> {projectData.legalEntity.business_name || t('projectNew.notRegistered')}
                </p>
                <p className="text-sm text-gray-600">
                    <strong>{t('projectNew.businessCIF')}:</strong> {projectData.legalEntity.business_cif || t('projectNew.notRegistered')}
                </p>
                <p className="text-sm text-gray-600">
                    <strong>{t('projectNew.contactPhone')}:</strong> {projectData.legalEntity.contact_phone || t('projectNew.notRegistered')}
                </p>
                <p className="text-sm text-gray-600">
                    <strong>{t('projectNew.contactEmail')}:</strong> {projectData.legalEntity.contact_email || t('projectNew.notRegistered')}
                </p>
            </div>

            {/* Detalles de Administradores */}
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                    <h3 className="text-md font-bold text-gray-800">{t('projectNew.administrator')}</h3>
                    {projectData.legalEntity.administrators?.length > 0 ? (
                        projectData.legalEntity.administrators.map((admin, index) => (
                            <div key={index} className="space-y-1">
                                <p className="text-sm text-gray-600">
                                    <strong>{t('projectNew.name')}:</strong> {admin.administrator_name}
                                </p>
                                <p className="text-sm text-gray-600">
                                    <strong>{t('projectNew.id')}:</strong> {admin.document_id}
                                </p>
                                <p className="text-sm text-gray-600">
                                    <strong>{t('projectNew.nationality')}:</strong> {admin.nationality}
                                </p>
                                <p className="text-sm text-gray-600">
                                    <strong>{t('projectNew.fiscalAddress')}:</strong> {admin.fiscal_address}
                                </p>
                            </div>
                        ))
                    ) : (
                        <p className="text-sm text-gray-600">{t('projectNew.noAdministrator')}</p>
                    )}
                </div>

                {/* Detalles de Accionistas */}
                <div className="space-y-2">
                    <h3 className="text-md font-bold text-gray-800">{t('projectNew.shareholders')}</h3>
                    {projectData.legalEntity.shareholders?.length > 0 ? (
                        <ul className="space-y-1">
                            {projectData.legalEntity.shareholders.map((shareholder, index) => (
                                <li key={index} className="text-sm text-gray-600">
                                    {shareholder.shareholder_name} ({shareholder.participation}%)
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="text-sm text-gray-600">{t('projectNew.noShareholders')}</p>
                    )}
                </div>
            </div>

            {/* Documentos */}
            <div className="mt-4 space-y-2">
                <h3 className="text-md font-bold text-gray-800">{t('projectNew.documents')}</h3>
                {projectData.legalEntity.documents?.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full text-left text-sm">
                            <thead>
                                <tr className="text-gray-800">
                                    <th className="px-4 py-2 font-bold">{t('projectNew.documentType')}</th>
                                    <th className="px-4 py-2 font-bold">{t('projectNew.year')}</th>
                                    <th className="px-4 py-2 font-bold">{t('projectNew.actions')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projectData.legalEntity.documents.map((doc, index) => (
                                    <tr key={index} className="border-b border-gray-200">
                                        <td className="px-4 py-2 text-gray-600">{doc.document_type || t('projectNew.unknownType')}</td>
                                        <td className="px-4 py-2 text-gray-600">{doc.year || t('projectNew.unknownYear')}</td>
                                        <td className="px-4 py-2 text-blue-500 hover:underline">
                                            <a
                                                href={doc.document_pdf}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('projectNew.view')}
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="text-sm text-gray-600">{t('projectNew.noDocuments')}</p>
                )}
            </div>

            {/* Botón para editar */}
            <button
                onClick={() => openModal(7)}
                className="mt-4 flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
            >
                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                {t('projectNew.edit')}
            </button>
        </div>
    ) : (
        // Mostrar mensaje y botón para crear entidad legal
        <div>
            <p className="text-sm text-gray-600">{t('projectNew.noLegalEntity')}</p>
            <button
                onClick={handleStep7Click}
                className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
            >
                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                {t('projectNew.createEntity')}
            </button>
        </div>
    )}
</div>
</div>

                {/* Right Column */}
                {/* Step 6 Card */}
                <div className="p-6 bg-white rounded-lg shadow space-y-4 md:row-span-2 md:col-span-1">
                    <h2 className="text-lg font-bold text-gray-800 flex items-center">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-secondary" />
                        {t("projectNew.projectImages")}
                    </h2>
                    {projectData.images.length > 0 ? (
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 max-h-96 overflow-y-auto">
                            {projectData.images.map((image, index) => (
                                <div
                                    key={index}
                                    className="relative w-full pb-[75%] bg-gray-200 rounded-lg overflow-hidden cursor-pointer"
                                    onClick={() => setSelectedImage(image.image_url)}
                                >
                                    <img
                                        src={image.image_url}
                                        alt={t("projectNew.projectImage")}
                                        className="absolute inset-0 w-full h-full object-cover"
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-sm text-gray-600">{t("projectNew.noImagesUploaded")}</p>
                    )}
                    <button
                        onClick={() => openModal(6)}
                        className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-lg shadow-lg hover:shadow-xl transition duration-300 hover:from-purple-600 hover:to-blue-500"
                    >
                        <FontAwesomeIcon icon={faEdit} className="mr-2" />
                        {t("projectNew.edit")}
                    </button>

                    {/* Popup Modal for Image Preview */}
                    {selectedImage && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                            <div className="relative w-4/5 h-4/5">
                                <button
                                    onClick={() => setSelectedImage(null)}
                                    className="absolute top-4 right-4 text-white bg-gray-800 rounded-full p-2 hover:bg-gray-700 transition"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                                <img
                                    src={selectedImage}
                                    alt={t("projectNew.projectImage")}
                                    className="w-full h-full object-contain rounded-lg"
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>


            {/* Modal */}
            <ProjectAssistantModal
                isOpen={isModalOpen}
                onClose={closeModal}
                projectId={projectId}
                initialStep={currentStep}
            />
        </div>
    );


};

export default ProjectNew;
