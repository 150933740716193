// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa las traducciones
import translationES from './locales/es.json';
import translationEN from './locales/en.json';
import translationSV from './locales/sv.json';
import translationDE from './locales/de.json';
import translationAR from './locales/ar.json';
import translationPT from './locales/pt.json';

// Obtener el idioma desde localStorage o usar 'es' como idioma predeterminado
const savedLanguage = localStorage.getItem('i18nextLng') || 'es';

// Configura i18n
i18n.use(initReactI18next).init({
  resources: {
    es: { translation: translationES },
    pt: { translation: translationPT },
    en: { translation: translationEN },
    sv: { translation: translationSV },
    de: { translation: translationDE },
    ar: { translation: translationAR }
  },
  lng: savedLanguage, // Usar el idioma guardado
  fallbackLng: 'es', // Idioma de reserva si no se encuentra la traducción
  interpolation: {
    escapeValue: false // React ya escapa por defecto
  }
});

export default i18n;