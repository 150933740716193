import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import socket from '../../utils/socket';

const Inbox = ({ setInboxCount }) => {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [attachment, setAttachment] = useState(null);

  const token = localStorage.getItem('token');
  const userId = parseInt(localStorage.getItem('userId'), 10);
  const role = localStorage.getItem('role'); // 'fund'

  // --------------------------------------------
  // Cargar conversaciones (ROLE=fund)
  // --------------------------------------------
  useEffect(() => {
    if (!userId || !role) return;

    const fetchConversations = async () => {
      try {
        const response = await axios.get(
          `https://api.finanbid.com/api/conversations?userId=${userId}&role=${role}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const updatedConversations = response.data.map((conv) => ({
          ...conv,
          isUnread: false,
          messages: [],
        }));

        setConversations(updatedConversations);
        socket.emit('registerUser', userId);
      } catch (error) {
        console.error('Error al cargar las conversaciones:', error);
      }
    };

    fetchConversations();

    return () => {
      socket.off('newMessageGlobal');
    };
  }, [userId, role]);

  // --------------------------------------------
  // Escuchar notificaciones globales para nuevos mensajes
  // --------------------------------------------
  useEffect(() => {
    const handleNewMessage = (newMsg) => {
      // Solo procesar el mensaje si NO es del propio usuario
      if (newMsg.senderId !== userId) {
        // Actualizar el listado de conversaciones
        setConversations((prev) =>
          prev.map((conv) =>
            conv.conversation_id === newMsg.conversation_id
              ? { ...conv, isUnread: true, last_message: newMsg.message }
              : conv
          )
        );

        // Actualizar el contador de mensajes no leídos si la conversación no está activa
        if (newMsg.conversation_id !== selectedConversation?.conversation_id) {
          setInboxCount((prev) => prev + 1);
        }
      }

      // Si la conversación está abierta, agregar el nuevo mensaje
      if (newMsg.conversation_id === selectedConversation?.conversation_id) {
        setSelectedConversation((prev) => ({
          ...prev,
          messages: [...(prev.messages || []), newMsg],
        }));
      }
    };

    socket.on('newMessageGlobal', handleNewMessage);

    return () => {
      socket.off('newMessageGlobal', handleNewMessage);
    };
  }, [selectedConversation, userId, setInboxCount]);

  // --------------------------------------------
  // Seleccionar conversación y cargar mensajes
  // --------------------------------------------
  const handleSelectConversation = async (conversation) => {
    setSelectedConversation(conversation);

    try {
      const response = await axios.get(
        `https://api.finanbid.com/api/messages?conversationId=${conversation.conversation_id}&userId=${userId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const messages = response.data.map((msg) => ({
        ...msg,
        fileUrl: msg.file_url || null,
        fileName: msg.file_name || null,
      }));

      setSelectedConversation((prev) => ({
        ...prev,
        messages,
      }));

      // Marcar mensajes como leídos en el backend
      await axios.post(
        'https://api.finanbid.com/api/messages/mark-as-read',
        { conversationId: conversation.conversation_id, userId, role },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Actualizar el contador global
      setInboxCount((prev) => Math.max(0, prev - 1));

      // Actualizar estado local para marcar como leída
      setConversations((prev) =>
        prev.map((conv) =>
          conv.conversation_id === conversation.conversation_id
            ? { ...conv, isUnread: false }
            : conv
        )
      );
    } catch (error) {
      console.error('Error al cargar los mensajes:', error);
    }
  };

  // --------------------------------------------
  // Enviar mensaje
  // --------------------------------------------
  const handleSendMessage = async () => {
    if (!newMessage.trim() && !attachment) return;
    if (!selectedConversation) return;

    const { conversation_id, estado_conversacion, recipient_id, cliente_final_id } = selectedConversation;

    let finalRecipientId = null;
    let finalRecipientRole = null;

    // Lógica para definir el destinatario según el estado de la conversación
    if (estado_conversacion === 'finanbid_intermediario') {
      finalRecipientId = recipient_id;  // Admin de Finanbid
      finalRecipientRole = 'admin';
    } else if (estado_conversacion === 'con_promotor') {
      finalRecipientId = recipient_id;  // Finanbid todavía está involucrado
      finalRecipientRole = 'admin';
    } else if (estado_conversacion === 'directa_fondo_promotor') {
      finalRecipientId = cliente_final_id;  // El promotor ahora es el destinatario
      finalRecipientRole = 'promotor';
    }

    if (!finalRecipientId) {
      console.error('No se ha podido determinar el destinatario del mensaje.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('conversationId', conversation_id);
      formData.append('senderId', userId);
      formData.append('senderRole', role);
      formData.append('recipientId', finalRecipientId);
      formData.append('recipientRole', finalRecipientRole);
      if (newMessage.trim()) formData.append('message', newMessage);
      if (attachment) formData.append('attachment', attachment);

      const response = await axios.post('https://api.finanbid.com/api/messages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      // Añadir el mensaje solo localmente, no esperar que el WebSocket lo procese de nuevo
      setSelectedConversation((prev) => ({
        ...prev,
        messages: [...(prev.messages || []), { ...response.data, senderId: userId }],
      }));

      // Emitir mensaje al WebSocket para tiempo real
      socket.emit('sendMessage', {
        conversation_id,
        senderId: userId,
        recipientId: finalRecipientId,
        message: newMessage,
      });

      setNewMessage('');
      setAttachment(null);
    } catch (error) {
      console.error('Error al enviar el mensaje:', error.response?.data || error.message);
    }
  };

  // --------------------------------------------
  // Helper para mostrar remitente/rol
  // --------------------------------------------
  const getSenderLabel = (msg) => {
    if (msg.sender_id === userId) return 'Yo';
    if (msg.sender_role === 'admin') return 'Finanbid';
    if (msg.sender_role === 'promotor') return 'Cliente';
    return 'Desconocido';
  };

  const getMessageBgColor = (msg) => {
    if (msg.sender_id === userId) return 'bg-green-200 text-right';
    if (msg.sender_role === 'admin') return 'bg-gray-200 text-left';
    return 'bg-blue-100 text-left';
  };

  return (
    <div className="flex h-full">
      {/* LISTA DE CONVERSACIONES */}
      <div className="w-1/3 bg-gray-100 p-4 border-r overflow-y-auto relative">
        <h2 className="text-xl font-bold mb-4">{t('inbox.conversations')}</h2>
        <div>
          {conversations.length > 0 ? (
            conversations.map((conversation) => (
              <div
                key={conversation.conversation_id}
                onClick={() => handleSelectConversation(conversation)}
                className={`p-3 mb-2 cursor-pointer rounded-lg ${
                  selectedConversation?.conversation_id === conversation.conversation_id
                    ? 'bg-blue-200'
                    : conversation.isUnread
                    ? 'bg-yellow-100'
                    : 'bg-white hover:bg-gray-200'
                }`}
              >
                <p className="font-semibold">{`Project #${conversation.project_id || '???'}`}</p>
                <p className="text-sm text-gray-600">{conversation.last_message || t('inbox.noMessages')}</p>
                <p className="text-xs text-gray-500">{conversation.user_email || t('inbox.unknownUser')}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-600">{t('inbox.noConversations')}</p>
          )}
        </div>
      </div>

      {/* DETALLE DE LA CONVERSACIÓN */}
      <div className="w-2/3 p-4 flex flex-col justify-between">
        {selectedConversation ? (
          <>
            <div className="flex-1 overflow-y-auto mb-4">
              <h2 className="text-xl font-bold mb-4">
                {t('inbox.conversation')} #{selectedConversation.conversation_id}
              </h2>
              {(selectedConversation.messages || []).map((msg, index) => (
                <div
                  key={index}
                  className={`mb-2 flex ${msg.sender_id === userId ? 'justify-end' : 'justify-start'}`}
                >
                  <div className={`inline-block p-3 rounded-lg ${getMessageBgColor(msg)}`}>
                    <p className="font-bold text-sm mb-1">{getSenderLabel(msg)}</p>
                    <p className="text-sm">{msg.message}</p>
                    {msg.file_url && (
                      <a
                        href={msg.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {t('inbox.viewAttachment')}
                      </a>
                    )}
                    <p className="text-xs text-gray-500 mt-1">
                      {new Date(msg.fecha_creacion).toLocaleString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center mb-2">
              <input
                type="file"
                onChange={(e) => setAttachment(e.target.files[0])}
                className="p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="flex items-center">
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={t('inbox.typeMessage')}
                rows="4"
                className="flex-1 p-2 border border-gray-300 rounded-l-lg"
              />
              <button
                onClick={handleSendMessage}
                className="p-2 bg-blue-500 ml-3 text-white rounded-r-lg hover:bg-blue-600"
              >
                {t('inbox.send')}
              </button>
            </div>
          </>
        ) : (
          <p className="text-gray-600">{t('inbox.selectConversation')}</p>
        )}
      </div>
    </div>
  );
};

export default Inbox;
