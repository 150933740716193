import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ProjectCard from './ProjectCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEuroSign,
  faGlobe,
  faMapMarkerAlt,
  faChartBar,
  faBuilding,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";

const SearchOperations = () => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        amountRequested: [0, 250000000],
        selectedCountries: [],
        selectedProvinces: [],
        ltv: 0,
        sector: '',
        subsector: '',
        productType: '',
    });
    const [sectors, setSectors] = useState([]);
    const [subsectors, setSubsectors] = useState([]);
    const [products, setProducts] = useState([]);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Fetch sectors
        axios
            .get('https://api.finanbid.com/api/sectors')
            .then((res) => setSectors(res.data))
            .catch((err) => console.error('Error fetching sectors:', err));
    }, []);

    useEffect(() => {
        // Fetch products and subsectors when sector changes
        if (filters.sector) {
            axios
                .get(`https://api.finanbid.com/api/products?sector_id=${filters.sector}`)
                .then((res) => setProducts(res.data))
                .catch((err) => console.error('Error fetching products:', err));

            axios
                .get(`https://api.finanbid.com/api/subsectors?sector_id=${filters.sector}`)
                .then((res) => setSubsectors(res.data))
                .catch((err) => console.error('Error fetching subsectors:', err));
        } else {
            setProducts([]);
            setSubsectors([]);
        }
    }, [filters.sector]);

    const handleFilterChange = (field, value) => {
        setFilters((prevFilters) => {
            if (field === 'amountRequested') {
                // Actualizar ambos valores del rango
                return {
                    ...prevFilters,
                    amountRequested: value,
                };
            }

            // Para otros campos
            return {
                ...prevFilters,
                [field]: value,
            };
        });
    };

    const fetchProjects = async () => {
        try {
            // Crear un objeto de filtros sin campos vacíos
            const activeFilters = {};
            for (const key in filters) {
                if (
                    filters[key] !== '' && // Excluir cadenas vacías
                    filters[key] !== null &&
                    filters[key].length !== 0 // Excluir arrays vacíos
                ) {
                    activeFilters[key] = filters[key];
                }
            }

            const response = await axios.post('https://api.finanbid.com/api/projects/search', activeFilters);
            setProjects(response.data); // Actualizar el estado con los proyectos filtrados
        } catch (error) {
            console.error('Error al buscar proyectos:', error);
        }
    };

    return (
        <div className="container mx-auto p-4">
          {/* Formulario de búsqueda optimizado */}
          <div className="bg-white shadow-md rounded-lg p-4 mb-6 ">
            <h2 className="text-lg font-semibold flex items-center gap-2 mb-6 text-secondary">
              <FontAwesomeIcon icon={faSearch} className="text-secondary" /> {t("search.title")}
            </h2>
      
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
              {/* Filtro de Cantidad Requerida */}
              <div>
                <label className="flex items-center text-sm font-medium mb-1 text-gray-700">
                  <FontAwesomeIcon icon={faEuroSign} className="text-secondary mr-2" />
                  {t("search.amountRequested")}
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={filters.amountRequested[0].toLocaleString("es-ES")}
                    onFocus={(e) => (e.target.value = filters.amountRequested[0])}
                    onBlur={(e) => {
                      const value = parseInt(e.target.value.replace(/[^\d]/g, "")) || 0;
                      handleFilterChange("amountRequested", [value, filters.amountRequested[1]]);
                    }}
                    className="w-full border text-sm  rounded px-2 py-1"
                    placeholder={t("search.amountFrom")}
                  />
                  <span className="text-gray-600">—</span>
                  <input
                    type="text"
                    value={filters.amountRequested[1].toLocaleString("es-ES")}
                    onFocus={(e) => (e.target.value = filters.amountRequested[1])}
                    onBlur={(e) => {
                      const value = parseInt(e.target.value.replace(/[^\d]/g, "")) || 0;
                      handleFilterChange("amountRequested", [filters.amountRequested[0], value]);
                    }}
                    className="w-full border text-sm rounded px-2 py-1"
                    placeholder={t("search.amountTo")}
                  />
                </div>
              </div>
      
              {/* Filtro de País */}
              <div>
                <label className="flex items-center text-sm font-medium mb-1 text-gray-700">
                  <FontAwesomeIcon icon={faGlobe} className="text-secondary mr-2" />
                  {t("search.country")}
                </label>
                <select
                  //multiple
                  value={filters.selectedCountries.length > 0 ? filters.selectedCountries[0] : ""}
                  onChange={(e) =>
                    handleFilterChange(
                      "selectedCountries",
                      Array.from(e.target.selectedOptions, (option) => option.value)
                    )
                  }
                  className="w-full border text-sm rounded px-2 py-1"
                >
                  <option value="">{t("search.allCountries")}</option>
                  <option value="España">España</option>
                  <option value="Francia">Francia</option>
                  <option value="Alemania">Alemania</option>
                  <option value="México">México</option>
                  <option value="Argentina">Argentina</option>
                </select>
              </div>
      
              {/* Filtro de Provincia (Si el país es España) */}
              {filters.selectedCountries.includes("España") && (
                <div>
                  <label className="flex items-center text-sm font-medium mb-1 text-gray-700">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-secondary mr-2" />
                    {t("search.province")}
                  </label>
                  <select
                    multiple
                    value={filters.selectedProvinces}
                    onChange={(e) =>
                      handleFilterChange(
                        "selectedProvinces",
                        Array.from(e.target.selectedOptions, (option) => option.value)
                      )
                    }
                    className="w-full border text-sm rounded px-2 py-1"
                  >
                                                <option value="Albacete">Albacete</option>
                            <option value="Alicante">Alicante</option>
                            <option value="Almería">Almería</option>
                            <option value="Asturias">Asturias</option>
                            <option value="Ávila">Ávila</option>
                            <option value="Badajoz">Badajoz</option>
                            <option value="Barcelona">Barcelona</option>
                            <option value="Burgos">Burgos</option>
                            <option value="Cáceres">Cáceres</option>
                            <option value="Cádiz">Cádiz</option>
                            <option value="Cantabria">Cantabria</option>
                            <option value="Castellón">Castellón</option>
                            <option value="Ciudad Real">Ciudad Real</option>
                            <option value="Córdoba">Córdoba</option>
                            <option value="Cuenca">Cuenca</option>
                            <option value="Girona">Girona</option>
                            <option value="Granada">Granada</option>
                            <option value="Guadalajara">Guadalajara</option>
                            <option value="Guipúzcoa">Guipúzcoa</option>
                            <option value="Huelva">Huelva</option>
                            <option value="Huesca">Huesca</option>
                            <option value="Islas Baleares">Islas Baleares</option>
                            <option value="Jaén">Jaén</option>
                            <option value="La Coruña">La Coruña</option>
                            <option value="La Rioja">La Rioja</option>
                            <option value="Las Palmas">Las Palmas</option>
                            <option value="León">León</option>
                            <option value="Lleida">Lleida</option>
                            <option value="Lugo">Lugo</option>
                            <option value="Madrid">Madrid</option>
                            <option value="Málaga">Málaga</option>
                            <option value="Murcia">Murcia</option>
                            <option value="Navarra">Navarra</option>
                            <option value="Ourense">Ourense</option>
                            <option value="Palencia">Palencia</option>
                            <option value="Pontevedra">Pontevedra</option>
                            <option value="Salamanca">Salamanca</option>
                            <option value="Santa Cruz de Tenerife">Santa Cruz de Tenerife</option>
                            <option value="Segovia">Segovia</option>
                            <option value="Sevilla">Sevilla</option>
                            <option value="Soria">Soria</option>
                            <option value="Tarragona">Tarragona</option>
                            <option value="Teruel">Teruel</option>
                            <option value="Toledo">Toledo</option>
                            <option value="Valencia">Valencia</option>
                            <option value="Valladolid">Valladolid</option>
                            <option value="Vizcaya">Vizcaya</option>
                            <option value="Zamora">Zamora</option>
                            <option value="Zaragoza">Zaragoza</option>
                            <option value="Ceuta">Ceuta</option>
                            <option value="Melilla">Melilla</option>   
                  </select>
                </div>
              )}
      
              {/* Filtro de LTV */}
              <div>
                <label className="flex items-center text-sm font-medium mb-1 text-gray-700">
                  <FontAwesomeIcon icon={faChartBar} className="text-secondary mr-2" />
                  {t("search.ltv")}
                </label>
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={filters.ltv}
                  onChange={(e) => handleFilterChange("ltv", parseInt(e.target.value) || 0)}
                  className="w-full border text-sm rounded px-2 py-1"
                />
              </div>
      
              {/* Filtro de Sector */}
              <div>
                <label className="flex items-center text-sm font-medium mb-1 text-gray-700">
                  <FontAwesomeIcon icon={faBuilding} className="text-secondary mr-2" />
                  {t("search.sector")}
                </label>
                <select
                  value={filters.sector}
                  onChange={(e) => handleFilterChange("sector", e.target.value)}
                  className="w-full border text-sm rounded px-2 py-1"
                >
                  <option value="">{t("projectUpload.step2.selectSector")}</option>
                  {sectors.map((sector) => (
                    <option key={sector.id} value={sector.id}>
                      {sector.name}
                    </option>
                  ))}
                </select>
              </div>
      
              {/* Filtro de Subsector */}
              {filters.sector && (
                <div>
                  <label className="flex items-center text-sm font-medium mb-1 text-gray-700">
                    <FontAwesomeIcon icon={faLayerGroup} className="text-secondary mr-2" />
                    {t("search.subsector")}
                  </label>
                  <select
                    value={filters.subsector}
                    onChange={(e) => handleFilterChange("subsector", e.target.value)}
                    className="w-full border text-sm rounded px-2 py-1"
                  >
                    <option value="">{t("search.selectSubsector")}</option>
                    {subsectors.map((subsector) => (
                      <option key={subsector.id} value={subsector.id}>
                        {subsector.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
      
            {/* Botón de búsqueda */}
            <div className="flex justify-end mt-4">
              <button
                onClick={fetchProjects}
                className="bg-secondary text-white py-2 px-6 rounded-lg shadow-md hover:bg-opacity-90 transition"
              >
                {t("search.searchButton")}
              </button>
            </div>
          </div>
      
          {/* Resultados de la búsqueda */}
          <h3 className="text-lg font-semibold mb-4">{t("search.resultsTitle")}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {projects.map((project) => (
              <ProjectCard
                key={project.id}
                project={{
                  ...project,
                  sector:
                    sectors.find((sec) => sec.id === project.sector_id)?.name ||
                    t("search.unknownSector"),
                  subsector:
                    subsectors.find((sub) => sub.id === project.subsector_id)?.name ||
                    t("search.unknownSubsector"),
                }}
              />
            ))}
          </div>
        </div>
      );
};

export default SearchOperations;
