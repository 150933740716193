import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon, OfficeBuildingIcon, BriefcaseIcon, LightBulbIcon } from '@heroicons/react/solid';

const Step2ProjectDetails = ({ onNextStep, onPreviousStep, formData = {}, saveProjectId, legalEntityId, userId, comId }) => {
  const { t } = useTranslation();

  const [sector, setSector] = useState(formData.sector || '');
  const [subsector, setSubsector] = useState(formData.subsector || '');
  const [productType, setProductType] = useState(formData.productType || '');
  const [province, setProvince] = useState(formData.province || '');
  const [city, setCity] = useState(formData.city || '');
  const [description, setDescription] = useState(formData.description || '');
  const [amountRequested, setAmountRequested] = useState(formData.amountRequested || '');
  const [errorMessage, setErrorMessage] = useState(null);

  const [products, setProducts] = useState([]);
  const [subsectors, setSubsectors] = useState([]);
  const provinces = [
    'Álava', 'Albacete', 'Alicante', 'Almería', 'Ávila', 'Badajoz', 'Barcelona',
    'Burgos', 'Cáceres', 'Cádiz', 'Cantabria', 'Castellón', 'Ciudad Real',
    'Córdoba', 'Cuenca', 'Girona', 'Granada', 'Guadalajara', 'Guipúzcoa',
    'Huelva', 'Huesca', 'Illes Balears', 'Jaén', 'La Coruña', 'La Rioja',
    'Las Palmas', 'León', 'Lérida', 'Lugo', 'Madrid', 'Málaga', 'Murcia',
    'Navarra', 'Orense', 'Palencia', 'Pontevedra', 'Salamanca', 'Santa Cruz de Tenerife',
    'Segovia', 'Sevilla', 'Soria', 'Tarragona', 'Teruel', 'Toledo', 'Valencia',
    'Valladolid', 'Vizcaya', 'Zamora', 'Zaragoza'
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      onPreviousStep();
    }
  }, [onPreviousStep]);

  const handleSectorSelect = (sectorId) => {
    setSector(Number(sectorId));
    setSubsector('');
  };

  useEffect(() => {
    if (sector) {
      fetch(`https://api.finanbid.com/api/products?sector_id=${sector}`)
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
        })
        .catch((error) => {
          console.error('Error al obtener los productos:', error);
          setErrorMessage(t('projectUpload.errors.fetchProducts'));
        });

      fetch(`https://api.finanbid.com/api/subsectorsbysector?sector_id=${sector}`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setSubsectors(data);
          } else {
            console.error('Formato de subsectores no válido:', data);
          }
        })
        .catch((error) => {
          console.error('Error al obtener los subsectores:', error);
          setErrorMessage(t('projectUpload.errors.fetchSubsectors'));
        });
    } else {
      setProducts([]);
      setSubsectors([]);
    }
  }, [sector, t]);

  const handleNext = async () => {
    const token = localStorage.getItem('token');
    const projectData = {
      token: token,
      sector: sector,
      subsector: subsector,
      product_type: productType,
      status: 'incompleto',
      amount_requested: amountRequested,
      provincia: province || undefined,
      localidad: city,
      resumen: description,
      pais: 'España',
      user_id: userId || undefined,
      legal_entity_id: legalEntityId || undefined,
      comId: comId || userId, // Usar comId si existe, de lo contrario igualar al userId
    };

    try {
      const response = await fetch('https://api.finanbid.com/api/create-basic-project', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      });

      const data = await response.json();

      if (data.project_id) {
        saveProjectId(data.project_id);
        onNextStep();
      } else {
        setErrorMessage(data.message || t('projectUpload.errors.creationError'));
        console.error('Error al crear el proyecto:', data.message);
      }
    } catch (error) {
      setErrorMessage(t('projectUpload.errors.apiError'));
      console.error('Error en la llamada a la API:', error);
    }
  };

  return (
    <div className="text-white flex flex-col md:flex-row justify-between bg-back p-2">
      <div className="hidden md:block md:w-1/2 pr-8">
        <h3 className="text-3xl font-semibold mb-4">{t('projectUpload.step2.title')}</h3>
        <img src="/rb_4383.png" alt="Explicación" className="mb-4" />
        <p>{t('projectUpload.step2.explanation')}</p>
      </div>

      <div className="md:w-1/2 w-full">
        <h3 className="text-2xl font-bold mb-4">{t('projectUpload.step2.selectSectorTitle')}</h3>

        {errorMessage && <p className="text-red-500">{errorMessage}</p>}

        <form>
          <div className="flex flex-wrap justify-between mb-6 space-x-2 md:space-x-4">
            <button
              type="button"
              onClick={() => handleSectorSelect(1)}
              className={`w-1/5 p-3 border border-secondary rounded-lg flex flex-col items-center ${sector === 1 ? 'bg-secondary text-white' : 'bg-transparent text-secondary'}`}
            >
              <HomeIcon className="h-8 w-8 mb-2" />
              {t('projectUpload.sectors.1')}
            </button>

            <button
              type="button"
              onClick={() => handleSectorSelect(2)}
              className={`w-1/5 p-3 border border-secondary rounded-lg flex flex-col items-center ${sector === 2 ? 'bg-secondary text-white' : 'bg-transparent text-secondary'}`}
            >
              <OfficeBuildingIcon className="h-8 w-8 mb-2" />
              {t('projectUpload.sectors.2')}
            </button>

            <button
              type="button"
              onClick={() => handleSectorSelect(3)}
              className={`w-1/5 p-3 border border-secondary rounded-lg flex flex-col items-center ${sector === 3 ? 'bg-secondary text-white' : 'bg-transparent text-secondary'}`}
            >
              <BriefcaseIcon className="h-8 w-8 mb-2" />
              {t('projectUpload.sectors.3')}
            </button>

            <button
              type="button"
              onClick={() => handleSectorSelect(4)}
              className={`w-1/5 p-3 border border-secondary rounded-lg flex flex-col items-center ${sector === 4 ? 'bg-secondary text-white' : 'bg-transparent text-secondary'}`}
            >
              <LightBulbIcon className="h-8 w-8 mb-2" />
              {t('projectUpload.sectors.4')}
            </button>
          </div>

          {sector && (
            <div className="mb-4">
              <label className="block text-sm font-medium">{t('projectUpload.step2.subsector')}</label>
              <select
                value={subsector}
                onChange={(e) => setSubsector(e.target.value)}
                className="w-full px-4 py-2 border rounded-md bg-back text-white hover:border-secondary"
              >
                <option value="">{t('projectUpload.step2.selectSubsector')}</option>
                {subsectors.map((sub) => (
                  <option key={sub.id} value={sub.id} className="text-white bg-back">
                    {sub.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {sector && (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium">{t('projectUpload.step2.productType')}</label>
                <select
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  className="w-full px-4 py-2 border rounded-md bg-back text-white hover:border-secondary"
                >
                  <option value="">{t('projectUpload.step2.selectProductType')}</option>
                  {Array.isArray(products) &&
                    products.map((product) => (
                      <option key={product.id} value={product.id} className="text-white bg-back">
                        {t(`projectUpload.products.${product.id}`)}
                      </option>
                    ))}
                </select>
              </div>
                          
                       

              <div className="mb-4 flex space-x-4">
                <div className="w-1/2">
                  <label className="block text-sm font-medium">{t('projectUpload.step2.city')}</label>
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                    className="w-full px-4 py-2 border rounded-md text-black"
                    placeholder={t('projectUpload.step2.enterCity')}
                  />
                </div>
                <div className="w-1/2">
                  <label className="block text-sm font-medium">{t('projectUpload.step2.province')}</label>
                  <select
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md bg-back text-white hover:bg-bg-secondary"
                    required
                  >
                    <option value="">{t('projectUpload.step2.selectProvince')}</option>
                    {provinces.map((prov, index) => (
                      <option key={index} value={prov} className="text-white bg-back">
                        {prov}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium">{t('projectUpload.step2.description')}</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-md text-black"
                  rows="4"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium">{t('projectUpload.step2.amountRequested')}</label>
                <input
                  type="number"
                  value={amountRequested}
                  onChange={(e) => setAmountRequested(e.target.value)}
                  required
                  className="w-full px-4 py-2 border rounded-md text-black"
                />
              </div>

              <button
                type="button"
                className="w-full bg-primary text-white py-2 rounded-md hover:bg-opacity-90"
                onClick={handleNext}
              >
                {t('projectUpload.step2.nextStep')}
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Step2ProjectDetails;


