import React, { useEffect, useState } from 'react';
import { refreshUserToken } from './utils/auth.js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import Header from './components/Header';
import CTA from './components/CTA';
import ScrollToTop from './components/ScrollToTop';
import Hero from './components/Hero';
import Bidfunding from './components/Bidfunding';
import Steps from './components/Steps';
import AcercaDe from './components/AcercaDe';
import CallToAction from './components/CallToAction';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Funds from './components/Fondos';
import HeroFunds from './components/HeroFondos';
import Inmobiliario from './components/Inmobiliario';
import Colaboradores from './components/colaboradores.js';
import Newsletter from './components/Newsletter';
import Renovables from './components/Renovables';
import ComoFunciona from './components/ComoFunciona.js';
import HeroComoFunciona from './components/HeaderComoFunciona.js';
import SubirProyecto from './components/SubirProyecto';
import DashboardAdmin from './components/dashadmin/DashboardAdmin';
import DashboardUser from './components/dashuser/DashboardUser';
import DashboardHome from './components/dashuser/DashboardHome';
import DashboardFondos from './components/dashfondo/DashboardFondos';
import UserProjects from './components/dashuser/UserProjects';
import UserProfile from './components/dashuser/UserProfile';
import UserSettings from './components/dashuser/UserSettings';
import PrestamoPuente from './components/prestamoPuente.js';
import PrestamoPromotor from './components/prestamoPromotor';
import PrestamoFlipping from './components/prestamoFlipping';
import ProtectedRoute from './components/ProtectedRoute';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import socket from './utils/socket.js';
import 'cookieconsent/build/cookieconsent.min.css';
import 'cookieconsent';
import Privacidad from './components/Privacidad';
import TerminosYCondiciones from './components/termsFollowups';

function App() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isDashboardRoute = location.pathname.startsWith('/dashboard');
  const isDashboardFundRoute =
    location.pathname.startsWith('/fund') && !location.pathname.startsWith('/funds');

  const [realTimeData, setRealTimeData] = useState(null);
  const isAuthenticated = !!localStorage.getItem('token');

  // 1) Detect subdominio => set idioma i18n
  useEffect(() => {
    const host = window.location.hostname; // ej: "es.finanbid.com"
    if (host.startsWith('es.')) {
      i18n.changeLanguage('es');
    } else if (host.startsWith('en.')) {
      i18n.changeLanguage('en');
    } else if (host.startsWith('pt.')) {
      i18n.changeLanguage('pt');
    } else if (host.startsWith('sv.')) {
      i18n.changeLanguage('sv');
    } else if (host.startsWith('de.')) {
      i18n.changeLanguage('de');
    } else if (host.startsWith('ar.')) {
      i18n.changeLanguage('ar');
    } else {
      // Fallback si es "www.finanbid.com" o "finanbid.com"
      i18n.changeLanguage('es');
    }
  }, []);

  // 2) Cookie Consent
  useEffect(() => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#113238",
          text: "#fff",
        },
        button: {
          background: "#00d0b2",
          text: "#000",
        },
      },
      content: {
        message: "Utilizamos cookies para asegurar la mejor experiencia en nuestro sitio web.",
        dismiss: "Aceptar",
        link: "Saber más",
        href: "/politica-de-cookies",
      },
      position: "bottom-right",
    });
  }, []);

  // 3) Refresh Token
  useEffect(() => {
    const checkAndRefreshToken = async () => {
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        try {
          await refreshUserToken();
        } catch (error) {
          console.error('Error al refrescar el token:', error);
        }
      }
    };
    checkAndRefreshToken();
    const intervalId = setInterval(() => {
      checkAndRefreshToken();
    }, 15 * 60 * 1000); // cada 15 min
    return () => clearInterval(intervalId);
  }, []);

  // 4) WebSocket
  useEffect(() => {
    socket.on('connect', () => {
      console.log('WebSocket conectado:', socket.id);
    });
    socket.on('newMessage', (message) => {
      console.log('Nuevo mensaje recibido:', message);
    });
    return () => {
      socket.off('connect');
      socket.off('newMessage');
    };
  }, []);

  return (
    <div className="App bg-back min-h-screen">
      {/* Header visible solo si no estamos en rutas admin/dashboard */}
      {!isAdminRoute && !isDashboardRoute && !isDashboardFundRoute && <Header />}

      <Routes>
        {/* HOME - / */}
        <Route
          path="/"
          element={
            <>
              <Hero />
              <Bidfunding />
              <Steps />
              <Newsletter />
            </>
          }
        />
        {/* ABOUT - /about */}
        <Route path="/about" element={<AcercaDe />} />

        {/* COMO FUNCIONA - /comofunciona */}
        <Route
          path="/comofunciona"
          element={
            <>
              <HeroComoFunciona />
              <ComoFunciona />
              <CallToAction />
            </>
          }
        />



        {/* CONTACT - /contact */}
        <Route
          path="/contact"
          element={
            <>
              <Helmet>
                <title>Contacto | Finanbid - Solicita Asesoría</title>
                <link rel="canonical" href="https://www.finanbid.com/contact" />
                <meta
                  name="description"
                  content="¿Tienes dudas sobre bidfunding o cómo subir tu proyecto? Contáctanos para recibir asesoría experta en financiación privada."
                />
                <link rel="alternate" href="https://www.finanbid.com/contact" hreflang="x-default" />
                <link rel="alternate" href="https://es.finanbid.com/contact" hreflang="es" />
                <link rel="alternate" href="https://en.finanbid.com/contact" hreflang="en" />
                <link rel="alternate" href="https://pt.finanbid.com/contact" hreflang="pt" />
                <link rel="alternate" href="https://sv.finanbid.com/contact" hreflang="sv" />
                <link rel="alternate" href="https://de.finanbid.com/contact" hreflang="de" />
                <link rel="alternate" href="https://ar.finanbid.com/contact" hreflang="ar" />
              </Helmet>
              <Contacto />
              <CTA />
            </>
          }
        />

        {/* PRIVACIDAD - /privacidad */}
        <Route
          path="/privacidad"
          element={
            <>
              <Helmet>
                <title>Política de Privacidad - Finanbid</title>
                <link rel="canonical" href="https://www.finanbid.com/privacidad" />
                <meta
                  name="description"
                  content="Consulta cómo manejamos tus datos y la seguridad en Finanbid. Transparencia y protección de la información de promotores y fondos."
                />
                <link
                  rel="alternate"
                  href="https://www.finanbid.com/privacidad"
                  hreflang="x-default"
                />
                <link rel="alternate" href="https://es.finanbid.com/privacidad" hreflang="es" />
                <link rel="alternate" href="https://en.finanbid.com/privacidad" hreflang="en" />
                <link rel="alternate" href="https://pt.finanbid.com/privacidad" hreflang="pt" />
                <link rel="alternate" href="https://sv.finanbid.com/privacidad" hreflang="sv" />
                <link rel="alternate" href="https://de.finanbid.com/privacidad" hreflang="de" />
                <link rel="alternate" href="https://ar.finanbid.com/privacidad" hreflang="ar" />
              </Helmet>
              <Privacidad />
            </>
          }
        />

        {/* TERMS - /terms-followups */}
        <Route
          path="/terms-followups"
          element={
            <>
              <Helmet>
                <title>Términos y Condiciones - Finanbid</title>
                <link rel="canonical" href="https://www.finanbid.com/terms-followups" />
                <meta
                  name="description"
                  content="Lee las condiciones para utilizar Finanbid, la plataforma donde promotores y fondos concretan operaciones de financiación."
                />
                <link
                  rel="alternate"
                  href="https://www.finanbid.com/terms-followups"
                  hreflang="x-default"
                />
                <link
                  rel="alternate"
                  href="https://es.finanbid.com/terms-followups"
                  hreflang="es"
                />
                <link
                  rel="alternate"
                  href="https://en.finanbid.com/terms-followups"
                  hreflang="en"
                />
                <link
                  rel="alternate"
                  href="https://pt.finanbid.com/terms-followups"
                  hreflang="pt"
                />
                <link
                  rel="alternate"
                  href="https://sv.finanbid.com/terms-followups"
                  hreflang="sv"
                />
                <link
                  rel="alternate"
                  href="https://de.finanbid.com/terms-followups"
                  hreflang="de"
                />
                <link
                  rel="alternate"
                  href="https://ar.finanbid.com/terms-followups"
                  hreflang="ar"
                />

              </Helmet>
              <TerminosYCondiciones />
            </>
          }
        />

        {/* LOGIN / REGISTER */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* FUNDS - /funds */}
        <Route
          path="/funds"
          element={
            <>
              <HeroFunds />
              <Funds />
              <Newsletter />
            </>
          }
        />


        {/* INMOBILIARIO - /inmobiliario */}
        <Route
          path="/inmobiliario"
          element={<Inmobiliario />}
        />


        {/* PRESTAMO PUENTE - /prestamopuente */}
        <Route
          path="/prestamopuente"
          element={<PrestamoPuente />}
        />


        {/* PRESTAMO PROMOTOR - /prestamopromotor */}
        <Route
          path="/prestamopromotor"
          element={<PrestamoPromotor />}
        />


        {/* PRESTAMO FLIPPING - /prestamoflipping */}
        <Route
          path="/prestamoflipping"
          element={<PrestamoFlipping />}
        />


        {/* RENOVABLES - /renovables */}
        <Route
          path="/renovables"
          element={
            <>
              <Helmet>
                <title>Financiación de Energías Renovables | Finanbid</title>
                <link rel="canonical" href="https://www.finanbid.com/renovables" />
                <meta
                  name="description"
                  content="Impulsa proyectos verdes con fondos que apoyan energías limpias. Sube tu proyecto renovable y recibe ofertas de financiación."
                />
                <meta
                  name="keywords"
                  content="financiación renovables, energías limpias, bonos verdes, capital privado, sostenibilidad"
                />
                <link
                  rel="alternate"
                  href="https://www.finanbid.com/renovables"
                  hreflang="x-default"
                />
                {/* etc. */}
              </Helmet>
              <Renovables />
            </>
          }
        />

        {/* COLABORADORES - /colaboradores */}
        <Route
          path="/colaboradores"
          element={<Colaboradores />}
        />


        {/* SUBIR PROYECTO - /subir-proyecto */}
        <Route
          path="/subir-proyecto"
          element={
            <>
              <Helmet>
                <title>Sube tu Proyecto - Finanbid</title>
                <link rel="canonical" href="https://www.finanbid.com/subir-proyecto" />
                <meta
                  name="description"
                  content="Muestra tu proyecto a fondos de inversión. Sube la documentación y deja que los inversores pujen con sus ofertas."
                />
                <meta
                  name="keywords"
                  content="subir proyecto, promotor, financiación, bidfunding"
                />
                <link
                  rel="alternate"
                  href="https://www.finanbid.com/subir-proyecto"
                  hreflang="x-default"
                />
                {/* etc. */}
              </Helmet>
              <SubirProyecto />
            </>
          }
        />

        {/* Rutas privadas: Admin */}
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <DashboardAdmin />
            </ProtectedRoute>
          }
        />

        {/* Rutas privadas: Fondos */}
        <Route
          path="/fund/dashboard/*"
          element={
            <ProtectedRoute allowedRoles={['fondo', 'admin']}>
              <DashboardFondos />
            </ProtectedRoute>
          }
        />

        {/* Rutas privadas: Usuario */}
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <DashboardUser />
            </ProtectedRoute>
          }
        >
          <Route index element={<DashboardHome />} />
          <Route
            path="projects"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <UserProjects />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <UserSettings />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>

      {/* Footer visible si no es admin/dashboard/fund */}
      {!isAdminRoute && !isDashboardRoute && !isDashboardFundRoute && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default AppWrapper;
