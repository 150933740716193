import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelpWidget from './HelpWidget';
import socket from '../../utils/socket'; // Importamos el socket.io-client
import Inbox from './Inbox';
import DashboardHome from './DashboardHome';
import UserProjects from './UserProjects';
import UserProfile from './UserProfile';
import UserOffers from './Offers';
import Project from './Project';
import ProjectAssistantModal from "./ProjectAssistantModal";
import { faBars, faTimes, faHome, faUsers, faFolder, faChartLine, faMoneyBill, faCog, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const DashboardUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [offersCount, setOffersCount] = useState(0);
  const [inboxCount, setInboxCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Estado para controlar el sidebar
  const [userStatus, setUserStatus] = useState('active'); // Estado inicial para el status del usuario
  const [notifications, setNotifications] = useState([]); // Estado para las notificaciones
  const [showPendingPopup, setShowPendingPopup] = useState(false);
  const [dashboardStats, setDashboardStats] = useState({
    project_count: 0,
    inbox_count: 0,
    offer_count: 0,
    user_name: 'Usuario',
  });
  const [isCollaborator, setIsCollaborator] = useState(false);
  const [collaboratorCode, setCollaboratorCode] = useState(null);
  const [latestUpdate, setLatestUpdate] = useState(null);
  const userId = parseInt(localStorage.getItem('userId'), 10);
  const role = localStorage.getItem('role'); // 'promotor' en este caso
  const fetchLatestUpdate = async () => {
    try {
      const response = await fetch('https://api.finanbid.com/api/system-updates');
      if (!response.ok) {
        throw new Error('Failed to fetch latest update');
      }
      const data = await response.json();
      setLatestUpdate(data);
    } catch (error) {
      console.error('Error fetching latest update:', error);
    }
  };
// ------------------------------------------
  // Obtener la cantidad inicial de mensajes no leídos
  // ------------------------------------------
  useEffect(() => {
    const fetchInboxCount = async () => {
      try {
        if (!userId || !role) return;

        const response = await fetch(`https://api.finanbid.com/api/messages/unread-count?userId=${userId}&role=${role}`);
        if (!response.ok) throw new Error('Error al obtener la cantidad de mensajes');

        const data = await response.json();
        setInboxCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de mensajes:', error);
      }
    };

    fetchInboxCount();

    // Registrar usuario en WebSocket
    socket.emit('registerUser', userId);
  }, [userId, role]);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No autorizado');
        }

        const response = await fetch('https://api.finanbid.com/api/user/status', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error al verificar el estado del usuario');
        }

        const data = await response.json();
        setUserStatus(data.status);

        if (data.status !== 'active') {
          setShowPendingPopup(true);
        }
      } catch (error) {
        console.error('Error al obtener el estado del usuario:', error);
        navigate('/'); // Redirigir al login si no está autorizado
      }
    };

    const fetchInboxCount = async () => {
      try {
        if (!userId || !role) return;

        const response = await fetch(`https://api.finanbid.com/api/messages/unread-count?userId=${userId}&role=${role}`);
        if (!response.ok) throw new Error('Error al obtener la cantidad de mensajes');

        const data = await response.json();
        setInboxCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de mensajes:', error);
      }
    };

    const fetchDashboardStats = async () => {
      try {
        const userId = parseInt(localStorage.getItem('userId'), 10);
        if (!userId) {
          console.error('No se encontró userId en localStorage.');
          return;
        }

        const response = await fetch(`https://api.finanbid.com/api/dashboard/stats/${userId}`);
        const data = await response.json();
        setDashboardStats(data);
      } catch (error) {
        console.error('Error al obtener estadísticas del dashboard:', error);
      }
    };

    const fetchCollaboratorInfo = async () => {
      try {
        const userId = parseInt(localStorage.getItem('userId'), 10);
        const response = await fetch(`https://api.finanbid.com/api/collaborators/${userId}`);
        if (response.ok) {
          const data = await response.json();
          setIsCollaborator(true);
          setCollaboratorCode(data.code);
        } else if (response.status === 404) {
          setIsCollaborator(false);
        } else {
          throw new Error('Error al obtener información del colaborador.');
        }
      } catch (error) {
        console.error('Error al obtener información del colaborador:', error);
      }
    };

    fetchUserStatus();
    fetchInboxCount();
    fetchDashboardStats();
    fetchCollaboratorInfo();
    fetchLatestUpdate(); // Llamada para obtener la última actualización
  }, [navigate]);

  

  const handleProjectsClick = () => {
    if (location.pathname === '/dashboard/projects') {
      navigate('/dashboard');
      setTimeout(() => navigate('/dashboard/projects'), 0);
    } else {
      navigate('/dashboard/projects');
    }
    setIsSidebarOpen(false); // Cierra el sidebar al hacer clic en una opción
  };

  
// ------------------------------------------
  // Escuchar mensajes nuevos en tiempo real
  // ------------------------------------------
  useEffect(() => {
    const handleNewMessage = (newMsg) => {
      console.log('Nuevo mensaje recibido:', newMsg);

      // Solo mostrar la notificación si el mensaje es para este usuario (promotor)
      if (newMsg.recipientId === userId && newMsg.recipientRole === role) {
        // Incrementar contador de mensajes no leídos
        setInboxCount(prev => prev + 1);

        // Mostrar notificación
        setNotifications(prev => [
          ...prev,
          { id: Date.now(), message: newMsg.message, conversationId: newMsg.conversation_id },
        ]);

        // Eliminar la notificación después de 5 segundos
        setTimeout(() => {
          setNotifications(prev => prev.filter(n => n.conversationId !== newMsg.conversation_id));
        }, 5000);
      }
    };

    socket.on('newMessageGlobal', handleNewMessage);

    return () => {
      socket.off('newMessageGlobal', handleNewMessage);
    };
  }, [userId, role]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="h-screen flex flex-col md:flex-row">
      <HelpWidget />
      {showPendingPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold mb-4">{t('dashboard.pendingTitle', 'Cuenta pendiente de aprobación')}</h2>
            <p className="text-gray-700 mb-6">{t('dashboard.pendingMessage', 'Tu cuenta todavía está pendiente de aprobación. Por favor, espera la validación.')}</p>
            <button
              onClick={handleLogout}
              className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              {t('dashboard.logout', 'Cerrar sesión')}
            </button>
          </div>
        </div>
      )}
      {/* Header */}
      <header className="bg-gray-800 text-white flex justify-between items-center p-4 md:hidden">
        <img className="h-8 w-auto" src="/logofinanbid.png" alt="User Dashboard" />
        <button onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="text-white focus:outline-none">
          <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="lg" />
        </button>
      </header>

      {/* Sidebar */}
      <nav className={`bg-gray-800 w-64 h-full flex flex-col justify-between fixed top-0 md:relative z-10 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-200 ease-in-out`}>
        <div>
          <div className="h-16 bg-gray-900 flex items-center justify-center">
            <img className="h-12 w-auto hidden md:block" src="/logofinanbid.png" alt="User Dashboard" />
          </div>
          <div className="flex-1 mt-8 space-y-2">
            <Link to="/dashboard" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faHome} className="mr-2" /> {t('dashboard.home')}
            </Link>
            <button
              onClick={handleProjectsClick}
              className="block text-gray-400 hover:text-white px-4 py-2 text-left w-full"
            >
              <FontAwesomeIcon icon={faFolder} className="mr-2" /> {t('dashboard.myProjects')}
            </button>
            <Link to="/dashboard/offers" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faMoneyBill} className="mr-2" /> {offersCount > 0 ? `${t('dashboard.offers')} (${offersCount})` : t('dashboard.offers')}
            </Link>
            <Link
              to="/dashboard/inbox"
              className={`block px-4 py-2 ${inboxCount > 0 ? 'text-secondary font-bold' : 'text-gray-400 hover:text-white'}`}
              onClick={() => setIsSidebarOpen(false)}
            >
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" /> {inboxCount > 0 ? `${t('dashboard.inbox')} (${inboxCount})` : t('dashboard.inbox')}
            </Link>
          </div>
        </div>
        <div className="mb-4 space-y-2">
          <Link to="/dashboard/profile" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
            <FontAwesomeIcon icon={faCog} className="mr-2" /> {t('dashboard.profile')}
          </Link>
          <button onClick={handleLogout} className="block text-gray-400 hover:text-white px-4 py-2 w-full text-left">
            {t('dashboard.logout')}
          </button>
        </div>
      </nav>

      {/* Contenido Principal */}
      <main className="flex-1 h-screen overflow-y-auto p-6 bg-gray-100">
        {/* Notificaciones visuales */}
        <div className="fixed bottom-4 right-4 space-y-2 z-50">
          {notifications.map((notification) => (
            <div key={notification.id} className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 transition-opacity duration-300 ease-in-out opacity-100">
              <p className="text-sm">{notification.message}</p>
              <button
                onClick={() => {
                  navigate('/dashboard/inbox');
                  setNotifications((prev) => prev.filter((n) => n.id !== notification.id));
                }}
                className="text-blue-500 text-xs mt-2 hover:underline"
              >
                Ver conversación
              </button>
            </div>
          ))}
        </div>
      <Routes>
          <Route path="/" element={<DashboardHome stats={dashboardStats} isCollaborator={isCollaborator} collaboratorCode={collaboratorCode} latestUpdate={latestUpdate} />} />
          <Route path="/projects" element={<UserProjects />} />
          <Route path="/projects/:projectId" element={<Project />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/offers" element={<UserOffers />} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardUser;

