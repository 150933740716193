import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const PrestamoPromotor = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLang = i18n.language.toLowerCase(); // "es", "en", etc.

  let metaTitle = '';
  let metaDescription = '';
  let metaKeywords = '';
  let ogTitle = '';
  let ogDescription = '';
  let faqJsonLd = '';

  // SEO condicional según idioma
  if (currentLang === 'es') {
    metaTitle = 'Préstamo Promotor - Impulsa tu Proyecto Inmobiliario | Finanbid';
    metaDescription =
      'Consigue financiación para proyectos de desarrollo inmobiliario. Fondos compiten por tu proyecto, ofreciéndote condiciones más atractivas que la banca tradicional.';
    metaKeywords =
      'préstamo promotor, desarrollo inmobiliario, fondos de inversión, capital privado, obra nueva, refinanciación';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "¿Para qué tipo de desarrollos se ofrece un Préstamo Promotor?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Proyectos de construcción y desarrollo inmobiliario, ya sea residencial, comercial u hotelero. El fondo analiza la viabilidad y la garantía del proyecto."
            }
          },
          {
            "@type": "Question",
            "name": "¿Qué ventajas ofrece Finanbid al promotor?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "En lugar de una sola entidad, múltiples fondos compiten para ofrecerte mejores tipos de interés y flexibilidad. Rapidez y transparencia en el proceso."
            }
          },
          {
            "@type": "Question",
            "name": "¿Cómo se formaliza el préstamo?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Tras recibir ofertas, eliges la más adecuada y firmas las condiciones. A partir de ahí, se avanza con la formalización legal y el desembolso."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'en') {
    metaTitle = 'Promoter Loan - Boost Your Real Estate Project | Finanbid';
    metaDescription =
      'Obtain financing for real estate development. Investment funds compete for your project, offering more attractive terms than traditional banks.';
    metaKeywords =
      'promoter loan, real estate development, investment funds, private capital, new construction, refinancing';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What types of developments qualify for a promoter loan?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "From residential to commercial or hotel developments, the fund reviews project viability and collateral before offering financing."
            }
          },
          {
            "@type": "Question",
            "name": "Why choose Finanbid?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Multiple funds compete to provide better rates and flexible conditions, speeding up your access to private capital."
            }
          },
          {
            "@type": "Question",
            "name": "How is the loan formalized?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "After receiving offers, you pick the best one and sign the loan terms. The next step is the legal formalization and disbursement."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'pt') {
    metaTitle = 'Empréstimo Promotor - Impulsione seu Projeto Imobiliário | Finanbid';
    metaDescription =
      'Obtenha financiamento para desenvolvimento imobiliário. Fundos competem pelo seu projeto, oferecendo condições mais vantajosas que bancos tradicionais.';
    metaKeywords =
      'empréstimo promotor, desenvolvimento imobiliário, fundos de investimento, capital privado, construção, refinanciamento';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'sv') {
    metaTitle = 'Promotorlån - Driv ditt fastighetsprojekt | Finanbid';
    metaDescription =
      'Säkra finansiering för fastighetsutveckling. Investeringsfonder tävlar om ditt projekt med bättre villkor än traditionella banker.';
    metaKeywords =
      'promotorlån, fastighetsutveckling, investeringsfonder, privat kapital, nybyggnation, refinansiering';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'de') {
    metaTitle = 'Promotor-Darlehen - Fördern Sie Ihr Immobilienprojekt | Finanbid';
    metaDescription =
      'Holen Sie sich Finanzierung für Immobilienentwicklungen. Investmentfonds konkurrieren um Ihr Projekt und bieten attraktivere Konditionen als Banken.';
    metaKeywords =
      'promotor-darlehen, immobilienentwicklung, investmentfonds, privates kapital, neubau, refinanzierung';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'ar') {
    metaTitle = 'قرض المطور - عزز مشروعك العقاري | Finanbid';
    metaDescription =
      'احصل على تمويل لتطوير مشاريعك العقارية. تتنافس صناديق الاستثمار على مشروعك لتقديم شروط أكثر جاذبية من البنوك التقليدية.';
    metaKeywords =
      'قرض المطور، تطوير عقاري، صناديق الاستثمار، رأس المال الخاص، بناء جديد، إعادة التمويل';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else {
    // Fallback (es)
    metaTitle = 'Préstamo Promotor - Impulsa tu Proyecto Inmobiliario | Finanbid';
    metaDescription =
      'Consigue financiación para proyectos de desarrollo inmobiliario. Fondos compiten por tu proyecto, ofreciéndote condiciones más atractivas que la banca tradicional.';
    metaKeywords =
      'préstamo promotor, desarrollo inmobiliario, fondos de inversión, capital privado, obra nueva, refinanciación';
    ogTitle = metaTitle;
    ogDescription = metaDescription;
    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  }

  const canonicalUrl = "https://www.finanbid.com/prestamopromotor";

  return (
    <div className="bg-back text-white lg:mt-10 mt-24">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        <link rel="canonical" href={canonicalUrl} />

        {/* Hreflang alternates */}
        <link rel="alternate" href="https://www.finanbid.com/prestamopromotor" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/prestamopromotor" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/prestamopromotor" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/prestamopromotor" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/prestamopromotor" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/prestamopromotor" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/prestamopromotor" hreflang="ar" />

        {/* Open Graph */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@finanbid" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* JSON-LD FAQ */}
        <script type="application/ld+json">
          {faqJsonLd}
        </script>
      </Helmet>

      {/* Hero Section */}
      <section className="bg-back dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:pt-28">
          <div className="place-self-center">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              {t('prestamoPromotor.hero.title')}
            </h1>
            <p className="max-w-2xl mb-6 font-light text-secondary lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('prestamoPromotor.hero.subtitle')}
            </p>
            <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('prestamoPromotor.hero.description')}
            </p>
            <button
              onClick={() => navigate('/subir-proyecto')}
              className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
            >
              {t('prestamoPromotor.hero.button')}
            </button>
          </div>
        </div>
      </section>

      {/* Principales Usos del Préstamo Promotor */}
      <section className="px-4 py-16 mx-auto sm:max-w-xl bg-gray-200 mb-12 md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 rounded-xl shadow-lg transform transition-transform hover:translate-y-[-10px]">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-sky-500 text-white">
              {t('prestamoPromotor.uses.tag')}
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-back sm:text-4xl md:mx-auto">
            {t('prestamoPromotor.uses.title')}
          </h2>
          <p className="text-base text-secondary md:text-lg">
            {t('prestamoPromotor.uses.subtitle')}
          </p>
        </div>

        <div className="grid max-w-md gap-8 row-gap-10 sm:mx-auto lg:max-w-full lg:grid-cols-3">
          {/* Section for construction of new developments */}
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoPromotor.uses.construction.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPromotor.uses.construction.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPromotor.uses.construction.description2')}
              </p>
            </div>
          </div>
          {/* Section for rehabilitation projects */}
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoPromotor.uses.rehabilitation.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPromotor.uses.rehabilitation.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPromotor.uses.rehabilitation.description2')}
              </p>
            </div>
          </div>
          {/* Section for commercial projects */}
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-4">
              <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-green-50">
                <svg className="w-12 h-12 text-green-400" stroke="currentColor" viewBox="0 0 52 52">
                  <polygon strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                </svg>
              </div>
            </div>
            <div>
              <h6 className="mb-2 text-secondary font-semibold leading-5">
                {t('prestamoPromotor.uses.commercial.title')}
              </h6>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPromotor.uses.commercial.description1')}
              </p>
              <p className="mb-3 text-sm text-gray-900">
                {t('prestamoPromotor.uses.commercial.description2')}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Características del Préstamo Promotor */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('prestamoPromotor.features.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {['feature1', 'feature2', 'feature3'].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800 rounded-lg text-center">
                <h3 className="text-xl font-semibold">
                  {t(`prestamoPromotor.features.${feature}.title`)}
                </h3>
                <p className="mt-4 text-gray-300">
                  {t(`prestamoPromotor.features.${feature}.description`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="py-16 bg-back text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">
            {t('promotor.cta.title')}
          </h2>
          <p className="text-lg mb-8">
            {t('promotor.cta.description')}
          </p>
          <button
            onClick={() => navigate('/subir-proyecto')}
            className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
          >
            {t('promotor.cta.button')}
          </button>
        </div>
      </section>
    </div>
  );
};

export default PrestamoPromotor;
