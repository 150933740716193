import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProjectAdminSettings = ({ projectData, onUpdateField, onDeleteProject }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [subsectors, setSubsectors] = useState([]);
    const [formData, setFormData] = useState({ ...projectData });
    const [loadingSubsectors, setLoadingSubsectors] = useState(false);
    const navigate = useNavigate();

    const token = localStorage.getItem("token");

    // Fetch products and sectors
    useEffect(() => {
        fetchData("https://api.finanbid.com/api/admin/products", setProducts);
        fetchData("https://api.finanbid.com/api/sectors", setSectors);

        if (formData.sector_id) fetchSubsectors(formData.sector_id);
    }, [formData.sector_id]);

    const fetchData = async (url, setter) => {
        try {
            const response = await fetch(url, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            setter(data);
        } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
        }
    };

    const fetchSubsectors = async (sectorId) => {
        setLoadingSubsectors(true);
        try {
            const response = await fetch(`https://api.finanbid.com/api/subsectors?sector_id=${sectorId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const data = await response.json();
            setSubsectors(data);
        } catch (error) {
            console.error("Error fetching subsectors:", error);
        } finally {
            setLoadingSubsectors(false);
        }
    };

    const handleChange = (field, value) => {
        setFormData((prev) => ({ ...prev, [field]: value }));
        if (field === "sector_id") {
            setFormData((prev) => ({ ...prev, subsector_id: "" }));
            fetchSubsectors(value);
        }
    };

    const handleBlur = async (field, value) => {
        if (onUpdateField) {
            await onUpdateField(field, value);
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm(t("projectAdmin.confirmDelete"));
        if (confirmDelete && onDeleteProject) {
            await onDeleteProject(projectData.id);
            navigate("/admin/projects"); // Redirigir después de eliminar
        }
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow space-y-4">
            <h2 className="text-lg font-bold text-gray-800">{t("projectAdmin.settings")}</h2>

            {/* Product Type */}
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-600">{t("projectAdmin.product")}</label>
                    <select
                        value={formData.product_type || ""}
                        onChange={(e) => handleChange("product_type", e.target.value)}
                        onBlur={() => handleBlur("product_type", formData.product_type)}
                        className="w-full p-2 border rounded-md"
                    >
                        <option value="">{t("projectAdmin.selectProduct")}</option>
                        {products.map((product) => (
                            <option key={product.id} value={product.id}>
                                {product.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Status */}
                <div>
                    <label className="block text-sm font-medium text-gray-600">{t("projectAdmin.status")}</label>
                    <select
                        value={formData.status || ""}
                        onChange={(e) => handleChange("status", e.target.value)}
                        onBlur={() => handleBlur("status", formData.status)}
                        className={`w-full p-2 border rounded-md ${
                            formData.status === "Visible"
                                ? "text-green-600"
                                : formData.status === "Cancelado"
                                ? "text-red-600"
                                : "text-gray-800"
                        }`}
                    >
                        {["Incompleto", "En Revision", "En Modificación", "Visible", "Finalizado", "Cancelado"].map((status) => (
                            <option key={status} value={status}>
                                {t(`projectAdmin.statuses.${status}`)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Sector and Subsector */}
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-600">{t("projectAdmin.sector")}</label>
                    <select
                        value={formData.sector_id || ""}
                        onChange={(e) => handleChange("sector_id", e.target.value)}
                        onBlur={() => handleBlur("sector_id", formData.sector_id)}
                        className="w-full p-2 border rounded-md"
                    >
                        <option value="">{t("projectAdmin.selectSector")}</option>
                        {sectors.map((sector) => (
                            <option key={sector.id} value={sector.id}>
                                {sector.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-600">{t("projectAdmin.subsector")}</label>
                    <select
                        value={formData.subsector_id || ""}
                        onChange={(e) => handleChange("subsector_id", e.target.value)}
                        onBlur={() => handleBlur("subsector_id", formData.subsector_id)}
                        className="w-full p-2 border rounded-md"
                        disabled={!formData.sector_id || loadingSubsectors}
                    >
                        <option value="">{loadingSubsectors ? t("loading") : t("projectAdmin.selectSubsector")}</option>
                        {subsectors.map((subsector) => (
                            <option key={subsector.id} value={subsector.id}>
                                {subsector.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Eliminar Proyecto */}
            <div className="flex justify-end">
                <button
                    onClick={handleDelete}
                    className="mt-4 bg-red-600 text-white px-6 py-2 rounded-md hover:bg-red-700"
                >
                    {t("projectAdmin.deleteProject")}
                </button>
            </div>
        </div>
    );
};

export default ProjectAdminSettings;


