import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';


const HeroFondos = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.toLowerCase(); // "es", "en", ...

  let metaTitle = '';
  let metaDescription = '';
  let metaKeywords = '';
  let ogTitle = '';
  let ogDescription = '';
  let faqJsonLd = '';

  // Lógica SEO según el idioma
  if (currentLang === 'es') {
    metaTitle = 'Fondos e Inversores en Finanbid: Oportunidades de Inversión';
    metaDescription =
      'Descubre proyectos verificados en Finanbid buscando financiación. Conecta como fondo, realiza ofertas competitivas y financia operaciones con alta rentabilidad.';
    metaKeywords =
      'fondos de inversión, family offices, financiación privada, oportunidad de inversión, promotor, proyectos verificados, alta rentabilidad';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "¿Cómo se benefician los fondos al usar Finanbid?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Los fondos obtienen acceso a proyectos cuidadosamente verificados, con una plataforma que garantiza competitividad y transparencia en la oferta."
            }
          },
          {
            "@type": "Question",
            "name": "¿Qué tipo de proyectos se pueden financiar?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Desde proyectos inmobiliarios (compra de suelo, promoción, flipping) hasta otros sectores. Los promotores suben datos y documentos, y el fondo analiza la viabilidad."
            }
          },
          {
            "@type": "Question",
            "name": "¿Cómo presentar ofertas?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Una vez dentro de la plataforma, revisas la información y subes tus términos. El promotor evalúa la propuesta y decide con qué fondo continuar."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'en') {
    metaTitle = 'Funds and Investors at Finanbid: Investment Opportunities';
    metaDescription =
      'Discover verified projects at Finanbid seeking financing. Connect as a fund, make competitive offers, and finance operations with strong returns.';
    metaKeywords =
      'investment funds, family offices, private financing, investment opportunity, promoter, verified projects, high returns';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "How do funds benefit from using Finanbid?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Funds get access to carefully verified projects, with a platform ensuring competitiveness and transparency in bidding."
            }
          },
          {
            "@type": "Question",
            "name": "What kind of projects can be financed?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Primarily real estate (land purchase, development, flipping) and other business sectors. Promoters upload data and documents, and the fund analyzes viability."
            }
          },
          {
            "@type": "Question",
            "name": "How do I make offers?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Once inside the platform, you review the information and submit your terms. The promoter then evaluates the proposals and selects a fund."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'pt') {
    metaTitle = 'Fundos e Investidores na Finanbid: Oportunidades de Investimento';
    metaDescription =
      'Descubra projetos verificados na Finanbid que buscam financiamento. Conecte-se como fundo, faça propostas competitivas e financie operações de alta rentabilidade.';
    metaKeywords =
      'fundos de investimento, family offices, financiamento privado, oportunidade de investimento, promotor, projetos verificados';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      }
    `;
  } else if (currentLang === 'sv') {
    metaTitle = 'Fonder och Investerare på Finanbid: Investeringsmöjligheter';
    metaDescription =
      'Upptäck verifierade projekt på Finanbid som söker finansiering. Anslut som fond, gör konkurrenskraftiga erbjudanden och finansiera projekt med god avkastning.';
    metaKeywords =
      'investeringsfonder, family offices, privat finansiering, investeringsmöjlighet, promotor, verifierade projekt';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      }
    `;
  } else if (currentLang === 'de') {
    metaTitle = 'Fonds und Investoren bei Finanbid: Investitionsmöglichkeiten';
    metaDescription =
      'Entdecken Sie geprüfte Projekte bei Finanbid, die Finanzierung suchen. Treten Sie als Fonds bei, geben Sie wettbewerbsfähige Angebote ab und finanzieren Sie Vorhaben mit hoher Rendite.';
    metaKeywords =
      'Investmentfonds, Family Offices, private Finanzierung, Investitionschance, Promoter, geprüfte Projekte';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      }
    `;
  } else if (currentLang === 'ar') {
    metaTitle = 'الصناديق والمستثمرون في Finanbid: فرص استثمارية';
    metaDescription =
      'اكتشف المشاريع التي تم التحقق منها في Finanbid والتي تبحث عن التمويل. انضم كصندوق، وقدم عروضًا تنافسية، ومول العمليات بعوائد كبيرة.';
    metaKeywords =
      'صناديق الاستثمار، المكاتب العائلية، التمويل الخاص، فرصة استثمارية، المروج، مشاريع موثوقة';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      }
    `;
  } else {
    // Fallback: es
    metaTitle = 'Fondos e Inversores en Finanbid: Oportunidades de Inversión';
    metaDescription =
      'Descubre proyectos verificados que necesitan financiación. Únete a Finanbid y envía ofertas competitivas a promotores.';
    metaKeywords =
      'fondos de inversión, family offices, financiación privada, oportunidad de inversión, promotor, proyectos verificados';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      }
    `;
  }

  // Canonical + alternates
  const canonicalUrl = "https://www.finanbid.com/funds";

  return (
    <section
      className="bg-white"
      style={{
        backgroundImage: 'url("/fondo.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      {/* SEO con Helmet */}
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        {/* Canonical */}
        <link rel="canonical" href={canonicalUrl} />

        {/* Hreflang alternates */}
        <link rel="alternate" href="https://www.finanbid.com/funds" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/funds" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/funds" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/funds" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/funds" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/funds" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/funds" hreflang="ar" />

        {/* Open Graph */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@finanbid" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* JSON-LD FAQ */}
        <script type="application/ld+json">
          {faqJsonLd}
        </script>
      </Helmet>

      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle
              cx="512"
              cy="512"
              r="512"
              fill="url(#gradientHeroFondos)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="gradientHeroFondos">
                <stop stopColor="#3B82F6" />
                <stop offset="1" stopColor="#10B981" />
              </radialGradient>
            </defs>
          </svg>

          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              {t('heroFondos.title')}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              {t('heroFondos.description1')}
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              {t('heroFondos.description2')}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              
              <a href="#" className="text-sm font-semibold leading-6 text-white">
                {t('heroFondos.moreInfoButton')} <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>

          <div className="relative mt-16 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src="/fondos1.png"
              alt={t('heroFondos.imageAlt')}
              width="1824"
              height="1080"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroFondos;
