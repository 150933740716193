import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const Inmobiliaria = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.toLowerCase();
  const navigate = useNavigate();

  let metaTitle = '';
  let metaDescription = '';
  let metaKeywords = '';
  let ogTitle = '';
  let ogDescription = '';
  let faqJsonLd = '';

  // Lógica multiidioma
  if (currentLang === 'es') {
    metaTitle = 'Financiación Inmobiliaria - Finanbid';
    metaDescription =
      'Compra de suelo, promoción, construcción y flipping: En Finanbid los fondos pujan para ofrecer las mejores condiciones a tus proyectos inmobiliarios.';
    metaKeywords =
      'financiación inmobiliaria, compra de suelo, promoción, construcción, flipping, préstamo promotor, capital privado';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "¿Qué tipo de proyectos inmobiliarios se pueden financiar?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Finanbid conecta proyectos de compra de suelo finalista, promociones en construcción, rehabilitaciones y flipping, con fondos de inversión especializados."
            }
          },
          {
            "@type": "Question",
            "name": "¿Cuál es la ventaja de la puja de fondos?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Los fondos compiten entre sí para ofrecer tasas y condiciones más atractivas que en la banca tradicional, acelerando la obtención de capital."
            }
          },
          {
            "@type": "Question",
            "name": "¿Cuánto tardan los fondos en hacer una oferta?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Una vez subes tu documentación y tu proyecto es visible, los fondos interesados suelen presentar ofertas en cuestión de días o semanas."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'en') {
    metaTitle = 'Real Estate Financing - Finanbid';
    metaDescription =
      'Land purchase, development, construction, and flipping: At Finanbid, investment funds bid to offer the best terms for your real estate projects.';
    metaKeywords =
      'real estate financing, land purchase, development, construction, flipping, promotor loan, private capital';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What type of real estate projects can be financed?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Finanbid connects projects such as land purchase, development, construction, and flipping with specialized investment funds."
            }
          },
          {
            "@type": "Question",
            "name": "Why do funds bid for my project?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Funds compete to offer better rates and terms than traditional banks, expediting your capital acquisition."
            }
          },
          {
            "@type": "Question",
            "name": "How quickly do funds present offers?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Once you upload all relevant data, interested funds typically respond with offers within days or weeks."
            }
          }
        ]
      }
    `;
  } else if (currentLang === 'pt') {
    metaTitle = 'Financiamento Imobiliário - Finanbid';
    metaDescription =
      'Compra de terreno, promoção, construção e flipping: Na Finanbid, fundos de investimento competem para oferecer as melhores condições.';
    metaKeywords =
      'financiamento imobiliário, compra de terreno, promoção, construção, flipping, empréstimo promotor, capital privado';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'sv') {
    metaTitle = 'Fastighetsfinansiering - Finanbid';
    metaDescription =
      'Markköp, projektutveckling, byggnation och flipping: På Finanbid tävlar investeringsfonder om att erbjuda de bästa villkoren.';
    metaKeywords =
      'fastighetsfinansiering, markköp, fastighetsutveckling, byggnation, flipping, privat kapital';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'de') {
    metaTitle = 'Immobilienfinanzierung - Finanbid';
    metaDescription =
      'Grundstückskauf, Projektentwicklung, Bau und Flipping: Bei Finanbid konkurrieren Investmentfonds, um die besten Konditionen anzubieten.';
    metaKeywords =
      'Immobilienfinanzierung, Grundstückskauf, Projektentwicklung, Bau, flipping, privates Kapital';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else if (currentLang === 'ar') {
    metaTitle = 'التمويل العقاري - Finanbid';
    metaDescription =
      'شراء الأراضي، التطوير، البناء وتقنية flipping: في Finanbid، تتنافس صناديق الاستثمار لتقديم أفضل الشروط لمشاريعك العقارية.';
    metaKeywords =
      'التمويل العقاري، شراء الأراضي، التطوير، البناء، flipping، رأس المال الخاص';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  } else {
    // Fallback (es)
    metaTitle = 'Financiación Inmobiliaria - Finanbid';
    metaDescription =
      'Compra de suelo, promoción, construcción y flipping: En Finanbid los fondos pujan para ofrecer las mejores condiciones a tus proyectos inmobiliarios.';
    metaKeywords =
      'financiación inmobiliaria, compra de suelo, promoción, construcción, flipping, préstamo promotor, capital privado';
    ogTitle = metaTitle;
    ogDescription = metaDescription;

    faqJsonLd = `
      { "@context": "https://schema.org", "@type": "FAQPage", "mainEntity": [] }
    `;
  }

  // Canonical + alternates
  const canonicalUrl = "https://www.finanbid.com/inmobiliario";

  return (
    <div className="bg-back text-white lg:mt-10 mt-24">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />

        <link rel="canonical" href={canonicalUrl} />

        {/* Hreflang alternates */}
        <link rel="alternate" href="https://www.finanbid.com/inmobiliario" hreflang="x-default" />
        <link rel="alternate" href="https://es.finanbid.com/inmobiliario" hreflang="es" />
        <link rel="alternate" href="https://en.finanbid.com/inmobiliario" hreflang="en" />
        <link rel="alternate" href="https://pt.finanbid.com/inmobiliario" hreflang="pt" />
        <link rel="alternate" href="https://sv.finanbid.com/inmobiliario" hreflang="sv" />
        <link rel="alternate" href="https://de.finanbid.com/inmobiliario" hreflang="de" />
        <link rel="alternate" href="https://ar.finanbid.com/inmobiliario" hreflang="ar" />

        {/* Open Graph */}
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@finanbid" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={ogDescription} />
        <meta name="twitter:image" content="https://www.finanbid.com/imagenenlace.png" />

        {/* JSON-LD FAQ */}
        <script type="application/ld+json">
          {faqJsonLd}
        </script>
      </Helmet>
      {/* Hero Section */}
      <section className="bg-back dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            {/* Etiqueta PROYECTOS INMOBILIARIOS */}
            <span className="rounded-full uppercase bg-secondary px-3 py-0.5 text-sm font-semibold leading-5 text-white mb-4 inline-block">
              {t('inmobiliaria.hero.tag')}
            </span>

            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl dark:text-white">
              {t('inmobiliaria.hero.title')}
            </h1>

            <p className="max-w-2xl mb-6 font-light text-secondary lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('inmobiliaria.hero.subtitle')}
            </p>

            <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {t('inmobiliaria.hero.description')}
            </p>

            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a href="/subir-proyecto" className="inline-flex items-center justify-center px-5 py-3 text-sm font-medium text-center text-white bg-secondary rounded-lg hover:bg-opacity-90 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700">
                {t('inmobiliaria.hero.uploadProject')}
              </a>

              <a href="/contact" className="inline-flex items-center justify-center w-full px-5 py-3 mb-2 mr-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:w-auto focus:outline-none hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                {t('inmobiliaria.hero.contactUs')}
              </a>
            </div>
          </div>

          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="/heroinmo.png" alt={t('inmobiliaria.hero.imgAlt')} />
          </div>
        </div>
      </section>

      {/* Tipos de Productos Financieros */}
      <section className="py-16 bg-back text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('inmobiliaria.products.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Préstamo 1 */}
            <div className="p-6 bg-sec2 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-3">
                {t('inmobiliaria.products.product1.title')}
              </h3>
              <p className="text-gray-300">
                {t('inmobiliaria.products.product1.description')}
              </p>
            </div>

            {/* Préstamo 2 */}
            <div className="p-6 bg-sec2 rounded-lg shadow-lg cursor-pointer" onClick={() => navigate('/prestamopromotor')}>
              <h3 className="text-xl font-semibold mb-3">
                {t('inmobiliaria.products.product2.title')}
              </h3>
              <p className="text-gray-300">
                {t('inmobiliaria.products.product2.description')}
              </p>
            </div>

            {/* Préstamo Puente */}
            <div className="p-6 bg-sec2 rounded-lg shadow-lg cursor-pointer" onClick={() => navigate('/prestamopuente')}>
              <h3 className="text-xl font-semibold mb-3">
                {t('inmobiliaria.products.product3.title')}
              </h3>
              <p className="text-gray-300">
                {t('inmobiliaria.products.product3.description')}
              </p>
            </div>

            {/* Préstamo Flipping */}
            <div className="p-6 bg-sec2 rounded-lg shadow-lg cursor-pointer" onClick={() => navigate('/prestamoflipping')}>
              <h3 className="text-xl font-semibold mb-3">
                {t('inmobiliaria.products.product4.title')}
              </h3>
              <p className="text-gray-300">
                {t('inmobiliaria.products.product4.description')}
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Características de la Financiación Alternativa */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 text-center">
            {t('inmobiliaria.features.title')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {['feature1', 'feature2', 'feature3', 'feature4'].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800 rounded-lg text-center">
                <h3 className="text-xl font-semibold">
                  {t(`inmobiliaria.features.${feature}.title`)}
                </h3>
                <p className="mt-4 text-gray-300">
                  {t(`inmobiliaria.features.${feature}.description`)}
                </p>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
            {['feature5', 'feature6', 'feature7', 'feature8'].map((feature, index) => (
              <div key={index} className="p-6 bg-gray-800 rounded-lg text-center">
                <h3 className="text-xl font-semibold">
                  {t(`inmobiliaria.features.${feature}.title`)}
                </h3>
                <p className="mt-4 text-gray-300">
                  {t(`inmobiliaria.features.${feature}.description`)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="py-16 bg-secondary text-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-6">
            {t('inmobiliaria.cta.title')}
          </h2>
          <p className="text-lg mb-8">
            {t('inmobiliaria.cta.description')}
          </p>
          <button
            onClick={() => navigate('/subir-proyecto')}
            className="bg-primary text-white font-bold py-3 px-6 rounded-lg hover:bg-gray-800"
          >
            {t('inmobiliaria.cta.button')}
          </button>
        </div>
      </section>
    </div>
  );
};

export default Inmobiliaria;
