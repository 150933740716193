import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars, faTimes, faSearch, faChartLine, faEnvelope, faUser,
  faSignOutAlt, faBook
} from '@fortawesome/free-solid-svg-icons';

import Search from './SearchOperations';
import Tracking from './Tracking';
import Tesis from './Tesis';
import Inbox from './Inbox';
import Profile from './UserProfile';
import ProjectDetails from './ProjectDetails';
import socket from '../../utils/socket';

const DashboardFondos = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [offersCount, setOffersCount] = useState(0);
  const [inboxCount, setInboxCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);  // Para la conversación activa

  const userId = parseInt(localStorage.getItem('userId'), 10);
  const role = localStorage.getItem('role'); // 'fondo'

  // Cargar ofertas y mensajes no leídos
  useEffect(() => {
    const fetchOffersCount = async () => {
      try {
        const response = await fetch('https://api.finanbid.com/api/offers/unread-count');
        const data = await response.json();
        setOffersCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de ofertas:', error);
      }
    };

    const fetchInboxCount = async () => {
      try {
        if (!userId || !role) return;

        const response = await fetch(`https://api.finanbid.com/api/messages/unread-count?userId=${userId}&role=${role}`);
        if (!response.ok) throw new Error(`Error al obtener la cantidad de mensajes: ${response.status}`);

        const data = await response.json();
        setInboxCount(data.count);
      } catch (error) {
        console.error('Error al obtener la cantidad de mensajes:', error);
      }
    };

    fetchOffersCount();
    fetchInboxCount();
    socket.emit('registerUser', userId);
  }, [userId, role]);

  // WebSocket para manejar nuevos mensajes en tiempo real
  useEffect(() => {
    const handleNewMessage = (newMsg) => {
      console.log('Nuevo mensaje recibido:', newMsg);

      // Verificar si el mensaje es para el fondo y no lo ha enviado el propio fondo
      if (newMsg.recipientId === userId && newMsg.senderId !== userId) {
        console.log('Mensaje para el fondo. Evaluando si mostrar notificación o actualizar contador.');

        // Solo actualizar si NO estamos viendo la conversación activa
        if (newMsg.conversation_id !== currentConversationId) {
          setInboxCount(prev => prev + 1);  // Incrementar contador de mensajes no leídos

          // Mostrar notificación
          setNotifications(prev => [
            ...prev,
            { id: Date.now(), message: newMsg.message, conversationId: newMsg.conversation_id }
          ]);

          // Eliminar notificación después de 5 segundos
          setTimeout(() => {
            setNotifications(prev => prev.filter(n => n.conversationId !== newMsg.conversation_id));
          }, 5000);
        } else {
          console.log('El mensaje pertenece a la conversación activa. No actualizar contador ni mostrar notificación.');
        }
      }
    };

    // Evitar listeners duplicados
    socket.off('newMessageGlobal');
    socket.on('newMessageGlobal', handleNewMessage);

    return () => {
      socket.off('newMessageGlobal', handleNewMessage);
    };
  }, [userId, currentConversationId]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      {/* Header */}
      <header className="bg-gray-800 text-white flex justify-between items-center p-4 md:hidden">
        <img className="h-8 w-auto" src="/logofinanbid.png" alt="Fondos Dashboard" />
        <button onClick={toggleSidebar} className="text-white focus:outline-none">
          <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="lg" />
        </button>
      </header>

      {/* Sidebar */}
      <nav className={`bg-gray-800 w-64 h-full flex flex-col justify-between fixed top-0 md:relative z-10 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-200 ease-in-out`}>
        <div>
          <div className="h-16 bg-gray-900 flex items-center justify-center">
            <img className="h-12 w-auto hidden md:block" src="/logofinanbid.png" alt="Fondos Dashboard" />
          </div>
          <div className="flex-1 mt-8 space-y-2">
            <Link to="/fund/dashboard" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faSearch} className="mr-2" /> {t('dashboard.search')}
            </Link>
            <Link to="/fund/dashboard/tracking" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faChartLine} className="mr-2" /> {t('dashboard.tracking')}
            </Link>
            <Link to="/fund/dashboard/tesis" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faBook} className="mr-2" /> {t('dashboard.tesis')}
            </Link>
            <Link to="/fund/dashboard/inbox" className={`block px-4 py-2 ${inboxCount > 0 ? 'text-secondary font-bold' : 'text-gray-400 hover:text-white'}`} onClick={() => setIsSidebarOpen(false)}>
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              {inboxCount > 0 ? `${t('dashboard.inbox')} (${inboxCount})` : t('dashboard.inbox')}
            </Link>
          </div>
        </div>
        <div className="mb-4 space-y-2">
          <Link to="/fund/dashboard/profile" className="block text-gray-400 hover:text-white px-4 py-2" onClick={() => setIsSidebarOpen(false)}>
            <FontAwesomeIcon icon={faUser} className="mr-2" /> {t('dashboard.profile')}
          </Link>
          <button onClick={handleLogout} className="block text-gray-400 hover:text-white px-4 py-2 w-full text-left">
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> {t('dashboard.logout')}
          </button>
        </div>
      </nav>

      {/* Contenido Principal */}
      <main className="flex-1 h-screen overflow-y-auto p-6 bg-gray-100">
        {/* Notificaciones visuales */}
        <div className="fixed bottom-4 right-4 space-y-2">
          {notifications.map((notification) => (
            <div key={notification.id} className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
              <p className="text-sm">{notification.message}</p>
              <button
                onClick={() => {
                  navigate(`/fund/dashboard/inbox`);
                  setNotifications((prev) => prev.filter((n) => n.id !== notification.id));
                }}
                className="text-blue-500 text-xs mt-2 hover:underline"
              >
                Ver conversación
              </button>
            </div>
          ))}
        </div>

        <Routes>
          <Route path="/" element={<Search />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/tesis" element={<Tesis />} />
          <Route path="/inbox" element={<Inbox setInboxCount={setInboxCount} setCurrentConversationId={setCurrentConversationId} />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/project/:projectId" element={<ProjectDetails />} />
        </Routes>
      </main>
    </div>
  );
};

export default DashboardFondos;
